import React from 'react';

const SocialMediaInstagram = () => {
    return (

            <a className="list-group-item  border-0 SocialMediaInstagram" target="_blank" id="list-link"
               href={'https://Instagram.com/sigloyex'}>
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.SocialMediaInstagramSt2{fill:none;stroke-width:5.5996;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <linearGradient
                    id="SVGID_1_"
                    gradientUnits="userSpaceOnUse"
                    x1="-0.2131"
                    y1="78.2789"
                    x2="99.6274"
                    y2="20.6359"
                >
                    <stop offset={0} style={{ stopColor: "#BF0791" }} />
                    <stop offset={1} style={{ stopColor: "#BE0493" }} />
                </linearGradient>
                <path
                    className="SocialMediaInstagramSt2"
                    d="M82,21.6c0,4.4-6,7.6-10.7,1.7C68.6,20,69.6,9.7,82,9.9c12.4,0.2,15.8,10.2,16,15.2c0,3.3,0,16,0,40.2
	S80.8,97.2,72.2,97.9c-4.6,0-19.5,0-41.8,0C8.1,97.9,2.2,80.7,2.1,72.1c0-3.4,0-16.1,0-39.7c0-23.6,15.8-30,23.7-30.3h37.6
	C51.1,7.5,25,18.4,25.8,44.9c0.8,27.2,26.4,34.2,37.6,23C74.6,56.7,68.6,32.4,50,32.4"
                />
            </svg>
            </a>
    );
};

export default SocialMediaInstagram;
