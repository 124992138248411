import React from 'react';

function AccSettingIco(props) {



    return (
        <div className="AccSettingIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.AccSettingIcoSt0{fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.AccSettingIcoSt1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="AccSettingIcoSt0"
                        d="M47.4,78.2H14.5 M37.1,44.7c9.6,0,16.6,3,21.9,7.3 M37.1,44.7c-6.2,0-20.5-4.2-20.5-20.9
		c0-12.5,8.2-20.9,20.5-20.9c12.3,0,20.5,8.4,20.5,20.9C57.6,32.2,51.9,44.7,37.1,44.7z M37.1,44.7C16.6,44.7,7.7,58.3,3,69.8"
                    />
                    <path
                        className="AccSettingIcoSt0"
                        d="M69.5,96.7c1.3,0.2,2.6,0.3,3.9,0.3c1.5,0,2.9-0.1,4.3-0.4l0.6-4.4c1.8-0.5,3.4-1.2,5-2.1l3.9,2.5
		c2-1.5,3.8-3.2,5.3-5.3l-2.3-4.2c0.9-1.6,1.6-3.2,2-5h4.3c0.3-1.5,0.5-3.1,0.5-4.7c0-1.3-0.1-2.6-0.3-3.9l-4.5-0.9
		c-0.5-1.8-1.2-3.6-2.1-5.2 M87.1,54.2l-3.8,2.6c-1.7-1-3.5-1.7-5.4-2.2c-0.2-1.5-0.4-3-0.7-4.4c-1.3-0.2-2.6-0.3-3.9-0.3
		c-1.4,0-2.8,0.1-4.1,0.4l-0.8,4.5l0,0c-1.8,0.5-3.4,1.2-5,2.1L59.9,54l0,0l-3.4,2.8c-0.8,0.8-1.6,1.7-2.3,2.7l2.3,4.2
		c-0.8,1.5-1.5,3-2,4.7L50,69.6c-0.2,1.2-0.3,2.5-0.3,3.8c0,1.4,0.1,2.7,0.3,4l4.5,0.8c0.5,2.1,1.4,4,2.5,5.7l-3.1,3
		c1.6,2.3,3.5,4.2,5.8,5.8l3.9-2.5v0 M73.4,81.8c-4.6,0-8.3-3.7-8.3-8.3c0-4.6,3.7-8.3,8.3-8.3s8.3,3.7,8.3,8.3
		C81.7,78,77.9,81.8,73.4,81.8z"
                    />
                </g>
            </svg>

        </div>
    );
}

export default AccSettingIco;