import React, {useContext} from 'react';
import {Context} from "../../translation/Wrapper";

function ConvertBtn(props) {

    const context = useContext(Context);

    return (
        <>
            {context.locale == "fa"  ?
                (
                    <svg  viewBox="0 0 201 96" >
                        <style>
                            {".convertBtnSt0{fill:none;}"}
                            {".convertBtnSt1{fill:none;stroke:#000000;stroke-width:3.4139;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                                </style>
                                <g>
                                <path class="convertBtnSt1" d="M54.6,4.5h136.7c2.7,0,4.9,2.2,4.9,4.9v77.3c0,2.7-2.2,4.9-4.9,4.9H9.8c-2.7,0-4.9-2.2-4.9-4.9V35"/>
                                <line class="convertBtnSt1" x1="41.3" y1="4.5" x2="43.2" y2="4.5"/>
                                </g>
                                <g>
                                <path class="convertBtnSt0" d="M49.8,44.1L46,45.6c0.5,1.2,0.9,2.4,1.2,3.7c0.3,1.2,0.4,2.3,0.4,3.3c0,1.9-0.5,3.4-1.5,4.7c-1,1.3-2.7,2.3-5.1,3.1
		l1.4,3.9c3-1.1,5.4-2.7,7-4.6s2.4-4.3,2.4-7C51.8,50.3,51.1,47.4,49.8,44.1z"/>
                                <rect class="convertBtnSt0" x="45.5" y="36.9" transform="matrix(0.707 -0.7072 0.7072 0.707 -13.6246 44.9748)" width="4" height="4"/>
                                <path class="convertBtnSt0" d="M56.5,45.6c0.5,1.2,0.9,2.4,1.2,3.7c0.3,1.2,0.4,2.3,0.4,3.3c0,1.9-0.5,3.4-1.5,4.7c-1,1.3-2.7,2.3-5.1,3.1l1.4,3.9
		c3-1.1,5.4-2.7,7-4.6s2.4-4.3,2.4-7c0-2.3-0.7-5.1-2-8.5L56.5,45.6z"/>
                                <rect class="convertBtnSt0" x="65.3" y="33" width="4.2" height="22.5"/>
                                <path class="convertBtnSt0" d="M113.5,41.8c0.3,0.9,0.6,1.9,0.8,3c0.2,1.1,0.3,1.9,0.3,2.6c0,1.6-0.2,2.6-0.7,3.2c-0.1,0.2-0.5,0.4-1.1,0.6
		c-0.6,0.2-1.3,0.3-2.2,0.3c0,0,0,0,0,0v0h-2c0,0,0,0,0,0v0c-1.7,0-2.8-0.3-3.4-1c-0.6-0.7-0.9-2.1-0.9-4.2V33h-4.2v19.7
		c0,1.3-0.2,2.4-0.5,3.3c-0.4,1-0.8,1.7-1.4,2.2c-0.6,0.5-1.3,0.9-2,1.2c-0.7,0.3-1.5,0.4-2.3,0.4c-1.7,0-3.1-0.5-4.2-1.4
		s-1.7-2.1-1.7-3.5c0-0.9,0.2-2,0.6-3.2s1-2.5,1.7-3.9l-3.6-2c-1.9,3.5-2.8,6.5-2.8,9.1c0,1.1,0.2,2.2,0.6,3.2
		c0.4,1,0.9,1.8,1.6,2.6c0.7,0.7,1.4,1.3,2.3,1.8c0.9,0.5,1.8,0.9,2.7,1.1c1,0.2,1.9,0.4,2.9,0.4c1.2,0,2.3-0.2,3.5-0.6
		s2.2-1,3.1-1.7c1-0.7,1.8-1.7,2.4-2.9s1.1-2.5,1.3-4c1.2,0.6,2.7,0.9,4.4,0.9v0c0,0,0,0,0,0h2v0c0,0,0,0,0,0c3,0,5.2-0.8,6.5-2.3
		c1.1-1.4,1.7-3.3,1.7-5.8c0-2-0.4-4.3-1.3-6.8L113.5,41.8z"/>
                                <polygon class="convertBtnSt0" points="111.5,61.1 108.8,58.3 106,61.1 108.8,63.9 111.5,61.2 114.3,63.9 117.1,61.1 114.3,58.3 	"/>
                                <rect class="convertBtnSt0" x="139.5" y="59.1" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -1.7879 117.9145)" width="4" height="4"/>
                                <polygon class="convertBtnSt0" points="155.8,36.3 158.6,39.1 161.4,36.3 158.6,33.5 155.8,36.2 153.1,33.5 150.2,36.3 153.1,39.1 	"/>
                                <path class="convertBtnSt0" d="M154.9,41.8c0.3,0.9,0.6,1.9,0.8,3c0.2,1.1,0.3,1.9,0.3,2.6c0,1.6-0.2,2.6-0.7,3.2c-0.1,0.2-0.5,0.4-1.1,0.6
		c-0.6,0.2-1.3,0.3-2.2,0.3c0,0,0,0,0,0v0c-1.6,0-2.7-0.3-3.3-0.9c-0.7-0.7-1-2.1-1-4.3v-1.7h-4.2v1.7c0,2.2-0.3,3.7-1,4.4
		c-0.6,0.6-1.7,0.9-3.3,0.9c-0.6,0-1,0-1.2,0c-0.2,0-0.5-0.1-0.9-0.1s-0.6-0.2-0.8-0.3c-0.2-0.1-0.4-0.4-0.6-0.7
		c-0.2-0.3-0.5-0.7-0.7-1.1c-0.2-0.4-0.5-1-0.8-1.7l-3.6-8.5l-3.8,1.7l3,7.1c0.2,0.6,0.4,1.1,0.4,1.5c0,0.6-0.3,1.2-0.9,1.6
		s-1.5,0.7-2.6,0.7c-1.6,0-3.1-0.6-4.5-1.7l-2.5,3.3c1,0.8,2.1,1.4,3.4,1.9s2.4,0.7,3.7,0.7c1.2,0,2.4-0.2,3.4-0.6s2-1,2.7-1.8
		c1.5,1.6,3.6,2.4,6.3,2.4c2.8,0,4.9-0.8,6.4-2.3c1.5,1.6,3.6,2.3,6.4,2.3v0c0,0,0,0,0,0c3,0,5.2-0.8,6.5-2.3
		c1.1-1.4,1.7-3.3,1.7-5.8c0-2-0.4-4.3-1.3-6.8L154.9,41.8z"/>
                                </g>
                                </svg>
                )
                :
                (
                    <svg viewBox="0 0 201 96">
                        <style>
                            {".convertBtnSt0{fill:#000;stroke:none;}"}
                            {".convertBtnSt1{fill:none;stroke:#000000;stroke-width:3.4139;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        </style>
                        <g>
                            <path className="convertBtnSt1"
                                  d="M54.6,4.5h136.7c2.7,0,4.9,2.2,4.9,4.9v77.3c0,2.7-2.2,4.9-4.9,4.9H9.8c-2.7,0-4.9-2.2-4.9-4.9V35"/>
                            <line className="convertBtnSt1" x1="41.3" y1="4.5" x2="43.2" y2="4.5"/>
                        </g>
                        <g>
                            <path className="convertBtnSt0" d="M27.5,39.5c1.8-1.9,4.2-2.8,7-2.8c3.8,0,6.6,1.3,8.3,3.8c1,1.4,1.5,2.8,1.6,4.3h-4.8c-0.3-1.1-0.7-1.9-1.2-2.5
		c-0.9-1-2.1-1.5-3.8-1.5c-1.7,0-3,0.7-4,2.1c-1,1.4-1.5,3.4-1.5,5.9c0,2.6,0.5,4.5,1.6,5.8c1,1.3,2.4,1.9,4,1.9
		c1.6,0,2.9-0.5,3.7-1.6c0.5-0.6,0.9-1.5,1.2-2.7h4.7c-0.4,2.5-1.5,4.5-3.1,6.1c-1.7,1.6-3.8,2.3-6.5,2.3c-3.3,0-5.8-1.1-7.7-3.2
		c-1.9-2.1-2.8-5-2.8-8.7C24.3,44.8,25.3,41.7,27.5,39.5z"/>
                            <path className="convertBtnSt0" d="M66.1,58c-1.7,1.8-4.2,2.7-7.5,2.7c-3.3,0-5.8-0.9-7.5-2.7c-2.3-2.2-3.5-5.3-3.5-9.4c0-4.2,1.2-7.3,3.5-9.4
		c1.7-1.8,4.2-2.7,7.5-2.7c3.3,0,5.8,0.9,7.5,2.7c2.3,2.1,3.5,5.2,3.5,9.4C69.6,52.6,68.4,55.8,66.1,58z M63.2,54.5
		c1.1-1.4,1.7-3.4,1.7-6c0-2.6-0.6-4.5-1.7-5.9s-2.6-2.1-4.5-2.1s-3.4,0.7-4.5,2.1c-1.1,1.4-1.7,3.4-1.7,6s0.6,4.6,1.7,6
		c1.1,1.4,2.6,2.1,4.5,2.1S62,55.9,63.2,54.5z"/>
                            <path className="convertBtnSt0" d="M73.5,37.1h5l9.1,15.9V37.1H92V60h-4.8l-9.3-16.2V60h-4.5V37.1z"/>
                            <path className="convertBtnSt0" d="M110.2,37.1h5L107.4,60h-4.5l-7.7-22.9h5.1l5,17.4L110.2,37.1z"/>
                            <path className="convertBtnSt0" d="M135,41.2h-11.8V46H134v4h-10.9v5.9h12.4V60h-17.1V37.1H135V41.2z"/>
                            <path className="convertBtnSt0" d="M154.5,37.7c0.8,0.4,1.6,0.9,2.1,1.6c0.5,0.6,0.9,1.2,1.1,1.9s0.4,1.5,0.4,2.4c0,1.1-0.3,2.2-0.8,3.2
		c-0.5,1-1.5,1.8-2.7,2.2c1.1,0.4,1.8,1,2.2,1.8s0.7,2,0.7,3.6V56c0,1,0,1.8,0.1,2.1c0.1,0.6,0.4,1,0.9,1.3V60h-5.3
		c-0.1-0.5-0.2-0.9-0.3-1.2c-0.1-0.6-0.2-1.3-0.2-2l0-2.1c0-1.5-0.3-2.4-0.8-2.9c-0.5-0.5-1.4-0.7-2.7-0.7h-4.7v9h-4.7V37.1h10.9
		C152.4,37.2,153.6,37.4,154.5,37.7z M144.6,41.1v6.1h5.1c1,0,1.8-0.1,2.3-0.4c0.9-0.4,1.4-1.3,1.4-2.6c0-1.4-0.4-2.3-1.3-2.8
		c-0.5-0.3-1.2-0.4-2.2-0.4H144.6z"/>
                            <path className="convertBtnSt0" d="M179.7,37.1v4h-6.8V60H168V41.2h-6.9v-4H179.7z"/>
                        </g>
                    </svg>

                )
            }
        </>
    );
}

export default ConvertBtn;