import {createGlobalStyle} from "styled-components"

export const GlobalStyles = createGlobalStyle`


  .form-select, .NationalityCountryContainer ul, .NationalityCountryContainer div,
  .NationalityCountryContainer button, .DatePickerContainer input, .DatePickerContainer button, .react-calendar div, .modalStyle {
    background: ${({theme}) => theme.body};
    color: ${({theme}) => theme.text};
  }

  .CopyToCLipBoard button {
    color: ${({theme}) => theme.body};
    transition: 04s all;
    border: 2px solid;
    border-radius: 10px;
  }

  .CopyToCLipBoard button:hover {
    border: 2px dashed;
    transition: 0.7s all;
    transform: scale(1.1);
  }


  .tilesWrap li:after {
    background: ${({theme}) => theme.idVerificationStepCard};
    color: ${({theme}) => theme.text};
  }

  .dd-button, .table > tbody {
    color: ${({theme}) => theme.textSec};
  }

  a, .form__field {
    color: ${({theme}) => theme.text};
  }

  body {
    color: ${({theme}) => theme.text};
  }

  .normalCN {
    background: ${({theme}) => theme.navColor};
    color: ${({theme}) => theme.navFColor};
  }

  .normalCB {
    background: ${({theme}) => theme.GlassList};
    color: ${({theme}) => theme.navFColor};
  }

  .crypto-list1, .crypto-list2, .crypto-list3, .crypto-list4 {

  }

  .settingBody ul li:nth-child(even) {
    background: ${({theme}) => theme.GlassList};
  }

  #lists tr:nth-child(even) {
    background: ${({theme}) => theme.GlassList};
  }

  .OrderTypeBtnGp .option {
    color: ${({theme}) => theme.text};
    border: ${({theme}) => theme.cardBorder};
  }

  #option-1:checked:checked ~ .option-1,
  #option-2:checked:checked ~ .option-2 {
    background: ${({theme}) => theme.GlassList};
  }

  .NationalityCountryContainer div, .form-control, .e-button-container {
    border: ${({theme}) => theme.cardBorder};
    border-radius: 4px;
  }

  .Nav-menu .Nav-menu-dropdown li {
    list-style: none;
  }

  .Nav-menu .Nav-menu-dropdown {
    box-shadow: 1px 1px 1px 3px ${({theme}) => theme.shadowColor};
    background: ${({theme}) => theme.dropDown};

  }

  .Nav-menu .Nav-menu-dropdown p div, .Nav-menu:hover .Nav-menu-dropdown {
    color: ${({theme}) => theme.text};
  }

  .termsCards {
    background-color: ${({theme}) => theme.GlassCard};
  }

  .DatePickerContainer div {
    width: 100%;
    border-radius: 4px;
  }

  .DatePickerContainer label {
    padding: 0 5px 30px 5px;
  }

  .DatePickerContainer input {
    margin: 25px 0 0 0;
    height: fit-content;
  }

  .react-calendar div {
    padding: 0;
    margin: 0;
  }


  .NationalityCountryContainer ul li:hover, .PhoneInputContainer ul li:hover, .react-tel-input .country-list .country:hover, .react-tel-input .country-list .country.highlight {
    background-color: ${({theme}) => theme.tableTr};
    color: #0a0a0a;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    -o-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
  }

  .li-current {
    background: ${({theme}) => theme.calColor};
    font-weight: bold;
    color: #f9b251;
  }

  .header .exact-price {
    color: ${({theme}) => theme.GPrice};
  }

  .exchange-history .card-header a {
    color: ${({theme}) => theme.text};
  }

  .Orders .card-header a {
    color: ${({theme}) => theme.text};
  }

  // .exchange-history .card-header a:hover, .Orders .card-header a:hover {
  //   color: ${({theme}) => theme.tableTr};
  // }

  .exchange-history .card-header a:hover.active, .Orders .card-header a:hover.active {
    color: ${({theme}) => theme.text};
    cursor: default;
  }

  #list-link:hover {
    color: ${({theme}) => theme.footerHover};
    cursor: pointer;
  }

  .reference-s-r a:hover, .reference-f-r a:hover {
    filter: ${({theme}) => theme.footerBrightnessIco};
  }

  .tilesWrap li h3 {
    color: ${({theme}) => theme.verification};
  }

  .tableGlow {
    box-shadow: 3px 3px 3px 3px ${({theme}) => theme.tableGlow}, 1px 1px 1px 1px ${({theme}) => theme.tableGlow};
    color: ${({theme}) => theme.tableGlow};
  }

  .marketListGlow:hover {
    box-shadow: 1.5px 1.5px 1.5px 1.5px ${({theme}) => theme.EstTotal}, 2px 2px 2px 2px ${({theme}) => theme.EstTotal};
  }

  .Accounts-container, .btn-10, .bnClose, .MuiSlider-markLabel, .MuiSlider-markLabelActive, .pgOrders .Orders-btn {
    color: ${({theme}) => theme.text};
  }

  .table-Trade {
    background-color: ${({theme}) => theme.btnM};
  }

  .Register-Login .Register-Login-btn{
    background-color: ${({theme}) => theme.notLogin};
  }

  #navMenu {
    padding: 0;
  }

  .ModalContent {
    color: ${({theme}) => theme.text};
    background: ${({theme}) => theme.body};
    box-shadow: 0 15px 30px 0 hsl(0deg, 0%, 0%, 0.11%), 0 5px 15px 0 hsl(0deg, 0%, 0%, 0.8%);
    border-radius: 30px;
  }

  .dark-mode .ModalContent {
    color: ${({theme}) => theme.text};
    background: ${({theme}) => theme.body};
  }

  .grow {
    color: ${({theme}) => theme.growColor};
  }

  .SocialMediaEmailSt0, .SocialMediaTelegramSt3, .SocialMediaInstagramSt2, .SocialMediaTwitterSt1, .ElevatorButton0, .PlaceBtn .PlaceBtnSt0,
  .AccSettingIco .AccSettingIcoSt0, .Idvico .IdvicoSt0, .Mcico .McicoSt0, .SettingPageIco .SettingPageIcoSt0, .LightModIco .LightModIcoSt0, .DarkModeIco DarkModeIcoSt0
  .SettingIco .SettingIcoSt0, .AccInfo .AccInfoSt0, .OrdersIco .OrdersIcoSt0, .WalletIco .WalletIcoSt0, .SettingIco .SettingIcoSt0, .convertBtn .convertBtnSt1 {
    stroke: ${({theme}) => theme.textSec};
  }

  .BlockChainSt0, .BlockChainSt1, .BlockChainSt2, .Coin360St1, .Coin360St2, .Coin360St3, .CoinDeskSt0, .CoinTelegraphSt0, .CoinTelegraphSt1, .CoinMarketCapSt0,
  .IcodropsSt0, .IcobenchIcoSt0, .TradingViewSt1, .SubmitBtn .SubmitBtnSt1, .FeedBackBtn .SubmitBtnSt1, .convertBtn .convertBtnSt0 {
    fill: ${({theme}) => theme.textSec};
  }

  .IcobenchIcoSt1 {
    fill: ${({theme}) => theme.navColor};
  }

  .PlaceOrderBtn .PlaceOrderBtnSt2, .LanguageIcoSt0, .LogInBtn .LogInBtnSt2, .JoinNow .JoinNowSt0, .LogInBtn .LogInBtnSt1,
  .PlaceOrderBtn .PlaceOrderBtnSt1, .LogInNav .LogInBtnSt2, .LogInNav .LogInBtnSt1, .SubmitBtn .SubmitBtnSt0, .FeedBackBtn .SubmitBtnSt0,
  .WithdrawBtn .WithdrawIcoSt0 {
    stroke: ${({theme}) => theme.textSec};
  }

  .PlaceBtn .PlaceBtnSt1, .JoinNow .JoinNowSt1, .SelectedIcon .SelectedIconSt0, .LogInBtn .LogInBtnSt0, .PlaceOrderBtn .PlaceOrderBtnSt0, .LogInNav .LogInBtnSt0, .SubmitBtn .SubmitBtnSt2, .FeedBackBtn .SubmitBtnSt2, .WithdrawBtn .WithdrawIcoSt1 {
    fill: ${({theme}) => theme.textSec};
  }

  .LogInBtn:hover, .PlaceBtn:hover, .PlaceOrderBtn:hover, PlaceOrderBtn:hover .PlaceOrderBtnSt0, .JoinNow:hover .JoinNowSt1,
  .LogInBtn:hover .LogInBtnSt0, .LogInNav:hover .LogInBtnSt0, .SubmitBtn:hover .SubmitBtnSt1, .SubmitBtn:hover .SubmitBtnSt2, .FeedBackBtn:hover .SubmitBtnSt2,
  .convertBtn:hover .convertBtnSt0, .WithdrawBtn:hover .WithdrawIcoSt1 {
    stroke: ${({theme}) => theme.textSec};
  }

  .feedBackTextAreaContainer .textareaGP textarea, .feedBackTextAreaContainer .textareaGPBNN textarea {
    background-color: ${({theme}) => theme.GlassCardNoOp};
    color: ${({theme}) => theme.text};
  }


  .LanguageIco {
    width: 20px;
  }


  .PlaceBtn {
    width: 120px;
  }

  .Buy-sell-responsive-btn, .fa-file-invoice {
    background: ${({theme}) => theme.GlassCard};
  }

  .navInfo-container {
    padding: 10px;
  }

  .menu-title, .fa-file-invoice {
    color: ${({theme}) => theme.text};
  }

  #navMenu .dark-them, .Nav-menu-title {
    color: ${({theme}) => theme.navFColor};

  }

  .card-header {
    background-color: transparent;
    color: ${({theme}) => theme.textSec};
    border-bottom-color: #dee2e6;
  }

  .card-header-tabs {
    border-bottom-color: #dee2e6;
  }

  .form-check-input {
    border: ${({theme}) => theme.cardBorder};
  }


  .MuiInput-underline:before {
    border: none;
  }

  .crypto-24H .small, .highest-24H .small, .value-24H .small, .lowest-24H .small, .volume-24H .small {
    color: ${({theme}) => theme.text};
  }

  .ACCnavbar .ACC-nav-btns {
    filter: brightness(1.5);
    border: 2px solid transparent;
  }

  .ACC-nav-btns:hover {
    filter: brightness(1);
    transition: 0.2s;
    border-radius: 0;
    color: ${({theme}) => theme.text};
    border-bottom: 2px solid ${({theme}) => theme.text};
  }

  .btnTOTPSub button {
    box-shadow: 0 0.5rem 0.5rem ${({theme}) => theme.shadowColor};
    color: ${({theme}) => theme.text};
    border: 1px solid ${({theme}) => theme.border};
  }

  .form-control:disabled, .form-control[readonly] {
    background-color: transparent;

    opacity: 1;
  }

  .shadow {
    box-shadow: 0 0.5rem 1rem ${({theme}) => theme.shadowColor} !important;
  }

  .tableShadow {
    box-shadow: 0 0 1.5rem ${({theme}) => theme.shadowColor} !important;
  }

  .shadow-sm {
    box-shadow: 0 0.125rem 0.25rem ${({theme}) => theme.shadowColor} !important;
  }

  .shadow-lg {
    box-shadow: 0 1rem 3rem ${({theme}) => theme.shadowColor} !important;
  }

  .switch-button {
    border: 1px solid transparent;
  }

  .darkThemAll {
    border-left: 1px solid ${({theme}) => theme.text};
  }

  .arrow-tabs > ul > li:hover:before, .arrow-tabs > ul > li:focus:before {
    box-shadow: 0 2px 3px ${({theme}) => theme.border};
  }

  .arrow-tabs > ul > li:hover:after, .arrow-tabs > ul > li:focus:after {
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2), 1px 1px 1px ${({theme}) => theme.border}
  }

  .bn632-hover.bn25, .bnt-hover.bnt {
    box-shadow: 0 4px 15px 0 ${({theme}) => theme.shadowColor};
  }

  .form-check-input:checked {
    background-color: ${({theme}) => theme.checkBox};
    border-color: ${({theme}) => theme.checkBox};
  }

  .css-p5v6ve-MuiAutocomplete-root .MuiInput-root .MuiInput-input, .MuiInputLabel-shrink {
    text-align: center;
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-p5v6ve-MuiAutocomplete-root .MuiAutocomplete-inputRoot {
    padding: 0;
  }

  .menu-dropdown {
    background-color: ${({theme}) => theme.dropDown};

  }

  .card-body, .exchange-nav {
    background-color: ${({theme}) => theme.tileRapColor};
  }

   .CoinMainInfoContainer .crypto-tags span , .title-container , .sideContent{
     background: ${({theme}) => theme.ShadowCard};
   }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.tileRapColorInset} inset !important;
    -webkit-text-fill-color: ${({theme}) => theme.text};
    -webkit-background-clip: ${({theme}) => theme.text};
  }


  .wallet-crypto-property {
    border-bottom: 1px solid ${({theme}) => theme.border};
  }

  .sideSelect {
    border-bottom: 2px dashed ${({theme}) => theme.text};
  }

  .arrow-tabs > ul > li:after, .arrow-tabs > ul > li.ui-state-active:hover:after, .arrow-tabs > ul > li.ui-state-active:focus:after {
    background-color: ${({theme}) => theme.bodySec};
  }

  #list-link {
    background-color: transparent;
    color: ${({theme}) => theme.textSec};
  }

  .card {
    background-color: transparent;
    color: ${({theme}) => theme.text};
    border-radius: 0;
  }

  .form-floating {
    border: dashed 2px ${({theme}) => theme.text};
    border-left: none;
    border-right: none;
    border-top: none;
    background-color: transparent;
    color: ${({theme}) => theme.text};
    border-radius: 0;
  }

  .exchange-history, .Orders .card, .exchange-form {
    border: none;
  }

  .financial-asset .card-body {
    border-top: ${({theme}) => theme.cardBodyBorder};
  }

  .Est-total .title, .Est-total .crypto-type, .Est-percent .title {
    color: ${({theme}) => theme.EstTotal};
  }

  .Est-total, .Est-percent {
    color: ${({theme}) => theme.textSec};
  }

  .form-control:focus, .form-control {
    color: ${({theme}) => theme.text};
    border: 1px solid;
  }

  .RFDisabled, .RFDisabled input {
    color: ${({theme}) => theme.disableColor};
  }

  .accountLevel-container .card-header {
    background-color: ${({theme}) => theme.body};
  }

  .emptyCircle {
    background-color: ${({theme}) => theme.body};
  }

  .verification-explanation .list-group-item {
    color: ${({theme}) => theme.textSec}
  }

  .circular-vip-level-container .vip-level {
    background-image:  linear-gradient( to right,#ffaa00 , #8c8c8c , #8c8c8c , #ffaa00);
  }
  


  .Deposit-title-container .explain {
    color: ${({theme}) => theme.colorInfo}
  }

  #SCAT a {
    color: ${({theme}) => theme.textSec}
  }

  #secondTable-content {
    color: ${({theme}) => theme.textSec}
  }


  .crypto-all {

    box-shadow: none;
    border: none;
  }

  .arrow-tabs > ul > li {
    background-color: ${({theme}) => theme.bodySec};
  }

  .arrow-tabs > ul > li a {
    color: ${({theme}) => theme.textSec}
  }

  .list-group-item.disabled, .list-group-item:disabled {
    color: ${({theme}) => theme.background};
  }

  .glow {
    color: ${({theme}) => theme.textSec};
    border-top: none;
  }


  .homepage-crypto-property.glow {
    border-top: none;
  }


  .row {
    --bs-gutter-x: 0;
  }

  .marquee {
    background-color: ${({theme}) => theme.marquee};
    color: ${({theme}) => theme.textSec}
  }

  .Deposit, .withdraw, .Transfer {
    background-color: ${({theme}) => theme.toggleBorder};
  }

  #button-addon2 {
    color: ${({theme}) => theme.background};
  }

  .messagesBox .card-body {
    border-top: ${({theme}) => theme.cardBorder};
  }

  .go-trade-usdt {
    color: ${({theme}) => theme.marquee};
  }

  .table-passage {
    color: ${({theme}) => theme.textSec};
  }

  .stared, .slide-dropdown, .info {
    color: ${({theme}) => theme.textSec};
  }

  .ACC-tilesWrap li:after {
    background: ${({theme}) => theme.idVerificationCard};
  }

  .ACC-tilesWrap .Acc-first-card h2, .ACC-tilesWrap .Acc-first-card h3 {
    color: ${({theme}) => theme.navFColor};
  }

  .crypto-table-homepage , .border-hover{
    background-color: ${({theme}) => theme.body};
  }

  .crypto-table-homepage tbody tr:nth-child(odd) {
    background: ${({theme}) => theme.GlassListSec};
  }

  .tileRap:before {
    background-color: #ffaa00;
  }

  .currentLocation {
    color: ${({theme}) => theme.textSec};
    transform: scale(1.2);
  }

  .currentLocation:hover {
    color: ${({theme}) => theme.textSec};
  }

  .LogoContainerLight .st0, .LogoContainerLight .st1 {
    stroke: ${({theme}) => theme.textSec};
  }

  .LogoContainerDark .st0, .LogoContainerDark .st1 {
    stroke: ${({theme}) => theme.textSec};
  }

  .LogoContainerLight .fi0, .LogoContainerLight .fi1 {
    fill: ${({theme}) => theme.textSec};
  }

  .LogoContainerDark .fi0, .LogoContainerDark .fi1 {
    fill: ${({theme}) => theme.textSec};
  }

  .HPTableLogo .st0, .HPTableLogo .st1,  .CITableLogo .st0, .CITableLogo .st1, .MLTableLogo .st0, .MLTableLogo .st1, .marketListLogo .st0, .marketListLogo .st1,
  .WTableLogo .st0, .WTableLogo .st1, .MarqueeLogo .st0, .MarqueeLogo .st1, .TPTableLogo .st0, .TPTableLogo .st1, .SignUpBtn .SignUpBtnSt0, .SignUpBtn:hover .SignUpBtnSt1 , .ArrowIcoSt0{
    stroke: ${({theme}) => theme.textSec};
  }

  .HPTableLogo .fi0, .HPTableLogo .fi0,  .CITableLogo .fi0, .CITableLogo .fi0, .MLTableLogo .fi0, .MLTableLogo .fi0, .marketListLogo .fi0, .marketListLogo .fi1,
  .WTableLogo .fi0, .WTableLogo .fi0, .MarqueeLogo .fi0, .MarqueeLogo .fi0, .TPTableLogo .fi0, .TPTableLogo .fi1, .SignUpBtn .SignUpBtnSt1 {
    fill: ${({theme}) => theme.textSec};
  }

  .marketListLogo {
    width: 25px;
    height: 25px;
    margin: 0 10px 0 10px;
    font-size: 14px;
  }

  .marketListFullName {
    margin: 5px 0 0 8px;
    font-size: 12px;
    opacity: 0.8;
  }

  #lists .CoinListItem:after {
    background-color: ${({theme}) => theme.MarketBefore};
    color: ${({theme}) => theme.text};
  }

  .EyeHideContainer .EyeHideContainerSt0, .EyeShowContainer .EyeShowContainerSt0 {
    stroke: ${({theme}) => theme.textSec};
  }

  .nodataFeedBack {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 120px 0;
  }

  .noData {
    position: absolute;
    margin: auto;
    width: 200px;
  }

  .noData h3 {
    margin: 10px 0 0 0;
    text-align: center;
  }

  .noData .noDataSt0, .noData .noDataSt1,
  .noData .noDataSt2, .noData .noDataSt3 {
    stroke: ${({theme}) => theme.textSec};
  }


`
