import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import {Context} from "../../translation/Wrapper";

function SubmitBtn(props) {

    const history = useHistory();
    const context = useContext(Context);

    return (
        <>
            {context.locale == "fa"  ?
                <svg viewBox="0 0 250 120">
                    <style type="text/css">
                        {".SubmitBtnSt0{fill:none;stroke:#000000;stroke-width:4.2725;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".SubmitBtnSt1{fill:none;stroke:none}"}
                    </style>
                    <g>
                        <path className="SubmitBtnSt0"
                              d="M67.5,5.5h171.1c3.4,0,6.1,2.8,6.1,6.1v96.7c0,3.4-2.8,6.1-6.1,6.1H11.4c-3.4,0-6.1-2.8-6.1-6.1V43.7"/>
                        <line className="SubmitBtnSt0" x1="50.8" y1="5.5" x2="53.2" y2="5.5"/>
                    </g>
                    <g>
                        <path className="SubmitBtnSt1" d="M109.3,72.4c-4,0-7-1.2-9.2-3.5c-1.1,1.2-2.4,2.1-4,2.7c-1.6,0.6-3.2,0.8-5,0.8c-1.8,0-3.6-0.3-5.3-1
		c-1.8-0.6-3.4-1.6-4.9-2.7l3.7-4.8c2,1.6,4.2,2.4,6.5,2.4c1.7,0,2.9-0.3,3.8-1c0.9-0.6,1.3-1.4,1.3-2.3c0-0.6-0.2-1.3-0.5-2.1
		l-4.4-10.4l5.6-2.4l5.3,12.4c0.4,1,0.8,1.8,1.1,2.4c0.3,0.6,0.6,1.2,1,1.6c0.4,0.5,0.7,0.8,0.9,1c0.2,0.2,0.6,0.4,1.1,0.5
		c0.5,0.1,0.9,0.2,1.3,0.2c0.4,0,0.9,0,1.8,0V72.4z"/>
                        <path className="SubmitBtnSt1" d="M101.9,80.5l4.1-4.1l4.1,4.1l-4.1,4.1L101.9,80.5z M109.3,66.3c2.3,0,3.9-0.4,4.7-1.3c1-1,1.5-3.1,1.5-6.4v-2.5h6v2.5
		c0,3.2,0.5,5.3,1.4,6.3c0.9,0.9,2.5,1.4,4.8,1.4v6c-4,0-7.1-1.1-9.3-3.4c-2.1,2.3-5.2,3.4-9.3,3.4c-0.8,0-1.6-0.3-2.1-0.9
		c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1C107.7,66.6,108.5,66.3,109.3,66.3z M109.9,80.5l4.1-4.1l4.1,4.1l-4.1,4.1
		L109.9,80.5z"/>
                        <path className="SubmitBtnSt1" d="M121.2,80.5l4.1-4.1l4.1,4.1l-4.1,4.1L121.2,80.5z M127.9,66.3c1.2,0,2.3-0.1,3.2-0.4c0.9-0.3,1.4-0.6,1.6-0.8
		c0.7-0.8,1.1-2.4,1.1-4.6c0-1-0.2-2.2-0.5-3.7c-0.3-1.5-0.7-3-1.2-4.3l5.8-1.9c1.3,3.7,1.9,7,1.9,9.9c0,3.7-0.8,6.5-2.5,8.5
		c-1.9,2.3-5.1,3.4-9.5,3.4c-0.8,0-1.6-0.3-2.1-0.9c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1C126.3,66.6,127,66.3,127.9,66.3
		z M129.2,80.5l4.1-4.1l4.1,4.1l-4.1,4.1L129.2,80.5z"/>
                        <path className="SubmitBtnSt1" d="M150.1,39.4v22.3c0,1.8,0.2,3,0.7,3.6c0.6,0.7,2.1,1.1,4.6,1.1v6c-4.1,0-7.1-1.1-9-3.3c-1.6-1.8-2.3-4.2-2.3-7.4V39.4
		H150.1z"/>
                        <path className="SubmitBtnSt1" d="M155.5,66.3c1.2,0,2.3-0.1,3.2-0.4c0.9-0.3,1.4-0.6,1.6-0.8c0.7-0.8,1.1-2.4,1.1-4.6c0-1-0.2-2.2-0.5-3.7
		c-0.3-1.5-0.7-3-1.2-4.3l5.8-1.9c1.3,3.7,1.9,7,1.9,9.9c0,3.7-0.8,6.5-2.5,8.5c-1.9,2.3-5.1,3.4-9.5,3.4c-0.8,0-1.6-0.3-2.1-0.9
		c-0.6-0.6-0.9-1.3-0.9-2.1c0-0.8,0.3-1.6,0.9-2.1C153.9,66.6,154.6,66.3,155.5,66.3z M152.9,44.3l4.1-4.1l4.1,4.1l-4.1,4.1
		L152.9,44.3z M160.9,44.3l4.1-4.1l4.1,4.1l-4.1,4.1L160.9,44.3z"/>
                    </g>
                </svg>
                :
                <svg viewBox="0 0 250 120">
                    <style>
                        {".SubmitBtnSt0{fill:none;stroke:#000000;stroke-width:4.2725;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".SubmitBtnSt1{font-family:'Helvetica-Bold';}"}
                        {".SubmitBtnSt2{font-size:48.2027px;}"}
                    </style>
                    <g>
                        <path className="SubmitBtnSt0"
                              d="M67.5,5.5h171.1c3.4,0,6.1,2.8,6.1,6.1v96.7c0,3.4-2.8,6.1-6.1,6.1H11.4c-3.4,0-6.1-2.8-6.1-6.1V43.7"/>
                        <line className="SubmitBtnSt0" x1="50.8" y1="5.5" x2="53.2" y2="5.5"/>
                    </g>
                    <text transform="matrix(1 0 0 1 46 78.3682)" className=" SubmitBtnSt2">submit</text>
                </svg>

            }
        </>
    );
}

export default SubmitBtn;