import React from 'react';

function WalletIco(props) {
    return (
        <div className="WalletIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.WalletIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="WalletIcoSt0"
                        d="M92,39.4V23.9l-65,0.7c-2.3,0-4.6-0.4-6.7-1.3c-3.3-1.4-7.1-4.8-7.8-10.7"
                    />
                    <polyline className="WalletIcoSt0" points="5.3,13.1 5.3,83.8 92,83.8 92,61.9 	" />
                    <path
                        className="WalletIcoSt0"
                        d="M92,23.9L92,23.9c0-8.5-6.9-15.4-15.3-15.4L14.2,8.5"
                    />
                    <path
                        className="WalletIcoSt0"
                        d="M71.1,58.8h17.7c4.6,0,8.3-3.7,8.3-8.3v0c0-4.6-3.7-8.3-8.3-8.3H71.1c-4.6,0-8.3,3.7-8.3,8.3v0
		C62.8,55.2,66.5,58.8,71.1,58.8z"
                    />
                    <circle className="WalletIcoSt0" cx="90.5" cy="50.4" r="2.7" />
                </g>
            </svg>
        </div>
    );
}

export default WalletIco;