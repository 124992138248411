import React from 'react';

function DarkModeIco(props) {
    return (
        <div className="DarkModeIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.DarkModeIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path className="DarkModeIcoSt0" d="M6.7,53.4c-0.1-1.1-0.1-2.3-0.1-3.4" />
                    <path
                        className="DarkModeIcoSt0"
                        d="M24.9,12.8c3.8-2.9,8.1-5.3,12.8-7c4.2-1.5,8.5-2.4,12.7-2.6C35,13.2,27.8,32.8,34.3,50.9
		c7.6,21.1,30.8,32.2,52,24.6c3.4-1.2,6.5-2.8,9.3-4.7c-5.2,10.5-14.3,19.1-26.2,23.3c-24.4,8.7-51.3-4-60-28.4
		c-0.4-1.2-0.8-2.4-1.1-3.6c-0.2-0.6-0.3-1.3-0.5-1.9"
                    />
                </g>
            </svg>
        </div>
    );
}

export default DarkModeIco;