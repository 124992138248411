import React, {useCallback, useContext, useEffect, useState} from "react";
import "../css/bootstrap.css";
import "../css/NavBar.scss";
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import {FaCheck} from "@react-icons/all-files/fa/FaCheck";
import {BsBellFill} from "@react-icons/all-files/bs/BsBellFill";
import {FaBars} from "@react-icons/all-files/fa/FaBars";
import {LoginContext} from "../../Root";
import {useHistory} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {Context} from "../../translation/Wrapper";
import {Theme} from "../ThemeContext";
import {Avatar, Badge, Divider, ListItem, ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import {RiLogoutBoxRLine} from "@react-icons/all-files/ri/RiLogoutBoxRLine";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import {useToasts} from "react-toast-notifications";
import LogInBtn from "../IconsClasses/LogInBtn";
import LightModIco from "../IconsClasses/LightModICO";
import DarkModeIco from "../IconsClasses/DarkModeIco";
import SettingIco from "../IconsClasses/SettingICO";
import AccInfo from "../IconsClasses/ACCInfo";
import OrdersIco from "../IconsClasses/OrdersICO";
import WalletIco from "../IconsClasses/WalletICO";
import LanguageIco from "../IconsClasses/LanguageIco";
import {useLastLocation} from 'react-router-last-location';
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import LogInNav from "../IconsClasses/LogInNav";

const NavBar = (props) => {
    const [toggle, setToggle] = useState(false);
    const {isLoggedIn, setIsLoggedIn} = useContext(LoginContext);
    const history = useHistory();
    const context = useContext(Context);
    const theme = useContext(Theme);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [languageOpen, setLanguageOpen] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    const {addToast} = useToasts();

    const location = useLocation().pathname;


    const logOut = async () => {
        setIsLoading(true)
        setError(null);
        try {
            const response = await axios.get('auth/logout', {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });
            if (response.status !== 200) {
                throw new Error('Something went wrong!');
            }
            localStorage.removeItem("token");

            refreshPage();
        } catch (error) {
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }
    }

    const getInfo = useCallback(async () => {
        try {
            const response = await axios.get('profile/info', {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });
            if (response.status == 200) {
                setUserInfo(response.data.data)
            }


        } catch (error) {
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");

                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }
    }, [])


    const refreshPage = () => {
        window.location.reload();
    }

    useEffect(() => {
        if (isLoggedIn) {
            getInfo();

        }
    }, []);


    return (
        <nav
            className={`navbar navbar-expand-lg  ${context.locale == "fa" && "font-face-farsi"} ${props.isOpen && 'd-none'} ${props.coloring}`}
            id="navMenu">
            <div className="container-fluid " id="navbar-C">
                <a
                    onClick={() => {
                        history.push("/");
                    }}
                    className="navbar-brand dark-them Logo">
                    {theme.theme == 'dark' ? <img src="./img/termsIco/logo-01.svg"/> :
                        <img src="./img/termsIco/logo-01.svg"/>}
                    {/*<FormattedMessage*/}
                    {/*    id="NavBar.Title"*/}
                    {/*    defaultMessage="Sigloy"*/}
                    {/*/>*/}
                </a>
                {
                    !isLoggedIn &&
                    <div id="hidden-started">
                        <LogInNav/>
                    </div>
                }


                <div className="d-flex">
                    {/*<li className={`nav-item Nav-menu secLan`}>*/}
                    {/*    <button className="Nav-menu-title languageIco-hidden  btn" aria-controls="simple-menu"*/}
                    {/*            aria-haspopup="true">*/}
                    {/*        <TranslateOutlinedIcon className="fas fa-id-badge"/>{" "}*/}
                    {/*    </button>*/}

                    {/*    <ul className="Nav-menu-dropdown language-dropdown">*/}
                    {/*        <ListItem*/}
                    {/*            onClick={() => context.selectLanguage('en')}><ListItemText> English {context.locale === 'en' &&*/}
                    {/*            <span className='checked'><FaCheck/></span>}</ListItemText></ListItem>*/}
                    {/*        <ListItem*/}
                    {/*            onClick={() => context.selectLanguage('fa')}><ListItemText> فارسی {context.locale === 'fa' &&*/}
                    {/*            <span className='checked'><FaCheck/></span>}</ListItemText></ListItem>*/}

                    {/*    </ul>*/}
                    {/*</li>*/}
                    <span className="DMLAN-hidden">

                        <a className="nav-link DarkMode dark-them" onClick={theme.themeChanger}>
                            {theme.theme == 'dark' ? <LightModIco/> :
                                <DarkModeIco/>}
                        </a>
                                          <a className={`nav-item Nav-menu`}>
          <button className="Nav-menu-title languageIco " aria-controls="simple-menu"
                  aria-haspopup="true">
                    <TranslateOutlinedIcon className="fas fa-id-badge"/>{" "}
          </button>

                                              {location == "/id_verification" ? "" :     <ul className="Nav-menu-dropdown language-dropdown">
                                                  <ListItem
                                                      onClick={() => context.selectLanguage('en')}><ListItemText>{context.locale === 'en' &&
                                                  <div
                                                      className='checked'><FaCheck/></div>}{"          "}<span>English</span></ListItemText></ListItem>
                                                  <ListItem
                                                      onClick={() => context.selectLanguage('fa')}><ListItemText>{context.locale === 'fa' &&
                                                  <div className='checked'><FaCheck/></div>} {"          "}
                                                      <span>فارسی</span> </ListItemText></ListItem>

                                              </ul>}

                </a>

                    </span>
                    <button
                        className="navbar-toggler "
                        type="button"
                        onClick={() => {
                            setToggle(!toggle);
                        }}
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarToggleExternalContent"
                        aria-controls="navbarToggleExternalContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
          <span className="btn dark-them btn-outline-light">
            <FaBars className="fas fa-bars"/>
          </span>
                    </button>
                </div>
                <div className={` navbar-collapse ${toggle ? "NavColl" : ""}`}>
                    <ul className={`navbar-nav me-auto mb-2 mb-lg-0 start-items ${context.locale == "fa" && 'BNN'}`}>
                        <li className={`nav-item `}>
                            <a onClick={() => history.push("markets")}
                               className={`nav-link ${location == '/markets' ? 'currentLocation' : 'dark-them'}`}
                               style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                <FormattedMessage
                                    id="NavBar.Market"
                                    defaultMessage="Market"
                                />
                            </a>
                        </li>

                        <li className={`nav-item `}>
                            <a onClick={() => window.location = localStorage.getItem("TradeLastCoin") == null ? `/Trade?pair=BTCUSDT` : `/Trade?pair=${localStorage.getItem("TradeLastCoin")}`}
                               className={`nav-link ${location == '/Trade' ? 'currentLocation' : 'dark-them'}`}
                               style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                <FormattedMessage
                                    id="NavBar.Trade"
                                    defaultMessage="Trade"
                                />
                            </a>
                        </li>

                        <li className={`nav-item `}>
                            <a onClick={() => history.push("convert")}
                               className={`nav-link ${location == '/convert' || location == '/Convert' ? 'currentLocation' : 'dark-them'}`}
                               style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                {/*<FormattedMessage*/}
                                {/*    id="NavBar.Trade"*/}
                                {/*    defaultMessage="Trade"*/}
                                {/*/>*/}
                                <FormattedMessage
                                    id="Convert.Title"
                                    defaultMessage="Convert"
                                />
                            </a>
                        </li>

                    </ul>
                    <span className=" navbar-text ">
            <div className>
              <ul className="navbar-nav end-items mt-1" id="first-nav">

                <li className={`nav-item Nav-menu ${!isLoggedIn && "d-none"}`}>
                         <button className="Nav-menu-title walletIco " aria-controls="simple-menu"
                                 aria-haspopup="true">
                    <WalletIco/>
                         </button>

                      <ul className="Nav-menu-dropdown walletDropdown">
                                <ListItem
                                    onClick={() => history.push("wallet_overview")}><ListItemText>
                                     <FormattedMessage id="WalletsOverview"
                                                       defaultMessage="WalletsOverview"/>
                                </ListItemText></ListItem>
                                <ListItem
                                    onClick={() => history.push("withdraw?pair=BTC")}><ListItemText>
                                        <FormattedMessage id="Withdraw.Title"
                                                          defaultMessage="WithdrawIco.Title"/>
                                </ListItemText></ListItem>
                                <ListItem
                                    onClick={() => history.push("deposit?pair=BTC")}><ListItemText>
                                        <FormattedMessage id="Deposit.Title"
                                                          defaultMessage="Deposit.Title"/>
                                </ListItemText></ListItem>
                      </ul>

                </li>

                           <li className={`nav-item Nav-menu ${!isLoggedIn && "d-none"}`}>
          <button className="Nav-menu-title ordersIco " onClick={() => history.push("orders")}
                  aria-controls="simple-menu"
                  aria-haspopup="true">
                    <OrdersIco/>{" "}
          </button>

                               {/*              <ul className="Nav-menu-dropdown account-dropdown">*/}
                               {/*          <ListItem onClick={() => history.push("settings")}><ListItemText>Settings</ListItemText></ListItem>*/}
                               {/*          <ListItem*/}
                               {/*              onClick={() => history.push("account")}><ListItemText>Account</ListItemText></ListItem>*/}
                               {/*          <ListItem*/}
                               {/*              onClick={() => history.push("accountLevel")}><ListItemText>Account Level</ListItemText></ListItem>*/}

                               {/*</ul>*/}
                </li>

                                  <li className={`nav-item Nav-menu ${!isLoggedIn && "d-none"}`}>
          <button className="Nav-menu-title walletIco " aria-controls="simple-menu"
                  aria-haspopup="true">
                    <SettingIco/>{" "}
          </button>

                                    <ul className="Nav-menu-dropdown account-dropdown">
                                <ListItem onClick={() => history.push("referral")}><ListItemText>
                                                    <FormattedMessage id="Account.Info.Settings"
                                                                      defaultMessage="Setting"/>
                                </ListItemText></ListItem>
                                <ListItem
                                    onClick={() => history.push("account")}><ListItemText>
                                     <FormattedMessage id="Account.Info.AccountSettings"
                                                       defaultMessage="AccountSettings"/>
                                </ListItemText></ListItem>
                                <ListItem
                                    onClick={() => history.push("id_verification")}><ListItemText>
                                          <FormattedMessage id="SettingsDropDown.IdVerification"
                                                            defaultMessage="SettingsDropDown.IdVerification"/>
                                </ListItemText></ListItem>

                      </ul>
                </li>

                                        <li className={`nav-item Nav-menu ${!isLoggedIn && "d-none"}`}>
                            <button className="Nav-menu-title " aria-controls="simple-menu"
                                    aria-haspopup="true">
                                <AccInfo/>
                            </button>
                                            {/*<a onClick={() => history.push("withdraw")}*/}
                                            {/*   className="nav-link bell dark-them m-0" href="#">*/}
                                            {/*    <FormattedMessage*/}
                                            {/*        id="NavBar.WithdrawIco"*/}
                                            {/*        defaultMessage="Finance"*/}
                                            {/*    />{" "}*/}
                                            {/*</a>*/}

                                            <ul className="Nav-menu-dropdown">

                                 <ListItem alignItems="flex-start">

        <ListItemAvatar>
          <Avatar style={{width: 60, height: 60}}/>
        </ListItemAvatar>

                     <IconButton style={{textAlign: "left"}} disabled={true}>
                                     <Badge
                                         style={theme.theme == 'dark' ? {
                                             color: "#fff",
                                             marginRight: 7
                                         } : {color: "#000", marginRight: 7}}
                                         badgeContent={` Level  ${userInfo.vip_level != undefined ? userInfo.vip_level + 1 : ' 1 '} `}
                                         color="default">

        <ListItemText
            secondary={
                <div className="navInfo-container">
                    <Typography
                        component="div"
                        variant="body2"
                        color="text.primary"
                    >

                        {userInfo.first_name != null ? userInfo.first_name : "####"}
                        {" "}
                        {userInfo.last_name != null ? userInfo.last_name : "####"}
                    </Typography>
                    <Typography
                        component="div"
                        variant="body2"
                        color="text.primary"
                    >
                        {userInfo == false ? "####" : userInfo.email}
                    </Typography>

                </div>
            }
        />

                                     </Badge>
                     </IconButton>
      </ListItem>

      <Divider variant="inset" component="div"/>
                              <li className="text-start ps-2 " onClick={logOut}><ListItemText>   <FormattedMessage
                                  id="NavBar.Logout"
                                  defaultMessage="NavBar.Logout"/> <RiLogoutBoxRLine/> </ListItemText></li>

                            </ul>
                        </li>

                <li className={`nav-item  d-none`}>
                  <a onClick={() => history.push("messages")}
                     className="nav-link bell dark-them m-0" href="#">
                         <Badge badgeContent={4} color="secondary">
      <BsBellFill className="fas fa-bell"/>
    </Badge>{" "}
                  </a>
                </li>
                <li className={`NavLoginBtn nav-item ${isLoggedIn && "d-none"} `} style={{padding:0}}>
                    <LogInNav />
                </li>

                                       <li className={`nav-item Nav-menu languageChanger`}>
          <button className="Nav-menu-title languageIco btn" aria-controls="simple-menu"
                  aria-haspopup="true">
                    <LanguageIco className="fas fa-id-badge"/>
          </button>

                                           {location == "/id_verification" ? "" : <ul className="Nav-menu-dropdown language-dropdown">
                                               <ListItem onClick={() => context.selectLanguage('en')}><ListItemText> <span
                                                   className="languageChangerText">English</span> {"       "} {context.locale === 'en' &&
                                               <div className='checked'><FaCheck/></div>}</ListItemText>
                                               </ListItem>
                                               <ListItem
                                                   onClick={() => context.selectLanguage('fa')}><ListItemText> <span
                                                   className="languageChangerText">فارسی</span> {"       "} {context.locale === 'fa' &&
                                               <div className='checked'><FaCheck/></div>}</ListItemText></ListItem>

                                           </ul>}

                </li>

                  <li className="nav-item  darkThemAll" onClick={theme.themeChanger}>
                  <a
                      className="nav-link DarkMode dark-them" style={{padding:0}}>
                                   {theme.theme == 'dark' ? <LightModIco/> :
                                       <DarkModeIco/>}
                  </a>
                </li>



              </ul>
            </div>
          </span>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
