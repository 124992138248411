import React, {useCallback, useContext, useEffect, useState} from 'react';
import Slider from 'react-animated-slider';
import verticalCss from '../../component/css/vertical.scss';
import axios from "axios";
import {CircularProgress} from "@material-ui/core";
import LineGraph from "../IconsClasses/LineGraph";
import {Theme} from "../ThemeContext";
import {parseArgs} from "env-cmd/dist/parse-args";
import JsxParser from "react-jsx-parser";

const VerticalSlider = () => {

    const theme = useContext(Theme).theme;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [tickers, setTickers] = useState([
        {
            "id": 1,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 2,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 3,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 4,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 5,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 6,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 7,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 8,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
        {
            "id": 9,
            "name": "#####",
            "lastPrice": "#####",
            "dayOpen": "#####",
            "volume": "#####",
            "value": "#####",
            "DayChange": "#####"
        },
    ]);
    const [chartData, setChartData] = useState([]);

    const fetchCoinHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {

            const listResponse = await axios.get('currencies/list');

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }

            const marketL = (listResponse.data);

            const loadedList = [];


            for (var keys in marketL) {
                loadedList.push({
                    name: marketL[keys].markets,
                    rank: marketL[keys].rank,
                    logo: marketL[keys].logo
                });
            }


            const response = await axios.get('core/marketTickerAll');

            if (response.status !== 200) {
                throw new Error('Something went wrong!');
            }

            const data = await (response.data.data.ticker);

            const loadedData = [];
            const loadedTL = [];
            const loadedVL = [];

            for (var i = 0; i <= loadedList.length; i++) {
                for (var key in data) {
                    if (loadedList[i] != undefined && loadedList[i].name != undefined && key == loadedList[i].name[0]) {
                        loadedData.push({
                            id: loadedList[i].rank,
                            logo: loadedList[i].logo,
                            name: key,
                            lastPrice: data[key].last,
                            dayOpen: data[key].open,
                            volume: parseFloat(data[key].vol).toFixed(2),
                            value: (data[key].vol * data[key].last).toFixed(2),
                            DayChange: (((data[key].last - data[key].open) / data[key].open) * 100).toFixed(2)
                        });
                    }
                }
            }

            loadedData.sort(function (a, b) {
                return b.value - a.value
            });

            setTickers(loadedData);
            fetchChartData(loadedData);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const fetchChartData = useCallback(async (loadedData) => {
        setIsLoading(true);
        setError(null);
        try {

            const listResponse = await axios.get(`/chart`);

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }

            const loadedTL = [];
            const CHData = (listResponse.data.data);

            for (var k = 0; k < 8; k++) {
                for (var key in CHData) {
                    if ( key != undefined && (loadedData[k].name.split("USDT")[0]) == key){
                        loadedTL.push(CHData[key]);
                    }
                }
            }
            
            setChartData(loadedTL);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCoinHandler();

    }, [fetchCoinHandler]);


    let content;

    if (tickers.length === 0) {
        content = <p>Found no Coin.</p>;
    }


    if (isLoading) {
        content = <p><CircularProgress/></p>;
    }

    return (
        <div>

            <Slider classNames={verticalCss} direction="vertical" autoplay="3000">
                <div>
                    <div className={`center ${isLoading && 'blurForLoading'}`}>
                        <ul className="list-group list-group-horizontal justify-content-center ">
                            {tickers.slice(0, 4).map((item , index) => (
                                <div className=" priceList card-body ">
                                    <div className="priceListContainer">
                                        <div className="infoContent">
                                            <div
                                                className={`${theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                <JsxParser className={"VSliderLogo"} jsx={item.logo}/>
                                            </div>
                                            <h5 className={`sliderPercent ${item.name.split("USDT")[0].length > 7 && 'SNN'}`}>{item.name.split("USDT")[0]}
                                                <br/>
                                                <span
                                                    className={`${item.DayChange > 0 ? 'inGrow' : 'inLose'}`}>{item.DayChange > 0 && '+'}{item.DayChange}%</span>
                                            </h5>
                                            {/*<span className="price">{item.value}$</span>*/}
                                        </div>
                                        <div className="chartContainer">
                                            <LineGraph chartData={chartData[index]}  percent={item.DayChange}/>
                                        </div>
                                    </div>
                                    <p className="exact-price">{item.lastPrice} $</p>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="center">
                        <ul className="list-group list-group-horizontal justify-content-center">
                            {tickers.slice(4, 8).map((item , index) => (
                                <div className=" priceList card-body ">
                                    <div className="priceListContainer">
                                        <div className="infoContent">
                                            <div
                                                className={`${theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                <JsxParser className={"VSliderLogo"} jsx={item.logo}/>
                                            </div>
                                            <h5 className={`sliderPercent ${item.name.split("USDT")[0].length > 7 && 'SNN'}`}>{item.name.split("USDT")[0]}
                                                <br/>
                                                <span
                                                    className={`${item.DayChange > 0 ? 'inGrow' : 'inLose'}`}>{item.DayChange > 0 && '+'}{item.DayChange}%</span>
                                            </h5>
                                            {/*<span className="price">{item.value}$</span>*/}
                                        </div>
                                        <div className="chartContainer">
                                          <LineGraph chartData={chartData[index+4]} percent={item.DayChange}/>
                                        </div>
                                    </div>
                                    <p className="exact-price">{item.lastPrice} $</p>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="center">
                        <ul className="list-group list-group-horizontal justify-content-center">
                            {tickers.slice(0, 4).map((item , index) => (
                                <div className=" priceList card-body ">
                                    <div className="priceListContainer">
                                        <div className="infoContent">
                                            <div
                                                className={`${theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                <JsxParser className={"VSliderLogo"} jsx={item.logo}/>
                                            </div>
                                            <h5 className={`sliderPercent ${item.name.split("USDT")[0].length > 7 && 'SNN'}`}>{item.name.split("USDT")[0]}
                                                <br/>
                                                <span
                                                    className={`${item.DayChange > 0 ? 'inGrow' : 'inLose'}`}>{item.DayChange > 0 && '+'}{item.DayChange}%</span>
                                            </h5>
                                            {/*<span className="price">{item.value}$</span>*/}
                                        </div>
                                        <div className="chartContainer">
                                                              <LineGraph chartData={chartData[index]} percent={item.DayChange}/>
                                        </div>
                                    </div>
                                    <p className="exact-price">{item.lastPrice} $</p>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

                <div>
                    <div className="center">
                        <ul className="list-group list-group-horizontal justify-content-center">
                            {tickers.slice(4, 8).map((item , index) => (
                                <div className=" priceList card-body ">
                                    <div className="priceListContainer">
                                        <div className="infoContent">
                                            <div
                                                className={`${theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                <JsxParser className={"VSliderLogo"} jsx={item.logo}/>
                                            </div>
                                            <h5 className={`sliderPercent ${item.name.split("USDT")[0].length > 7 && 'SNN'}`}>{item.name.split("USDT")[0]}
                                                <br/>
                                                <span
                                                    className={`${item.DayChange > 0 ? 'inGrow' : 'inLose'}`}>{item.DayChange > 0 && '+'}{item.DayChange}%</span>
                                            </h5>
                                            {/*<span className="price">{item.value}$</span>*/}
                                        </div>
                                        <div className="chartContainer">
                                                              <LineGraph chartData={chartData[index+4]} percent={item.DayChange}/>
                                        </div>
                                    </div>
                                    <p className="exact-price">{item.lastPrice} $</p>
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>

            </Slider>

        </div>
    );
};

export default VerticalSlider;
