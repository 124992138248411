import React from 'react';

function SettingIco(props) {
    return (
        <div className="SettingIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.SettingIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="SettingIcoSt0"
                        d="M43.8,96.1c2.5,0.4,5,0.6,7.7,0.6c2.9,0,5.7-0.3,8.5-0.8l1.1-8.8c3.5-0.9,6.7-2.3,9.8-4.1l7.8,4.9
		c4-2.9,7.5-6.4,10.4-10.3l-4.5-8.2c1.8-3.1,3.2-6.4,4-9.9h8.5c0.6-3,0.9-6.1,0.9-9.3c0-2.6-0.2-5.1-0.6-7.6l-8.8-1.7
		c-0.9-3.6-2.3-7.1-4.2-10.2 M78.6,12.4l-7.5,5c-3.3-2-6.8-3.4-10.6-4.3c-0.4-3-0.9-6-1.3-8.7c-2.5-0.4-5.1-0.6-7.7-0.6
		c-2.8,0-5.5,0.2-8.2,0.7l-1.5,8.8l0,0c-3.5,0.9-6.8,2.3-9.8,4.1L25,12.1l0,0l-6.6,5.6c-1.6,1.6-3.1,3.4-4.4,5.2l4.6,8.2
		c-1.7,2.9-3,6-3.8,9.3l-9,2.3C5.2,45.2,5,47.7,5,50.2c0,2.7,0.2,5.3,0.7,7.8l8.8,1.5c1,4.1,2.7,7.8,4.9,11.3l-6.1,5.9
		c3.1,4.4,6.9,8.3,11.3,11.4l7.6-5v0 M51.5,66.6c-9,0-16.4-7.3-16.4-16.4s7.3-16.4,16.4-16.4s16.4,7.3,16.4,16.4
		S60.5,66.6,51.5,66.6z"
                    />
                </g>
            </svg>
        </div>
    );
}

export default SettingIco;