import React from 'react';

function AccInfo(props) {
    return (
        <div className="AccInfo">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.AccInfoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="AccInfoSt0"
                        d="M50.1,45.4c14.8,0,21.7,9.1,25.5,17.6c1.8,4-1.4,8.2-5.8,8.2H32.7 M50.1,45.4c-4.8,0-15.8-3.2-15.8-16.1
		c0-9.7,6.3-16.1,15.8-16.1c9.5,0,15.8,6.4,15.8,16.1C66,35.8,61.5,45.4,50.1,45.4z M50.1,45.4c-15.8,0-22.7,10.4-26.3,19.3"
                    />
                    <path
                        className="AccInfoSt0"
                        d="M7.6,27.3v-14c0-4.8,3.9-8.7,8.7-8.7h67.3c4.8,0,8.7,3.9,8.7,8.7v59.5c0,4.8-3.9,8.7-8.7,8.7H74"
                    />
                    <path
                        className="AccInfoSt0"
                        d="M59.4,85.3l-7.1,10.9c-1,1.8-3.6,1.8-4.6,0L36.5,81.4H16.3c-4.8,0-8.7-3.9-8.7-8.7V54.2"
                    />
                </g>
            </svg>
        </div>
    );
}

export default AccInfo;