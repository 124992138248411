import React, {useContext} from 'react';
import {useHistory} from "react-router-dom";
import {Context} from "../../translation/Wrapper";

function LogInBtn(props) {

    const history = useHistory();
    const context = useContext(Context);

    return (
        <button className="LogInBtn"  onClick={() => history.push("login")}  disabled={!props.loginIsValid}>

            {context.locale == "fa"  ?
                <svg viewBox="0 0 250 120">
                    <style>
                        {".LogInBtnSt0{fill:none;stroke-width:3.4772;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".LogInBtnSt1{fill:none;stroke:#000000;stroke-width:3.4772;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                    </style>
                    <path className="LogInBtnSt1"
                          d="M53.2,15.7h139.2c2.7,0,5,2.2,5,5v78.7c0,2.7-2.2,5-5,5H7.6c-2.7,0-5-2.2-5-5V46.7"/>
                    <line className="LogInBtnSt1" x1="39.7" y1="15.7" x2="41.7" y2="15.7"/>

                    <path className="LogInBtnSt0" d="M75.8,57.2c0,1.3-0.2,2.5-0.6,3.5c-0.4,1.1-1,1.9-1.7,2.6c-0.7,0.7-1.6,1.3-2.5,1.8c-0.9,0.5-1.9,0.9-3,1.2
		S65.9,66.9,65,67s-2,0.2-2.9,0.2c-2.3,0-5-0.4-7.9-1.2l1.8-7.1c2.6,0.6,4.7,0.9,6.1,0.9c1.2,0,2.3-0.1,3.2-0.3
		c0.9-0.2,1.5-0.4,2-0.7c0.4-0.3,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.5,0.3-0.8c0-3-2.6-6.7-7.7-11l4.8-5.7c1.6,1.3,2.9,2.6,4.1,3.8
		c1.2,1.2,2.3,2.5,3.2,3.9c1,1.4,1.7,2.8,2.2,4.3S75.8,55.7,75.8,57.2z"/>
                    <path className="LogInBtnSt0" d="M89.3,67.2c-0.3,0-0.8,0-1.4,0c-1.3,0-2.5-0.2-3.7-0.5s-2.3-0.9-3.4-1.6c-1.1-0.7-1.9-1.7-2.5-2.9s-0.9-2.7-0.9-4.3
		c0-1.1,0.1-2.2,0.4-3.3c0.3-1.1,0.7-2.2,1.3-3.3c0.6-1.1,1.2-2,2-2.9c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.3-0.8,3.6-0.8
		c1.4,0,2.7,0.3,3.8,0.8c1.1,0.5,2.1,1.2,2.8,2c0.7,0.8,1.3,1.8,1.9,2.9c0.5,1.1,0.9,2.2,1.2,3.3c0.3,1,0.5,2.1,0.6,3.4
		s0.3,2.2,0.3,2.9c0,0.7,0,1.4,0,2.2c0,1.9-0.3,3.7-0.9,5.3c-0.6,1.6-1.5,3-2.6,4.2c-1.1,1.2-2.4,2.3-4.1,3.3
		c-1.6,1-3.3,1.8-5.1,2.5s-3.8,1.3-6.1,2L77.4,73c2.9-0.7,5.4-1.6,7.5-2.6C87,69.3,88.5,68.3,89.3,67.2z M90.5,59.5
		c-0.3-2.2-0.7-3.9-1.2-5c-0.5-1.1-1.1-1.6-1.8-1.6c-0.7,0-1.3,0.4-1.9,1.3c-0.5,0.9-0.8,2.1-0.8,3.6c0,0.7,0.2,1.3,0.6,1.5
		s1.2,0.4,2.5,0.4C88.8,59.8,89.7,59.7,90.5,59.5z"/>
                    <path className="LogInBtnSt0" d="M103.5,51.4l6.9-2.8c2.1,5.4,3.2,9.9,3.2,13.4c0,4.3-1.3,8-3.9,11.1c-2.6,3-6.3,5.4-11.1,7.2l-2.5-7
		c3.6-1.3,6.1-2.8,7.7-4.5s2.3-4,2.3-6.7C106.1,59.3,105.2,55.8,103.5,51.4z"/>
                    <path className="LogInBtnSt0" d="M127,67.2c-0.3,0-0.8,0-1.4,0c-1.3,0-2.5-0.2-3.7-0.5c-1.2-0.4-2.3-0.9-3.4-1.6s-1.9-1.7-2.5-2.9c-0.6-1.2-0.9-2.7-0.9-4.3
		c0-1.1,0.1-2.2,0.4-3.3c0.3-1.1,0.7-2.2,1.3-3.3c0.6-1.1,1.2-2,2-2.9c0.8-0.8,1.7-1.5,2.8-2c1.1-0.5,2.3-0.8,3.6-0.8
		c1.4,0,2.7,0.3,3.8,0.8c1.1,0.5,2.1,1.2,2.8,2c0.7,0.8,1.3,1.8,1.9,2.9s0.9,2.2,1.2,3.3c0.3,1,0.5,2.1,0.6,3.4
		c0.2,1.2,0.3,2.2,0.3,2.9c0,0.7,0,1.4,0,2.2c0,1.9-0.3,3.7-0.9,5.3c-0.6,1.6-1.5,3-2.6,4.2c-1.1,1.2-2.4,2.3-4.1,3.3
		c-1.6,1-3.3,1.8-5.1,2.5c-1.8,0.7-3.8,1.3-6.1,2l-1.9-7.2c2.9-0.7,5.4-1.6,7.5-2.6C124.7,69.3,126.2,68.3,127,67.2z M128.2,59.5
		c-0.3-2.2-0.7-3.9-1.2-5c-0.5-1.1-1.1-1.6-1.8-1.6c-0.7,0-1.3,0.4-1.9,1.3c-0.5,0.9-0.8,2.1-0.8,3.6c0,0.7,0.2,1.3,0.6,1.5
		s1.2,0.4,2.5,0.4C126.6,59.8,127.4,59.7,128.2,59.5z"/>
                </svg>

                :
                <svg viewBox="0 0 250 120">
                    <style>
                        {".LogInBtnSt0{;stroke-width:2;stroke-miterlimit:10;}"}
                        {".LogInBtnSt1{fill:none;stroke:#000000;stroke-width:3.4772;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                    </style>
                    <path className="LogInBtnSt0" d="M39.7,47.4h6.1V70h13.5v5.1H39.7V47.4z"/>
                    <path className="LogInBtnSt0" d="M61.5,61.4c0-2.1,0.3-4,0.9-5.7c0.6-1.8,1.5-3.3,2.7-4.7c1.2-1.3,2.6-2.4,4.3-3.1s3.6-1.1,5.7-1.1
				c2.2,0,4.1,0.4,5.8,1.1c1.7,0.8,3.1,1.8,4.3,3.1c1.2,1.3,2.1,2.9,2.7,4.7s0.9,3.7,0.9,5.7s-0.3,3.9-0.9,5.6
				c-0.6,1.8-1.5,3.3-2.7,4.6c-1.2,1.3-2.6,2.3-4.3,3.1c-1.7,0.8-3.6,1.1-5.8,1.1c-2.1,0-4.1-0.4-5.7-1.1s-3.1-1.8-4.3-3.1
				c-1.2-1.3-2.1-2.8-2.7-4.6C61.9,65.3,61.5,63.4,61.5,61.4z M67.7,61.4c0,1.2,0.1,2.3,0.4,3.4c0.3,1.1,0.7,2.1,1.3,3
				s1.4,1.6,2.3,2.1c1,0.5,2.1,0.8,3.5,0.8s2.5-0.3,3.5-0.8s1.7-1.2,2.3-2.1c0.6-0.9,1-1.9,1.3-3s0.4-2.2,0.4-3.4s-0.1-2.4-0.4-3.5
				s-0.7-2.2-1.3-3.1c-0.6-0.9-1.4-1.6-2.3-2.1s-2.1-0.8-3.5-0.8s-2.5,0.3-3.5,0.8s-1.7,1.2-2.3,2.1s-1,1.9-1.3,3.1
				C67.8,59,67.7,60.2,67.7,61.4z"/>
                    <path className="LogInBtnSt0" d="M113.5,72c-1.1,1.4-2.3,2.4-3.6,3s-2.7,0.9-4,0.9c-2.1,0-4.1-0.4-5.7-1.1c-1.7-0.8-3.1-1.8-4.3-3.1
				c-1.2-1.3-2.1-2.8-2.7-4.6c-0.6-1.8-0.9-3.6-0.9-5.6c0-2.1,0.3-4,0.9-5.7c0.6-1.8,1.5-3.3,2.7-4.7c1.2-1.3,2.6-2.4,4.3-3.1
				c1.7-0.8,3.6-1.1,5.7-1.1c1.4,0,2.8,0.2,4.1,0.6c1.3,0.4,2.5,1.1,3.6,1.9c1.1,0.8,1.9,1.9,2.7,3.1s1.2,2.6,1.3,4.2h-5.8
				c-0.4-1.6-1.1-2.7-2.1-3.5S107.4,52,106,52c-1.4,0-2.5,0.3-3.5,0.8s-1.7,1.2-2.3,2.1s-1,1.9-1.3,3.1c-0.3,1.1-0.4,2.3-0.4,3.5
				s0.1,2.3,0.4,3.4s0.7,2.1,1.3,3s1.4,1.6,2.3,2.1c1,0.5,2.1,0.8,3.5,0.8c2,0,3.6-0.5,4.7-1.5s1.8-2.5,1.9-4.4h-6.1v-4.5H118v15
				h-3.9L113.5,72z"/>
                    <path className="LogInBtnSt0" d="M122.9,47.4h6.1v27.7h-6.1V47.4z"/>
                    <path className="LogInBtnSt0"
                          d="M134.4,47.4h6.1L152,66h0.1V47.4h5.7v27.7h-6.1l-11.5-18.6h-0.1v18.6h-5.7V47.4z"/>

                    <path className="LogInBtnSt1"
                          d="M53.2,15.7h139.2c2.7,0,5,2.2,5,5v78.7c0,2.7-2.2,5-5,5H7.6c-2.7,0-5-2.2-5-5V46.7"/>
                    <line className="LogInBtnSt0" x1="39.7" y1="15.7" x2="41.7" y2="15.7"/>
                </svg>

            }

        </button>
    );
}

export default LogInBtn;