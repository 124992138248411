import React from 'react';

const CoinTelegraph = () => {
    return (

        <a href="https://cointelegraph.com/" className="CoinTelegraph " target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 20.4 18.7"
                style={{enableBackground: "new 0 0 20.4 18.7"}}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: "\n\t.CoinTelegraphSt0{}\n\t.CoinTelegraphSt1{}\n"
                    }}
                />
                <path
                    className="CoinTelegraphSt0"
                    d="M12.2,13.6l-3.4,1.4v-0.1c-0.7,0.2-1.3,0.3-2,0.3c-3.2,0-5.7-1.7-5.7-3.6v-0.7c1.4,1.7,3.5,2.6,5.7,2.4
	c0.8,0,1.7-0.1,2.5-0.3l0.3-0.7c-0.9,0.3-1.8,0.4-2.8,0.4c-3.2,0-5.7-1.6-5.7-3.6V8.6c1.4,1.6,3.5,2.5,5.7,2.3
	c1.1,0.1,2.2-0.1,3.2-0.6l0.1-0.7c-1,0.5-2.2,0.8-3.3,0.7c-3.2,0-5.7-1.6-5.7-3.6V6.1c1.4,1.6,3.5,2.5,5.6,2.5c1.4,0,2.7-0.4,3.9-1
	l0.1-0.9C9.7,7.5,8.3,8,6.8,8C3.7,7.9,1.2,6.3,1.2,4.3s2.5-3.6,5.7-3.6c1.7,0,3.4,0.6,4.7,1.7l0.1,0.2h0.7l-0.1-0.2
	C10.9,0.9,8.9,0,6.8,0.1C3.3,0.1,0.6,2,0.6,4.3v7.5c0.1,2.2,2.8,4,6.3,4C8.8,15.8,10.8,15.1,12.2,13.6z"
                />
                <path
                    className="CoinTelegraphSt1"
                    d="M10.9,18.7l1.1-4.9l-3.4,1.4l2.1-8.6h-4l1-4.2h12.7l-1,4.2h-4.1L14.4,9l4.6-1.9L10.9,18.7z M12.9,12.7l-0.6,2.9
	l4.9-7.3l-4,1.7l1.8-4h4l0.7-3H8.2l-0.7,3h4l-1.9,8.1L12.9,12.7L12.9,12.7z"
                />
            </svg>
        </a>
    );
};

export default CoinTelegraph;
