import React, {createContext, useState, Suspense} from "react";
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import App from "./App";
import SocketContext from "./SocketContext";
import 'react-spring-modal/styles.css';
import Convert from "./component/market/Convert";
import {create} from "jss";
import rtl from "jss-rtl";
import {StylesProvider, jssPreset} from "@material-ui/core/styles";
import {LastLocationProvider} from "react-router-last-location";
import LoadingSpinner from "./component/widgets/LoadingSpinner";
import axios from "axios";
import NotAllowed from "./component/widgets/NotAllowed";
import Feedback from "./component/more_pages/Feedback";
import ContactUs from "./component/more_pages/ContactUs";


const jss = create({plugins: [...jssPreset().plugins, rtl()]});

export const LoginContext = createContext();
export const VerificationContext = createContext();


const Root = () => {


    // axios.defaults.baseURL = "http://192.168.2.12:8080/";
// axios.defaults.baseURL = 'http://31.192.237.52:8080/'
// axios.defaults.baseURL = 'http://mohamadserver.ddns.net:8080/'
    axios.defaults.baseURL = 'https://api.sigloyex.com/';

    const NoMatchPage = () => {
        return (
            <h3>404 - Not found</h3>
        );
    };


    const [isLoggedIn, setIsLoggedIn] = useState(
        localStorage.getItem("token") != null
    );

    const [isVerified, setIsVerified] = useState(
        localStorage.getItem("verified") != null &&
        localStorage.getItem("verified") == "true"
    );

    localStorage.removeItem("tempToken");
    localStorage.removeItem("username");

    const LoginAndSignup = React.lazy(() => import('./component/LoginAndSignup/LoginAndSignup'));
    const ForgetPassword = React.lazy(() => import('./component/LoginAndSignup/ForgetPassword'));
    const Account = React.lazy(() => import('./component/accounts/Account'));
    const AccountLevel = React.lazy(() => import('./component/accounts/AccountLevel'));
    const Trade = React.lazy(() => import('./component/market/Trade'));
    const Withdraw = React.lazy(() => import('./component/market/Withdraw'));
    const CoinInfoScreen = React.lazy(() => import('./component/coins/CoinInfoScreen'));
    const DepositScreen = React.lazy(() => import('./component/market/DepositScreen'));
    const Convert = React.lazy(() => import('./component/market/Convert'));
    const FAQScreen = React.lazy(() => import('./component/more_pages/FAQScreen'));
    const IdVerification = React.lazy(() => import('./component/accounts/IdVerification'));
    const MarketList = React.lazy(() => import('./component/market/MarketList'));
    const MessagesScreen = React.lazy(() => import('./component/accounts/MessagesScreen'));
    const SettingScreen = React.lazy(() => import('./component/accounts/SettingScreen'));
    const WalletOverview = React.lazy(() => import('./component/accounts/WalletOverview'));
    const VerifiedScreen = React.lazy(() => import('./component/widgets/VerifiedScreen'));
    const OrdersPage = React.lazy(() => import('./component/market/OrdersPage'));
    const TermsPage = React.lazy(() => import('./component/more_pages/Terms'));
    const AboutUs = React.lazy(() => import('./component/more_pages/AboutUs'));
    const FeesStandard = React.lazy(() => import('./component/more_pages/FeesStandard'));
    const AuthLevel = React.lazy(() => import('./component/more_pages/AuthLevel'));
    const Questions = React.lazy(() => import('./component/more_pages/Questions'));
    const AccountSecurity = React.lazy(() => import('./component/more_pages/AccountSecurity'));
    const BuyTether = React.lazy(() => import('./component/market/BuyTether'));
    const WithdrawFeedBack = React.lazy(() => import('./component/more_pages/WithdrawFeedBack'));
    const APIManagement = React.lazy(() => import('./component/accounts/APIManagement'));
    const Feedback = React.lazy(() => import('./component/more_pages/Feedback'));
    const ContactUs = React.lazy(() => import('./component/more_pages/ContactUs'));


    return (
        <StylesProvider jss={jss}>
            <LoginContext.Provider value={{isLoggedIn, setIsLoggedIn}}>
                <VerificationContext.Provider value={{isVerified, setIsVerified}}>
                    <SocketContext.Consumer>

                        {(value) => {
                            return (
                                <LastLocationProvider>
                                <Suspense
                                    fallback={
                                        <div className='centered'>
                                            <LoadingSpinner/>
                                        </div>
                                    }
                                >


                                    <Switch>
                                        <Route path="/notFound">
                                            <NotAllowed />
                                        </Route>

                                            <Route
                                                component={(props) => <App socket={value} {...props} />}
                                                exact
                                                path="/"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <LoginAndSignup isSignup={false} socket={value} {...props} />
                                                )}
                                                exact
                                                path="/login"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <LoginAndSignup isSignup={true} socket={value} {...props} />
                                                )}
                                                exact
                                                path="/signup"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <TermsPage isSignup={false} socket={value} {...props} />)}
                                                exact
                                                path="/term_policies"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <AboutUs socket={value} {...props} />)}
                                                exact
                                                path="/AboutUs"
                                            />
                                        <Route
                                            component={(props) => (
                                                <Feedback socket={value} {...props} />)}
                                            exact
                                            path="/Feedback"
                                        />
                                        <Route
                                            component={(props) => (
                                                <ContactUs socket={value} {...props} />)}
                                            exact
                                            path="/ContactUs"
                                        />
                                            <Route
                                                component={(props) => (
                                                    <FeesStandard socket={value} {...props} />)}
                                                exact
                                                path="/feesStandard"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <AccountSecurity socket={value} {...props} />)}
                                                exact
                                                path="/accountSecurity"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <AuthLevel socket={value} {...props} />)}
                                                exact
                                                path="/authLevel"
                                            />

                                            <Route
                                                component={(props) => (
                                                    <Questions socket={value} {...props} />)}
                                                exact
                                                path="/Questions"
                                            />

                                            <Route
                                                component={(props) => (
                                                    <ForgetPassword socket={value} {...props} />
                                                )}
                                                exact
                                                path="/forgetPassword"
                                            />

                                            <Route
                                                component={(props) => <Account socket={value} {...props} />}
                                                exact
                                                path="/account"
                                            />

                                        <Route
                                            component={(props) => <APIManagement socket={value} {...props} />}
                                            exact
                                            path="/APIManagement"
                                        />

                                            <Route
                                                component={(props) => <Trade socket={value} {...props} />}
                                                exact
                                                path="/trade"
                                            />
                                        <Route
                                            component={(props) => <Trade socket={value} {...props} />}
                                            exact
                                            path="/Trade?pair=btcusdt"
                                        />
                                            {/*<Route*/}
                                            {/*    component={(props) => <BuyTether socket={value} {...props} />}*/}
                                            {/*    exact*/}
                                            {/*    path="/buyTether"*/}
                                            {/*/>*/}
                                            <Route
                                                component={(props) => (
                                                    <Withdraw socket={value} {...props} />
                                                )}
                                                exact
                                                path="/withdraw"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <WithdrawFeedBack socket={value} {...props} />
                                                )}
                                                exact
                                                path="/withdrawFeedBack"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <CoinInfoScreen socket={value} {...props} />
                                                )}
                                                exact
                                                path="/coin_info"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <DepositScreen socket={value} {...props} />
                                                )}
                                                exact
                                                path="/deposit"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <Convert socket={value} {...props} />
                                                )}
                                                exact
                                                path="/convert"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <FAQScreen socket={value} {...props} />
                                                )}
                                                exact
                                                path="/faq"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <IdVerification socket={value} {...props} />
                                                )}
                                                exact
                                                path="/id_verification"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <OrdersPage socket={value} {...props} />
                                                )}
                                                exact
                                                path="/orders"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <MarketList socket={value} {...props} />
                                                )}
                                                exact
                                                path="/markets"
                                            />
                                            {/*<Route*/}
                                            {/*    component={(props) => (*/}
                                            {/*        <MessagesScreen socket={value} {...props} />*/}
                                            {/*    )}*/}
                                            {/*    exact*/}
                                            {/*    path="/messages"*/}
                                            {/*/>*/}
                                            <Route
                                                component={(props) => (
                                                    <SettingScreen socket={value} {...props} />
                                                )}
                                                exact
                                                path="/referral"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <VerifiedScreen socket={value} {...props} />
                                                )}
                                                exact
                                                path="/verified"
                                            />
                                            <Route
                                                component={(props) => (
                                                    <WalletOverview socket={value} {...props} />
                                                )}
                                                exact
                                                path="/wallet_overview"
                                            />
                                            <Redirect exact={true} from="*" to="/notFound" />


                                    </Switch>

                                </Suspense>
                                </LastLocationProvider>
                            );
                        }}

                    </SocketContext.Consumer>
                </VerificationContext.Provider>
            </LoginContext.Provider>
        </StylesProvider>
    );
};

export default Root;
