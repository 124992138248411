import React, {useContext} from 'react';
import {LoginContext} from "../../Root";
import {useHistory} from "react-router-dom";
import {Context} from "../../translation/Wrapper";

function JoinNowBtn(props) {

    const {isLoggedIn, setIsLoggedIn} = useContext(LoginContext);

    const history = useHistory();
    const context = useContext(Context);

    return (
        <div className={`JoinNow ${isLoggedIn && 'd-none'}`} role={"button"} onClick={() => history.push("/signup")}>
            {context.locale == "fa" ?
                <svg viewBox="0 0 250 120">
                    <style>
                        {".JoinNowSt0{fill:none;stroke:#000000;stroke-width:3.8676;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".JoinNowSt1{;stroke-width:1.1645;stroke-miterlimit:10;}"}
                    </style>
                    <g>
                        <g>
                            <path className="JoinNowSt0"
                                  d="M67.6,15.7h172.2c3.3,0,6.2,2.2,6.2,5v78.7c0,2.7-2.7,5-6.2,5H11.2c-3.3,0-6.2-2.2-6.2-5V46.7"/>
                            <line className="JoinNowSt0" x1="50.9" y1="15.7" x2="53.4" y2="15.7"/>
                        </g>
                    </g>
                    <g>
                        <polygon
                            className="JoinNowSt1"
                            points="73.1,55.2 77.2,59.4 81.4,55.1 77.2,50.9 73.1,55 69,50.9 64.8,55.1 69,59.4 	"/>
                        <polygon
                            className="JoinNowSt1"
                            points="101.9,82.7 97.8,78.6 93.6,82.9 97.8,87.1 101.9,83 106,87.1 110.3,82.9 106,78.6 	"/>
                        <path className="JoinNowSt1" d="M104.1,53.7c1.2,4,1.8,6.9,1.8,8.7c0,0.8,0,1.5,0,2c0,0.5-0.1,1-0.3,1.4c-0.1,0.4-0.3,0.8-0.5,1s-0.5,0.4-1,0.6
		c-0.4,0.2-0.9,0.3-1.5,0.3c-0.5,0-1.2,0.1-2.1,0.1c-1.2,0-2.2,0-2.8-0.1c-0.6-0.1-1.1-0.2-1.2-0.4c-0.2-0.1-0.3-0.4-0.3-0.7
		c0-2.2,0.5-5,1.5-8.3l-7.2-1.8l-0.9,3.7c-0.6,2.5-2.5,4.4-5.6,5.7c-3.1,1.3-7.6,1.9-13.3,1.9c-2.2,0-4-0.1-5.5-0.3
		c-1.5-0.2-2.6-0.6-3.4-1c-0.8-0.4-1.3-0.9-1.6-1.4c-0.3-0.5-0.5-1.1-0.5-1.8c0-0.7,0.2-1.6,0.5-3c0.4-1.3,0.7-2.4,1.1-3.4l-6.9-2.8
		c-1.5,3.8-2.2,6.8-2.2,9.2c0,1.9,0.4,3.5,1.1,5s1.7,2.6,2.8,3.5c1.2,0.9,2.6,1.6,4.2,2.2c1.6,0.6,3.3,0.9,4.9,1.2
		c1.6,0.2,3.4,0.3,5.3,0.3c4.2,0,7.9-0.3,11.1-0.9c3.3-0.6,6.1-1.7,8.5-3.3c1.9,2.8,5.3,4.2,10.2,4.2c4.7,0,8.1-1.2,10.2-3.7
		c1.8-2.1,2.7-5.2,2.7-9.1c0-2.7-0.7-6.4-2.2-11L104.1,53.7z"/>
                        <rect className="JoinNowSt1" x="161.4" y="42.3" transform="matrix(0.707 -0.7072 0.7072 0.707 16.1643 129.497)"
                              width="6" height="6"/>
                        <path className="JoinNowSt1" d="M200.5,64.2l-8.6,1.7c-0.6,0.1-1.2,0.2-1.9,0.2c-1.3,0-2.3-0.3-3.1-0.8c-0.8-0.5-1.2-1.2-1.2-2.2c0-1.5,0.4-2.7,1.2-3.7
		s1.8-1.5,3.1-1.5c1.3,0,2.3,0.3,3,0.9c0.7,0.6,1.4,1.4,2.1,2.4l5.8-4.8c-2.9-4-6.5-5.9-10.9-5.9c-2.2,0-4.2,0.6-6,1.7
		c-1.8,1.1-3.2,2.7-4.2,4.6c-1,1.9-1.5,4-1.5,6.3c0,1.6,0.4,3.1,1.2,4.6c-1.1,0-1.9,0-2.5,0c-1.3,0-2.3-0.1-3.1-0.4
		c0.9-1.5,1.3-3.1,1.3-4.8c0-1.2-0.2-2.3-0.6-3.3c-0.4-1-0.9-1.9-1.6-2.7c-0.7-0.7-1.4-1.4-2.3-1.9c-0.9-0.5-1.8-0.9-2.7-1.2
		c-1-0.3-1.9-0.4-2.9-0.4c-2.6,0-5.3,0.8-8,2.4c-2.7,1.6-5.2,4.1-7.4,7.3v-5.5h-7.4v3.1c0,3.2-0.5,5.2-1.4,6.1
		c-0.8,0.8-2.2,1.1-4.4,1.1h-0.8c-0.1-0.8-0.2-1.5-0.3-2.3s-0.3-1.6-0.5-2.6s-0.5-1.9-0.9-2.7c-0.3-0.8-0.8-1.7-1.3-2.5
		s-1.2-1.6-1.8-2.2c-0.7-0.6-1.5-1.1-2.5-1.5c-1-0.4-2.1-0.6-3.2-0.6c-1.3,0-2.5,0.3-3.6,0.8c-1.1,0.5-2.1,1.2-2.8,2
		c-0.8,0.8-1.5,1.8-2,2.9c-0.6,1.1-1,2.2-1.3,3.3c-0.3,1.1-0.4,2.3-0.4,3.3c0,1.6,0.3,3.1,0.9,4.3c0.6,1.2,1.5,2.2,2.5,2.9
		c1,0.7,2.2,1.2,3.4,1.6c1.2,0.4,2.4,0.5,3.7,0.5h1.4c-0.8,1.1-2.3,2.1-4.4,3.2c-2.1,1-4.6,1.9-7.5,2.6l1.9,7.2
		c1.9-0.5,3.7-1.1,5.2-1.6c1.5-0.5,3-1.2,4.5-1.9c1.5-0.8,2.7-1.6,3.8-2.5c1.1-0.9,2-1.9,2.8-3.1c0.8-1.2,1.4-2.5,1.8-3.9h1.3l0,0
		c3.9,0,7-1,9.2-2.9c1.3,1.1,2.9,1.8,4.8,2.2s4.1,0.6,6.6,0.6c4,0,7.7-0.9,11.1-2.6c2.2,1.8,5.2,2.6,8.9,2.6c3.9,0,7.8-0.4,11.6-1.1
		l13.4-2.6L200.5,64.2z M125.4,67.7c-1.3,0-2.2-0.1-2.5-0.4c-0.4-0.3-0.6-0.8-0.6-1.5c0-1.5,0.3-2.7,0.8-3.6
		c0.5-0.9,1.2-1.3,1.9-1.3c0.7,0,1.3,0.6,1.8,1.7c0.5,1.1,0.9,2.8,1.2,5.2L125.4,67.7z M167.3,64.2c-0.2,0.4-0.6,0.8-1.2,1.3
		c-0.6,0.4-1.2,0.8-2,1.1c-0.8,0.3-1.8,0.6-3.1,0.8c-1.2,0.2-2.6,0.3-4.1,0.3c-0.7,0-1.3,0-1.6,0c0.9-1.4,1.8-2.5,2.7-3.5
		c0.9-1,1.8-1.7,2.6-2.2c0.8-0.5,1.5-0.9,2.2-1.1c0.6-0.2,1.2-0.3,1.8-0.3c0.9,0,1.7,0.2,2.3,0.7c0.6,0.5,0.8,1.1,0.8,1.8
		C167.6,63.4,167.5,63.8,167.3,64.2z"/>
                    </g>
                </svg>
                :
                <svg viewBox="0 0 250 120">
                    <style>
                        {".JoinNowSt0{fill:none;stroke:#000000;stroke-width:3.8676;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".JoinNowSt1{;stroke-width:1.1645;stroke-miterlimit:10;}"}
                    </style>
                    <g>
                        <g>
                            <path className="JoinNowSt0"
                                  d="M67.6,15.7h172.2c3.3,0,6.2,2.2,6.2,5v78.7c0,2.7-2.7,5-6.2,5H11.2c-3.3,0-6.2-2.2-6.2-5V46.7"/>
                            <line className="JoinNowSt0" x1="50.9" y1="15.7" x2="53.4" y2="15.7"/>
                        </g>
                    </g>
                    <g>
                        <path className="JoinNowSt1" d="M51.5,65.7c0,0.7,0,1.5-0.1,2.3c-0.1,0.8-0.3,1.6-0.5,2.5c-0.3,0.8-0.6,1.6-1.1,2.4c-0.5,0.8-1.1,1.4-1.9,2
		c-0.8,0.6-1.8,1.1-3,1.4c-1.2,0.4-2.6,0.5-4.2,0.5c-1.4,0-2.7-0.2-4-0.6c-1.3-0.4-2.4-1-3.4-1.8c-1-0.8-1.8-1.9-2.4-3.1
		c-0.6-1.3-0.9-2.8-0.9-4.6v-2.8h6.5v1.5c0,0.8,0.1,1.5,0.2,2.2c0.1,0.7,0.3,1.2,0.6,1.7c0.3,0.5,0.7,0.9,1.3,1.1
		c0.5,0.3,1.2,0.4,2.1,0.4c1,0,1.7-0.2,2.2-0.5c0.5-0.3,0.9-0.8,1.1-1.3c0.2-0.5,0.4-1.1,0.4-1.7c0-0.6,0.1-1.2,0.1-1.8V43.3h7.2
		V65.7z"/>
                        <path className="JoinNowSt1" d="M55.3,64.2c0-1.9,0.3-3.6,0.9-5.1c0.6-1.5,1.4-2.8,2.5-3.9c1.1-1.1,2.3-1.9,3.8-2.5c1.5-0.6,3.2-0.9,5-0.9
		c1.9,0,3.6,0.3,5.1,0.9c1.5,0.6,2.8,1.4,3.9,2.5c1.1,1.1,1.9,2.4,2.5,3.9c0.6,1.5,0.9,3.3,0.9,5.1c0,1.9-0.3,3.6-0.9,5.1
		c-0.6,1.5-1.4,2.8-2.5,3.9c-1.1,1.1-2.4,1.9-3.9,2.5c-1.5,0.6-3.2,0.9-5.1,0.9c-1.9,0-3.5-0.3-5-0.9c-1.5-0.6-2.8-1.4-3.8-2.5
		c-1.1-1.1-1.9-2.4-2.5-3.9C55.6,67.8,55.3,66.1,55.3,64.2z M61.8,64.2c0,0.9,0.1,1.9,0.3,2.8c0.2,0.9,0.5,1.7,0.9,2.4
		c0.4,0.7,1,1.3,1.8,1.7c0.7,0.4,1.6,0.6,2.7,0.6c1.1,0,2-0.2,2.8-0.6c0.7-0.4,1.3-1,1.8-1.7c0.4-0.7,0.8-1.5,0.9-2.4
		c0.2-0.9,0.3-1.8,0.3-2.8c0-0.9-0.1-1.9-0.3-2.8c-0.2-0.9-0.5-1.7-0.9-2.4c-0.4-0.7-1-1.3-1.8-1.7c-0.7-0.4-1.7-0.7-2.8-0.7
		c-1.1,0-2,0.2-2.7,0.7c-0.7,0.4-1.3,1-1.8,1.7c-0.4,0.7-0.8,1.5-0.9,2.4C61.9,62.3,61.8,63.2,61.8,64.2z"/>
                        <path className="JoinNowSt1" d="M90.3,48.6h-6.5v-5.3h6.5V48.6z M83.8,52.3h6.5V76h-6.5V52.3z"/>
                        <path className="JoinNowSt1" d="M95.4,52.3h6.2v3.3h0.1c0.8-1.3,1.9-2.3,3.2-3c1.3-0.6,2.6-1,4-1c1.7,0,3.2,0.2,4.3,0.7c1.1,0.5,2,1.1,2.6,2
		s1.1,1.9,1.3,3.1c0.3,1.2,0.4,2.5,0.4,4V76h-6.5V62.6c0-2-0.3-3.4-0.9-4.4c-0.6-1-1.7-1.4-3.2-1.4c-1.8,0-3,0.5-3.8,1.6
		c-0.8,1.1-1.2,2.8-1.2,5.2V76h-6.5V52.3z"/>
                        <path className="JoinNowSt1" d="M131.5,52.3h6.2v3.3h0.1c0.8-1.3,1.9-2.3,3.2-3c1.3-0.6,2.6-1,4-1c1.7,0,3.2,0.2,4.3,0.7c1.1,0.5,2,1.1,2.6,2
		c0.6,0.8,1.1,1.9,1.3,3.1c0.3,1.2,0.4,2.5,0.4,4V76h-6.5V62.6c0-2-0.3-3.4-0.9-4.4c-0.6-1-1.7-1.4-3.2-1.4c-1.8,0-3,0.5-3.8,1.6
		c-0.8,1.1-1.2,2.8-1.2,5.2V76h-6.5V52.3z"/>
                        <path className="JoinNowSt1" d="M158,64.2c0-1.9,0.3-3.6,0.9-5.1c0.6-1.5,1.4-2.8,2.5-3.9c1.1-1.1,2.3-1.9,3.8-2.5c1.5-0.6,3.2-0.9,5-0.9
		c1.9,0,3.6,0.3,5.1,0.9c1.5,0.6,2.8,1.4,3.9,2.5c1.1,1.1,1.9,2.4,2.5,3.9c0.6,1.5,0.9,3.3,0.9,5.1c0,1.9-0.3,3.6-0.9,5.1
		c-0.6,1.5-1.4,2.8-2.5,3.9c-1.1,1.1-2.4,1.9-3.9,2.5c-1.5,0.6-3.2,0.9-5.1,0.9c-1.9,0-3.5-0.3-5-0.9c-1.5-0.6-2.8-1.4-3.8-2.5
		c-1.1-1.1-1.9-2.4-2.5-3.9C158.3,67.8,158,66.1,158,64.2z M164.5,64.2c0,0.9,0.1,1.9,0.3,2.8c0.2,0.9,0.5,1.7,0.9,2.4
		c0.4,0.7,1,1.3,1.8,1.7c0.7,0.4,1.6,0.6,2.7,0.6c1.1,0,2-0.2,2.8-0.6c0.7-0.4,1.3-1,1.8-1.7c0.4-0.7,0.8-1.5,0.9-2.4
		c0.2-0.9,0.3-1.8,0.3-2.8c0-0.9-0.1-1.9-0.3-2.8c-0.2-0.9-0.5-1.7-0.9-2.4c-0.4-0.7-1-1.3-1.8-1.7c-0.7-0.4-1.7-0.7-2.8-0.7
		c-1.1,0-2,0.2-2.7,0.7c-0.7,0.4-1.3,1-1.8,1.7c-0.4,0.7-0.8,1.5-0.9,2.4C164.6,62.3,164.5,63.2,164.5,64.2z"/>
                        <path className="JoinNowSt1" d="M213.7,76H207l-4.2-15.9h-0.1l-4,15.9h-6.7l-7.5-23.7h6.9l4.3,16.1h0.1l3.9-16.1h6.3l4,16h0.1l4.3-16h6.7
		L213.7,76z"/>
                    </g>
                </svg>
            }


        </div>
    );
}

export default JoinNowBtn;