import React from 'react';

const BlockChain = () => {
    return (

        <a href="https://www.blockchain.com/" className="BlockChain " target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 20.4 18.7"
                style={{enableBackground: "new 0 0 20.4 18.7"}}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.BlockChainSt0{}\n\t.BlockChainSt1{}\n\t.BlockChainSt2{}\n"
                    }}
                />
                <path
                    className="BlockChainSt0"
                    d="M15.9,5.4l-5.7,2.9L4.5,5.4l4.3-4.3c0.8-0.8,2-0.8,2.8,0L15.9,5.4z"
                />
                <path
                    className="BlockChainSt1"
                    d="M9,18.2c-0.1,0-0.2-0.1-0.2-0.2l-7-7c-0.8-0.8-0.8-2,0-2.8l1-1.1L9,10.2L9,18.2z"
                />
                <path
                    className="BlockChainSt2"
                    d="M18.7,10.9l-7.1,7c-0.1,0.1-0.1,0.1-0.2,0.2l0-8l6.2-3.2l1.1,1.1C19.4,8.9,19.4,10.2,18.7,10.9z"
                />
            </svg>
        </a>
    );
};

export default BlockChain;
