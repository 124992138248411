import React, {useContext} from 'react';
import {Context} from "../../translation/Wrapper";

function PlaceBtn(props) {

    const context = useContext(Context);

    return (
        <>
            {context.locale == "fa"  ?
                (
                <svg viewBox="0 0 250 120">
                    <style>
                        {".PlaceBtnSt0{fill:none;stroke:#000000;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                        {".PlaceBtnSt1{fill:none;}"}
                    </style>
                    <g>
                        <polygon
                            className="PlaceBtnSt1"
                            points="102.7,53.8 107.2,58.3 111.8,53.7 107.2,49.1 102.7,53.6 98.2,49.1 93.6,53.7 98.2,58.3 	"/>
                        <rect
                            className="PlaceBtnSt1" x="133.2" y="81" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -19.6035 121.1266)"
                              width="6.5" height="6.5"/>
                        <polygon
                            className="PlaceBtnSt1"
                            points="159.9,43.6 164.5,48.1 169.1,43.5 164.5,38.9 159.9,43.4 155.4,38.9 150.8,43.5 155.4,48.1 	"/>
                        <rect
                            className="PlaceBtnSt1" x="156.8" y="32.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 21.5708 123.6229)"
                              width="6.5" height="6.5"/>
                        <path
                            className="PlaceBtnSt1" d="M158.5,52.5c0.5,1.5,0.9,3.2,1.3,4.9c0.4,1.7,0.5,3.1,0.5,4.2c0,2.5-0.4,4.3-1.2,5.2c-0.2,0.3-0.8,0.6-1.8,0.9
		s-2.2,0.5-3.6,0.5c-2.7,0-4.5-0.5-5.4-1.6c-1.1-1.2-1.6-3.5-1.6-7.1v-2.7h-6.8v2.8c0,3.6-0.6,6-1.7,7.2c-1,1-2.8,1.5-5.3,1.5
		c-1.3,0-2.3,0-3-0.1s-1.3-0.3-1.6-0.6c-0.3-0.3-0.6-0.6-0.6-0.9c-0.1-0.3-0.1-0.8-0.1-1.4c0-1.5,0.5-4,1.5-7.6l-6.6-1.7
		c-0.4,1.5-0.6,2.6-0.8,3.3c-0.8,3-3,5.2-6.5,6.7c-3.6,1.4-8.5,2.2-15,2.2c-2.4,0-4.5-0.1-6.1-0.4c-1.7-0.3-3-0.7-3.9-1.2
		s-1.6-1.1-2-1.8c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.7,0.2-1.8,0.5-3.1c0.3-1.3,0.7-2.5,1.1-3.5l-6.3-2.6c-1.4,3.7-2.1,6.8-2.1,9.2
		c0,1.4,0.2,2.7,0.6,3.8c0.4,1.2,0.9,2.2,1.6,3s1.5,1.6,2.5,2.3c1,0.7,2,1.2,3.1,1.6s2.3,0.8,3.6,1c1.3,0.3,2.6,0.5,3.9,0.6
		s2.7,0.2,4.1,0.2c4.7,0,8.9-0.4,12.5-1.1s6.7-2,9.3-3.8c1.8,3.3,5.5,4.9,11,4.9c4.6,0,8-1.3,10.5-3.8c2.5,2.5,6,3.8,10.4,3.8
		c4.9,0,8.5-1.3,10.6-3.8c1.9-2.2,2.8-5.4,2.8-9.6c0-3.3-0.7-7-2.2-11.2L158.5,52.5z"/>
                    </g>
                    <g>
                        <path className="PlaceBtnSt0"
                              d="M67.2,5.2h171.9c3.3,0,6.2,2.7,6.2,6.2v97.2c0,3.3-2.7,6.2-6.2,6.2H10.9c-3.3,0-6.2-2.7-6.2-6.2V43.5"/>
                        <line className="PlaceBtnSt1" x1="50.5" y1="5.2" x2="53" y2="5.2"/>
                    </g>
                </svg>

                )
                :
                (
                <svg viewBox="0 0 250 120">
                <style>
            {".PlaceBtnSt0{fill:none;stroke:#000000;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
            {".PlaceBtnSt1{fill:none;}"}
                </style>
                <g>
                <path className="PlaceBtnSt1" d="M63.2,44.4h15.4c2.2,0,4,0.4,5.6,1c1.5,0.6,2.7,1.5,3.7,2.5c1,1,1.6,2.2,2.1,3.6c0.5,1.4,0.6,2.7,0.6,4.1
		c0,1.4-0.2,2.7-0.6,4.1c-0.5,1.4-1.1,2.5-2.1,3.5s-2.1,1.9-3.7,2.5c-1.5,0.6-3.3,1-5.6,1h-7.9v12.2h-7.5V44.4z M70.8,60.4h5.8
		c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.5-0.4,2.1-0.7c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.6,0.5-1.5,0.5-2.6c0-1.1-0.1-2-0.5-2.7
		c-0.4-0.6-0.9-1.2-1.5-1.6c-0.6-0.4-1.4-0.6-2.1-0.7c-0.7-0.1-1.6-0.2-2.5-0.2h-5.8V60.4z"/>
                <path className="PlaceBtnSt1" d="M94.4,44.4h6.8v34.2h-6.8V44.4z"/>
                <path className="PlaceBtnSt1" d="M106.5,61.4c0.1-1.6,0.5-3,1.2-4c0.7-1.1,1.6-1.9,2.7-2.6c1.1-0.6,2.3-1.1,3.7-1.4c1.4-0.2,2.7-0.4,4.1-0.4
		c1.2,0,2.5,0.1,3.8,0.2c1.2,0.1,2.5,0.5,3.5,1c1,0.5,1.9,1.2,2.6,2.1c0.7,0.9,1,2.1,1,3.6v12.8c0,1.1,0.1,2.2,0.2,3.2
		c0.1,1,0.4,1.9,0.6,2.3H123c-0.1-0.4-0.2-0.7-0.2-1.2c-0.1-0.4-0.1-0.9-0.1-1.2c-1.1,1.1-2.3,1.9-3.8,2.3c-1.5,0.5-3,0.7-4.6,0.7
		c-1.2,0-2.2-0.1-3.3-0.5c-1-0.2-2-0.7-2.7-1.4c-0.7-0.6-1.4-1.4-1.9-2.3c-0.4-1-0.6-2-0.6-3.3c0-1.4,0.2-2.6,0.7-3.5
		c0.5-0.9,1.1-1.6,2-2.2c0.9-0.6,1.7-1,2.7-1.2c1-0.2,2-0.5,3.1-0.6c1.1-0.1,2.1-0.2,3.1-0.4c1-0.1,1.9-0.2,2.6-0.4
		c0.7-0.2,1.4-0.5,1.9-0.9c0.5-0.4,0.7-0.9,0.7-1.6s-0.1-1.4-0.4-1.7c-0.2-0.4-0.6-0.7-1-1s-0.9-0.4-1.4-0.5
		c-0.5-0.1-1.1-0.1-1.7-0.1c-1.4,0-2.3,0.2-3.1,0.9s-1.2,1.5-1.4,2.8h-7.2V61.4z M122.3,66.5c-0.2,0.2-0.6,0.5-1.1,0.6
		c-0.5,0.1-0.9,0.2-1.4,0.4c-0.5,0.1-1,0.1-1.6,0.2c-0.6,0.1-1.1,0.1-1.6,0.2c-0.5,0.1-1.1,0.2-1.5,0.4c-0.4,0.1-0.9,0.4-1.2,0.6
		c-0.4,0.2-0.6,0.6-0.9,1c-0.2,0.4-0.4,1-0.4,1.6c0,0.6,0.1,1.1,0.4,1.6c0.2,0.4,0.5,0.7,0.9,1c0.4,0.2,0.9,0.4,1.4,0.5
		c0.5,0.1,1,0.1,1.6,0.1c1.4,0,2.3-0.2,3.1-0.6c0.7-0.5,1.2-1,1.6-1.6c0.4-0.6,0.6-1.2,0.6-1.9s0.1-1.1,0.1-1.6V66.5z"/>
                <path className="PlaceBtnSt1" d="M151.3,62.5c-0.5-2.8-2.1-4.2-5.1-4.2c-1.1,0-2,0.2-2.7,0.7c-0.7,0.5-1.4,1.1-1.9,1.9s-0.7,1.6-1,2.6
		c-0.2,1-0.2,1.9-0.2,2.8c0,0.9,0.1,1.9,0.2,2.7c0.1,1,0.5,1.7,1,2.5s1,1.4,1.7,1.9c0.7,0.5,1.6,0.7,2.7,0.7c1.6,0,2.8-0.5,3.7-1.4
		c0.9-0.9,1.5-2.1,1.7-3.7h6.5c-0.5,3.3-1.7,5.8-3.8,7.5c-2.1,1.7-4.8,2.6-8.2,2.6c-1.9,0-3.6-0.4-5.2-1c-1.5-0.6-2.8-1.5-4-2.6
		c-1.1-1.1-2-2.5-2.6-4.1s-0.9-3.2-0.9-5.1c0-2,0.2-3.7,0.9-5.3c0.6-1.6,1.4-3.1,2.5-4.3c1.1-1.2,2.5-2.1,4-2.8c1.6-0.6,3.3-1,5.4-1
		c1.5,0,2.8,0.2,4.2,0.6s2.6,1,3.6,1.7c1.1,0.7,2,1.7,2.6,3c0.6,1.2,1.1,2.6,1.1,4.1h-6.4V62.5z"/>
                <path className="PlaceBtnSt1" d="M167.6,67.8c0.1,2.2,0.6,3.8,1.6,4.8c1,1,2.5,1.5,4.4,1.5c1.4,0,2.6-0.4,3.6-1c1-0.7,1.6-1.5,1.9-2.2h5.9
		c-1,3-2.3,5.1-4.4,6.4c-2.1,1.4-4.3,2-7.2,2c-2,0-3.7-0.4-5.3-1s-2.8-1.5-4-2.7c-1.1-1.1-2-2.6-2.5-4.2c-0.6-1.6-0.9-3.3-0.9-5.3
		c0-1.9,0.2-3.6,0.9-5.2c0.6-1.6,1.5-3,2.6-4.2c1.1-1.1,2.5-2.1,4-2.7c1.6-0.6,3.3-1,5.2-1c2.1,0,4,0.4,5.6,1.2
		c1.6,0.9,2.8,1.9,3.8,3.3c1,1.4,1.7,3,2.2,4.7c0.5,1.7,0.6,3.6,0.5,5.6L167.6,67.8L167.6,67.8z M178.6,63.5c-0.4-1.7-0.9-3.1-1.7-4
		c-0.9-0.9-2.1-1.4-3.7-1.4c-1.1,0-2,0.1-2.7,0.5c-0.7,0.4-1.4,0.9-1.7,1.4c-0.5,0.5-0.7,1.1-0.9,1.7c-0.1,0.6-0.2,1.1-0.2,1.6h11
		V63.5z"/>
                <path className="PlaceBtnSt0" d="M67.2,5.2h171.9c3.3,0,6.2,2.7,6.2,6.2v97.2c0,3.3-2.7,6.2-6.2,6.2H10.9c-3.3,0-6.2-2.7-6.2-6.2V43.5"/>
                <line className="PlaceBtnSt1" x1="50.5" y1="5.2" x2="53" y2="5.2"/>
                </g>
                </svg>

                )
            }
        </>

    );
}

export default PlaceBtn;