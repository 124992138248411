import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses, createFilterOptions} from '@mui/material/Autocomplete';
import {useHistory} from "react-router-dom";
import {useCallback, useContext, useEffect, useState} from "react";
import axios from "axios";
import {Theme} from "../ThemeContext";
import {makeStyles} from "@material-ui/core/styles";
import ElevatorButton from "../IconsClasses/ElevatorButton";
import JsxParser from "react-jsx-parser";


const MarketSearchSec = (props) => {


    const useStyles = makeStyles(() => ({
        root: {
            position: "relative",
            "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": {
                // Default transform is "translate(14px, 20px) scale(1)""
                // This lines up the label with the initial cursor position in the input
                // after changing its padding-left.
                transform: "translate(34px, 20px) scale(1);"
            },
            "& .css-11nizae-MuiInputBase-root-MuiInput-root:before": {
                borderBottom: "none"
            },
            "& .css-11nizae-MuiInputBase-root-MuiInput-root:focus": {
                borderBottom: "solid 2px"
            },
            "& .css-17vbkzs-MuiFormControl-root-MuiTextField-root": {
                marginTop: 0,

            },
            "& .css-b7k0tb-MuiAutocomplete-listbox": {
                padding: 0,

            },

        },

        listbox: {
            padding: 0,
            '&::-webkit-scrollbar': {
                width: '0.4em'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: theme.theme == 'dark' ? "rgb(38,45,70)" : "rgb(159,162,162)",
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.theme == 'dark' ? "#1b2032" : "#F4F9F9",
                outline: '1px solid slategrey',
                borderRadius: 8,
                border:'none'
            },
            '&.Mui-focused': {}

        },

        inputRoot: {
            color: theme.theme == 'dark' ? "#fff" : "#0a0a0a",
            border: "none",

            fontFamily: "HelveticaNeueCyrBold",
            // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
            '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
                // Default left padding is 6px
                paddingLeft: 26
            },
            "& .MuiOutlinedInput-notchedOutline": {
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
                borderBottom: "solid 1px"
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
                borderBottom: "solid 1px"
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
                borderBottom: "solid 1px"
            },
            "& .css-b7k0tb-MuiAutocomplete-listbox": {
                padding: 0,

            },
        }
    }));


    const theme = useContext(Theme);
    const history = useHistory();

    const [marketList, setMarketList] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [fullNameMarket, setFullNameMarket] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [isMarket, setIsMarket] = useState(false);
    const [error, setError] = useState(null);

    const fetchCoinHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {

            const listResponse = await axios.get('currencies/list');

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }


            const marketL = await (listResponse.data);


            const loadedCoinList = [];
            const loadedSecCoinList = [];
            const loadedNetwork = [];

            for (var keys in marketL) {
                if (marketL[keys].short_name != null) {
                    loadedCoinList.push(
                        {
                            name: marketL[keys].short_name,
                            logo: marketL[keys].logo,
                            marketListFullName: marketL[keys].full_name,

                        }
                    );
                    loadedNetwork.push({
                            id: keys,
                            name: marketL[keys].short_name,
                            networks: marketL[keys].networks,
                        }
                    );
                }
            }
            setMarketList(loadedCoinList);
            setNetworks(loadedNetwork);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const [val, setVal] = useState();
    const [imgLogo, setImgLogo] = useState();


    const styling = theme.theme == 'dark' ? {
        padding: 5,
        backgroundColor: "transparent",
        borderRadius: 4,
        textAlign: "left",
        direction: 'ltr'
    } : {borderRadius: 4, backgroundColor: "transparent", paddingTop: 5, textAlign: "left", direction: 'ltr'};

    const search = window.location.href;
    const coinName = search.split("=")[1];

    const car = {
        name: coinName,
        logo: "",
        marketListFullName: "",
    }

    const savage = (event, value) => {

        if (props.loc === "WDS") {
            window.history.pushState({}, null, `${props.componentLocName}?pair=${value.name}`);
        }

        if (props.onfetchGeneralList) {
            props.onfetchGeneralList();
        }

        setVal(value.name);
        setImgLogo(value.logo);
    }

    useEffect(() => {

        fetchCoinHandler();


        if (val != undefined && props.onGetMarketDetail) {
            props.onGetMarketDetail(val);
            props.setFirst(val);
            props.setFirstImage(imgLogo);
        }
        if (props.market == true && val != undefined) {
            setIsMarket(true);
            props.setSecond(val);
            props.setSecondImage(imgLogo);
        }
    }, [fetchCoinHandler, val]);


    const filterOptions = createFilterOptions({
        matchFrom: 'start',
    });

    const classes = useStyles();

    return (
        <Autocomplete
            id="highlights-demo"
            classes={classes}
            autoHighlight
            debug
            sx={{width: props.size, padding: 0}}
            forcePopupIcon={false}
            options={marketList}
            defaultValue={car}
            onChange={savage}
            filterOptions={filterOptions}
            getOptionLabel={(option) => (option.name != undefined ? option.name : "")}
            disableClearable={true}
            renderOption={(props, option) => {
                return (
                    <li style={{
                        backgroundColor: theme.theme == 'dark' ? "#1b2032" : "#F4F9F9",
                        color: theme.theme == 'dark' ? "#fff" : "#0a0a0a",
                        border: "2x solid",
                        fontWight: 500,
                    }}>
                                  <span  {...props} >
                                      <JsxParser className="marketListLogo" jsx={option.logo}/>
                                      {option.name}
                                      <span className="marketListFullName">
                                           {option.marketListFullName}
                                      </span>
                                    </span>
                    </li>
                );
            }}
            renderInput={(params) => (
                <>
                    <div className="ElevatorButton">
                        <ElevatorButton/>
                    </div>
                    <TextField autoFocus={true} {...params}
                               variant="outlined" style={styling} margin="normal">
                    </TextField>
                </>
            )}
        />
    );
};

export default MarketSearchSec;
