import React, {useEffect, useRef, useState, useContext, useCallback} from "react";
import {useHistory} from "react-router-dom";
import {ToastContainer, toast, Slide} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Footer from "./component/widgets/Footer";
import NavBar from "./component/widgets/NavBar";
import './component/css/homePage.css';
import './component/css/bootstrap.css';
import Marquee from "react-fast-marquee";
import {LoginContext} from "./Root";
import VerticalSlider from "./component/widgets/VerticalSlider";
import {CircularProgress} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import {Context} from "./translation/Wrapper";
import {Theme} from "./component/ThemeContext";
import JoinNowBtn from "./component/IconsClasses/JoinNowBtn";
import JsxParser from "react-jsx-parser";


export const redColor = "#ef5350";
export const greenColor = "#26a69a";

export const notifyCopied = () => toast.success("copied", {autoClose: 2000});
export const notifySuccessful = () =>
    toast.success("Successfully Done", {autoClose: 3500});
export const notifySuccessfulMessage = (message) =>
    toast.success(message, {autoClose: 3500});
export const notifyError = () =>
    toast.error("Network Problem, Please Try Again", {autoClose: 3500});
export const notifyErrorMessage = (message) =>
    toast.error(message, {autoClose: 3500});
export const notifyWarnMessage = (message) =>
    toast.warn(message, {autoClose: 4000});


const responsive = {
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 3,
        slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
        slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

function App() {
    const history = useHistory();
    const {isLoggedIn, setIsLoggedIn} = useContext(LoginContext);
    useEffect(() => {
        setInterval(() => {
            setWidth(window.innerWidth)
        }, 100)
    }, []);

    const [width, setWidth] = useState();

    const context = useContext(Context);

    //**************************************************************************************************************************************

    const [tickers, setTickers] = useState([
        {
            "id": 41,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 3,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 1,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 2,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 7,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 8,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 18,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 118,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 222,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 223,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        }
    ]);//topGainers
    const [topLosers, setTopLosers] = useState([
        {
            "id": 41,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 3,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 1,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 2,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        }
    ]);
    const [vLeaders, setVLeaders] = useState([
        {
            "id": 41,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 3,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 1,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 2,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 222,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        },
        {
            "id": 223,
            "name": "*******USDT",
            "lastPrice": "*******",
            "dayOpen": "*******",
            "volume": "*******",
            "value": "*******",
            "DayChange": "*******"
        }
    ]);

    const [topGainerTitle, setTopGainerTitle] = useState(<FormattedMessage id="Homepage.TopGainer"
                                                                           defaultMessage="TopGainer"/>);
    const [topLoserTitle, setTopLoserTitle] = useState(<FormattedMessage id="Homepage.TopLoser"
                                                                         defaultMessage="TopLoser"/>);
    const [vLeaderTitle, setVLeaderTitle] = useState(<FormattedMessage id="Homepage.ValueLeader"
                                                                       defaultMessage="ValueLeader"/>);

    const [isLoading, setIsLoading] = useState(false);
    const tableTGLoading = useRef("");
    const tableTLLoading = useRef("");
    const tableVLLoading = useRef("");
    const [tableLoadingData, setTableLoadingData] = useState(true);

    const [error, setError] = useState(null);
    const [tableSwitcher, setTableSwitcher] = useState(1);

    const fetchCoinHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {

            const listResponse = await axios.get('currencies/list');

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }


            const marketL = (listResponse.data);

            const loadedList = [];


            for (var keys in marketL) {
                loadedList.push({
                    name: marketL[keys].markets,
                    rank: marketL[keys].rank,
                    logo: marketL[keys].logo
                });
            }


            const response = await axios.get('core/marketTickerAll');

            if (response.status !== 200) {
                throw new Error('Something went wrong!');
            }

            const data = await (response.data.data.ticker);

            const loadedData = [];
            const loadedTL = [];
            const loadedVL = [];

            for (var i = 0; i <= loadedList.length; i++) {
                for (var key in data) {
                    if (loadedList[i] != undefined && loadedList[i].name != undefined && key == loadedList[i].name[0]) {
                        loadedData.push({
                            id: loadedList[i].rank,
                            logo: loadedList[i].logo,
                            name: key,
                            lastPrice: data[key].last,
                            dayOpen: data[key].open,
                            volume: parseFloat(data[key].vol).toFixed(2),
                            value: (data[key].vol * data[key].last).toFixed(2),
                            DayChange: (((data[key].last - data[key].open) / data[key].open) * 100).toFixed(2)
                        });
                        loadedTL.push({
                            id: loadedList[i].rank,
                            name: key,
                            logo: loadedList[i].logo,
                            lastPrice: data[key].last,
                            dayOpen: data[key].open,
                            volume: parseFloat(data[key].vol).toFixed(2),
                            value: (data[key].vol * data[key].last).toFixed(2),
                            DayChange: (((data[key].last - data[key].open) / data[key].open) * 100).toFixed(2)
                        });
                        loadedVL.push({
                            id: loadedList[i].rank,
                            name: key,
                            logo: loadedList[i].logo,
                            lastPrice: data[key].last,
                            dayOpen: data[key].open,
                            volume: parseFloat(data[key].vol).toFixed(2),
                            value: (data[key].vol * data[key].last).toFixed(2),
                            DayChange: (((data[key].last - data[key].open) / data[key].open) * 100).toFixed(2)
                        });
                    }
                }
            }

            loadedData.sort(function (a, b) {
                return b.DayChange - a.DayChange
            });
            setTickers(loadedData);

            loadedTL.sort(function (a, b) {
                return a.DayChange - b.DayChange
            });
            setTopLosers(loadedTL);
            loadedVL.sort(function (a, b) {
                return b.value - a.value
            });
            setVLeaders(loadedVL);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCoinHandler();
    }, [fetchCoinHandler]);

    let content;
    let ERR;

    if (tickers.length === 0) {
        ERR = <p>Found no Coin.</p>;
    }


    if (isLoading) {
        content = <p><CircularProgress/></p>;
    }

    const numberFormatter = (num) => {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        }
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 1000) {
            return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
        return num;
    }


    const ArrayFormatter = (arrayNum) => {
        setIsLoading(true);

        if (arrayNum == 2) {
            tableTGLoading.current.style.display = 'none';
            tableTLLoading.current.style.display = 'none';
            setTableLoadingData(false);
            setTimeout(() => {
                var tempTitle = topGainerTitle;
                setTopGainerTitle(topLoserTitle);
                setTopLoserTitle(tempTitle);

                var temp = tickers;
                setTickers(topLosers);
                setTopLosers(temp);

                setTableLoadingData(true);
                tableTLLoading.current.style.display = 'block';
                tableTGLoading.current.style.display = 'block';
            }, 1)
        }
        if (arrayNum == 3) {
            tableTGLoading.current.style.display = 'none';
            tableVLLoading.current.style.display = 'none';
            setTableLoadingData(false);
            setTimeout(() => {
                var tempTitle = topGainerTitle;
                setTopGainerTitle(vLeaderTitle);
                setVLeaderTitle(tempTitle);

                var temp = tickers;
                setTickers(vLeaders);
                setVLeaders(temp);

                setTableLoadingData(true);
                tableVLLoading.current.style.display = 'block';
                tableTGLoading.current.style.display = 'block';
            }, 1)
        }

        setIsLoading(false);
    }

    console.log(tableLoadingData);

    const theme = useContext(Theme).theme;

    return (

        <div className={`App ${theme == 'dark' ? 'BGDark' : 'BGLight'}`}>

            <NavBar/>


            <div
                className={` header ${theme == 'light' ? 'lightMode' : 'darkMode'} col-xs-12 col-md-12 col-sm-12  ${context.locale == "fa" && "font-face-farsi"}`}
                id="header">
                <div className={`container-fluid ${isLoggedIn && 'withJoin'}`}>
                    <div className="header-title ">
                        <h2 className={` ${context.locale == "fa" && 'BNN'} ${context.locale == "fa" && "font-face-farsi"}`}>
                            <FormattedMessage
                                id="Homepage.welcome"
                                defaultMessage="welcome"
                            />
                        </h2>
                        <h2 className={` ${context.locale == "fa" && 'BNN'} ${context.locale == "fa" && "font-face-farsi"}`}>
                            <FormattedMessage
                                id="Homepage.welcomeSec"
                                defaultMessage="welcome"
                            />
                        </h2>
                        <JoinNowBtn/>
                    </div>
                </div>
                <VerticalSlider/>
            </div>

            <div
                className={`container-fluid col-xs-12 col-md-12 col-sm-12 ${context.locale == "fa" && "font-face-farsi"}`}>

                {width < 800 && <Marquee className={`marqueeD fixed-bottom ${width < 800 ? 'd-block' : 'd-none'}`}
                                         style={{position: "fixed"}} id="marquee"
                                         gradientColor={'transparent'} pauseOnHover={true} loop={0} speed={60}>
                    {tickers.slice(0, 4).map((item) => (
                        <div className="d-flex price-change ms-2 me-2">
                            <JsxParser className="MarqueeLogo" jsx={item.logo}/>
                            <span>{item.name} {item.lastPrice}$</span>
                            <span className="d-inline"><span
                                className={`${item.DayChange > 0 ? 'inGrow' : 'inLose'}`}>{item.DayChange > 0 && '+'}{item.DayChange}%</span></span>
                        </div>
                    ))}

                </Marquee>}


                <div className={`col-12 crypto-all ${isLoading && 'blurForLoading'}`} id="FCAT">

                    <div className="card border-0" id="top-gainer-content">
                        <div className=" border-0 ">
                            <div className="HPMainTableContainer">

                                <div className={`tileRap`}>

                                    <div
                                        className="tab-pane fade show  crypto-table-homepage  tableShadow "
                                        id="first-table"
                                        role="tabpanel"
                                        aria-labelledby="pills-home-tab"
                                    >
                                        {/*<h4 className={`taleTitle  ${context.locale == "fa" ? "text-end" : "text-start"}`}*/}
                                        {/*    style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}*/}
                                        {/*>*/}
                                        {/*    {topGainerTitle}*/}
                                        {/*</h4>*/}
                                        <table className="table text-center " id="tileRapTable">
                                            <thead dir={context.locale == "fa" && "rtl"}>
                                            <tr className={`homepage-crypto-property ${context.locale == "fa" && "font-face-farsi"} `}
                                                ref={tableTGLoading}>
                                                <th scope="col"></th>
                                                <th className="NameTitle" scope="col ">
                                                    <FormattedMessage
                                                        id="Homepage.Table.Name"
                                                        defaultMessage="Name"
                                                    />
                                                </th>
                                                <th className="LastPriceTitle" scope="col ">
                                                    <FormattedMessage
                                                        id="Homepage.Table.LastPrice"
                                                        defaultMessage="LastPrice"
                                                    />
                                                </th>
                                                <th className="ChangeTitle" scope="col ">
                                                    <FormattedMessage
                                                        id="Homepage.Table.24hChange"
                                                        defaultMessage="24hChange"
                                                    />
                                                </th>
                                                <th className="VolumeTitle" scope="col ">
                                                    <FormattedMessage
                                                        id="Homepage.Table.24hVolume"
                                                        defaultMessage="24hVolume"
                                                    />
                                                </th>
                                                <th className="ValueTitle" scope="col ">
                                                    <FormattedMessage
                                                        id="Homepage.Table.24hValue"
                                                        defaultMessage="24hValue"
                                                    /> <span className="crypto-property-postFix">(USDT)</span>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {tickers.slice(0, 10).map((ticker) => (
                                                <tr className={`HPTableData ${tableLoadingData ? "textAnimation" : ""}`}>
                                                    <td style={{display: "flex", justifyContent: "center"}}>
                                                        {/*<img*/}
                                                        {/*    src="img/A640879E7F4AEA61E6777EA053450AF7.png"*/}
                                                        {/*    className="crypto-ico"*/}
                                                        {/*    alt="..."*/}
                                                        {/*/>*/}
                                                        <JsxParser className="HPTableLogo" jsx={ticker.logo}/>
                                                    </td>
                                                    <td className="Name">
                                                        {ticker.name}
                                                    </td>
                                                    <td className="LastPrice">{(ticker.lastPrice)}</td>
                                                    <td className={`${ticker.DayChange > 0 ? 'grow' : 'Lose'} fw-bold Change`}>{ticker.DayChange > 0 && '+'}{ticker.DayChange}%</td>
                                                    <td className="Volume">{numberFormatter(ticker.volume)}</td>
                                                    <td className="Value">{numberFormatter(ticker.value)}</td>
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="LiMain-container">

                                        <div className="list-group  border-hover crypto-list2 shadow">
                                            <h4
                                                className={`taleTitle   `}
                                                style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                            >
                                                <span
                                                    className={`taleTitleTextContainer ${!isLoading ? "textAnimation" : ""}`}
                                                    ref={tableTGLoading}>{topGainerTitle}</span>
                                            </h4>
                                        </div>

                                        <div className="list-group  border-hover crypto-list3 shadow"
                                             onClick={() => {
                                                 ArrayFormatter(2);
                                             }} role={"button"}>
                                            <h4
                                                className={`taleTitle`}
                                                style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                            >
                                                <span
                                                    className={`taleTitleTextContainer ${!isLoading ? "textAnimation" : ""}`}
                                                    ref={tableTLLoading}> {topLoserTitle} </span>
                                            </h4>
                                        </div>

                                        <div className="list-group  border-hover crypto-list3 shadow"
                                             onClick={() => {
                                                 ArrayFormatter(3);
                                             }} role={"button"}>
                                            <h4 className={`taleTitle `}
                                                style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                            >
                                                <span
                                                    className={`taleTitleTextContainer ${!isLoading ? "textAnimation" : ""}`}
                                                    ref={tableVLLoading}> {vLeaderTitle} </span>
                                            </h4>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className={`col-12 crypto-all ${isLoading && 'blurForLoading'}`} id="SCAT">
                    <div className="card border-0">
                        <div className="card-body border-0">
                            <div className="row justify-content-center">
                                <div
                                    className="tab-pane fade show  crypto-table-homepage tableShadow"
                                    id="first-table"
                                    role="tabpanel"
                                    aria-labelledby="pills-home-tab"
                                >
                                    <table className="table  text-center ">
                                        <thead dir={context.locale == "fa" && "rtl"}>
                                        <tr className={`homepage-crypto-property ${context.locale == "fa" && "font-face-farsi"} `}>
                                            <th scope="col"></th>
                                            <th scope="col">
                                                <FormattedMessage
                                                    id="Homepage.Table.Name"
                                                    defaultMessage="Name"
                                                />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage
                                                    id="Homepage.Table.LastPrice"
                                                    defaultMessage="LastPrice"
                                                />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage
                                                    id="Homepage.Table.24hChange"
                                                    defaultMessage="24hChange"
                                                />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage
                                                    id="Homepage.Table.24hVolume"
                                                    defaultMessage="24hVolume"
                                                />
                                            </th>
                                            <th scope="col">
                                                <FormattedMessage
                                                    id="Homepage.Table.24hValue"
                                                    defaultMessage="24hValue"
                                                /> <span className="crypto-property-postFix">(USDT)</span>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {(tableSwitcher === 1 ? tickers : tableSwitcher === 2 ? topLosers : vLeaders).slice(0, 5).map((ticker) => (
                                            <tr className={`first-row  ${tableLoadingData ? "textAnimation" : ""}`}
                                                id="secondTable-content">
                                                <td>
                                                    <JsxParser className="HPTableLogo" jsx={ticker.logo}/>
                                                </td>
                                                <td>
                                                    {ticker.name}
                                                </td>
                                                <td className={`${ticker.DayChange > 0 ? 'grow' : 'Lose'} fw-bold`}>{ticker.lastPrice}</td>
                                                <td className={`${ticker.DayChange > 0 ? 'grow' : 'Lose'} fw-bold`}>{ticker.DayChange > 0 && '+'}{ticker.DayChange}%</td>
                                                <td>{numberFormatter(ticker.volume)}</td>
                                                <td>{numberFormatter(ticker.value)}</td>
                                            </tr>
                                        ))}

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bannerCards">
                    <div className=" sigInfoCards ">
                        <div className={`card-body curvy shadow ${theme == 'dark' ? 'bgIDark' : 'bgILight'}`}>
                            <p>
                                <FormattedMessage
                                    id="Homepage.cardsInfo.Speed"
                                    defaultMessage="cardsInfo"
                                />
                            </p>
                        </div>
                        <div className={`card-body curvy shadow ${theme == 'dark' ? 'bgIDark' : 'bgILight'}`}>
                            <p>
                                <FormattedMessage
                                    id="Homepage.cardsInfo.Auth"
                                    defaultMessage="cardsInfo"
                                />
                            </p>
                        </div>
                        <div className={`card-body curvy shadow ${theme == 'dark' ? 'bgIDark' : 'bgILight'}`}>
                            <p>
                                <FormattedMessage
                                    id="Homepage.cardsInfo.CryptoVariety"
                                    defaultMessage="cardsInfo"
                                />
                            </p>
                        </div>
                    </div>
                </div>

                <div className="bannerCards">
                    <div className=" sigAuthInfo ">
                        <div className={`card-body curvy shadow ${theme == 'dark' ? 'bgIDark' : 'bgILight'}`} dir={context.locale == "fa" && "rtl"}>
                            <div className="textContainer">
                                <h3 >
                                    <FormattedMessage
                                        id="Homepage.cardsInfo.AuthInfo"
                                        defaultMessage="Homepage.cardsInfo.AuthInfo"
                                    />
                                </h3>
                                <br/>
                                <p >
                                    <span className="first" style={context.locale == "fa" ? {right:"-50px"} : {left:"-50px"}}></span>
                                    <FormattedMessage
                                        id="Homepage.cardsInfo.AuthInfo.textFirst"
                                        defaultMessage="Homepage.cardsInfo.AuthInfo.text"
                                    />
                                </p>
                                <p >
                                    <span className="second" style={context.locale == "fa" ? {right:"-50px"} : {left:"-50px"}}></span>
                                    <FormattedMessage
                                        id="Homepage.cardsInfo.AuthInfo.textSecond"
                                        defaultMessage="Homepage.cardsInfo.AuthInfo.text"
                                    />
                                </p>
                                <p >
                                    <span className="third" style={context.locale == "fa" ? {right:"-50px"} : {left:"-50px"}}></span>
                                    <FormattedMessage
                                        id="Homepage.cardsInfo.AuthInfo.textThird"
                                        defaultMessage="Homepage.cardsInfo.AuthInfo.text"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="bannerCards">*/}
                {/*    <div className=" sigBlogInfo ">*/}
                {/*        <div className={`card-body curvy shadow `}>*/}
                {/*            <h5 dir={context.locale == "fa" && "rtl"}>بلاگ سیگلوی</h5>*/}
                {/*            <p></p>*/}
                {/*        </div>*/}
                {/*        <div className={`card-body curvy shadow `}>*/}
                {/*            <h5 dir={context.locale == "fa" && "rtl"}>بلاگ سیگلوی</h5>*/}
                {/*            <p></p>*/}
                {/*        </div>*/}
                {/*        <div className={`card-body curvy shadow `}>*/}
                {/*            <h5 dir={context.locale == "fa" && "rtl"}>بلاگ سیگلوی</h5>*/}
                {/*            <p></p>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {/*<div className="card HPSlider">*/}
                {/*    <Carousel/>*/}
                {/*</div>*/}

            </div>

            <Footer/>

        </div>
    );
}

export default App;
