import React, { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import MaskedInput from "react-text-mask";
import PropTypes from "prop-types";
import { isIOS, isAndroid, isSafari } from "react-device-detect";
import moment from "moment";

export const nowDate = new Date();
export function parseDate(dateToParse) {
  var date = "";
  try {
    if (dateToParse == null) {
      date = "unknown time";
    } else {
      var date1 = new Date(dateToParse);

      const nowDate = new Date();

      var Difference_In_Time = nowDate.getTime() - date1.getTime();

      var Difference_In_Days = (
        Difference_In_Time /
        (1000 * 3600 * 24)
      ).toFixed(0);

      if (Difference_In_Days < 1) {
        const insec = (Difference_In_Time / 1000).toFixed(0);
        if (insec < 60) {
          if (insec == 0) {
            date = "Now";
          } else {
            const d = (Difference_In_Time / 1000).toFixed(0);
            date = d + (d > 1 ? " seconds ago" : " second ago");
          }
        } else if (Difference_In_Time / (1000 * 3600) < 1) {
          const d = (Difference_In_Time / (1000 * 60)).toFixed(0);
          date = d + (d > 1 ? " minutes ago" : " minute ago");
        } else {
          const d = (Difference_In_Time / (1000 * 3600)).toFixed(0);
          date = d + (d > 1 ? " hours ago" : " hour ago");
        }
      } else if (Difference_In_Days > 30) {
        //if (Difference_In_Days < 15)
        const d = (Difference_In_Days / 30).toFixed(0);
        date = d + (d > 1 ? " months ago" : " month ago");
      } else if (Difference_In_Days > 365) {
        const d = (Difference_In_Days / 365).toFixed(0);
        date = d + (d > 1 ? " years ago" : " year ago");
      } else {
        date =
          Difference_In_Days +
          (Difference_In_Days > 1 ? " days ago" : " day ago");
      }
      // else {
      //     date = 'خیلی روز پیش'
      // }
    }
    return date;
  } catch (e) {
    return "زمان نامشخص";
  }
}

export function parseFulDate(dateToParse) {
  var date = "";
  try {
    if (dateToParse == null) {
      date = "Unknown Date";
    } else {
      if (dateToParse > 0) {
        date = moment.unix(dateToParse).format("YYYY-MM-DD HH:mm:ss");
      } else {
        date = moment(dateToParse, "YYYY-MM-DDThh:mm:ss.Z").format(
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    }
    return date;
  } catch (e) {
    return "Unknown Date";
  }
}

export function parseDistance(distance) {
  var dis = "";
  if (distance == -1) {
    dis = "فاصله نامشخص";
  } else if (distance <= 1000) {
    dis = "در محدوده";
  } else {
    dis = (distance / 1000).toFixed(2) + " کیلومتر";
  }
  return dis;
}

export function parsePrice(p) {
  const price =
    p instanceof Number
      ? p.toFixedSpecial(p.toString().length)
      : Number(p).toFixedSpecial(p.toString().length);
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ت";
}

export function groupBy(array, key) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function parseUSDPrice(p, fixed, is_transfer) {
  if (p == null && is_transfer) {
    return "Transfer";
  } else if (p == null) {
    return "No Price";
  }
  var decimalsCount = 0;
  const str = (p ?? "0").toString();
  if (str.includes(".")) {
    const spl = str.split(".");
    decimalsCount = spl.length > 0 ? spl[1].length : 0;
  }
  if (p == 0) {
    return "";
  } else {
    const price = Math.trunc(
      p instanceof Number
        ? p.toFixedSpecial(decimalsCount)
        : Number(p).toFixedSpecial(decimalsCount)
    );

    if (p > 1000000000) {
      return (
        "B＄" +
        (price / 1000000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    } else if (p > 1000000) {
      return (
        "M＄" +
        (price / 1000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    } else if (p > 1000) {
      return (
        "K＄" + (price / 1000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      );
    } else {
      return "＄" + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
}

export function startTimer(duration, display) {
  var timer = duration,
    minutes,
    seconds;
  setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);

    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    display.innerHTML = minutes + ":" + seconds;

    if (--timer < 0) {
      timer = duration;
    }
  }, 1000);
}

Number.prototype.parseNumberLength = function () {
  const s = this.toString();
  if (s.includes("e-")) {
    return Number(s.split("e-")[1]);
  } else if (s.includes("e+")) {
    return Number(s.split("e+")[1]);
  } else {
    return s.length;
  }
};

Number.prototype.toFixedSpecial = function (n, fixed) {
  var str = this.toFixed(n);
  if (str.indexOf("e+") === -1 && str.indexOf("e-") === -1) return str;

  // if number is in scientific notation, pick (b)ase and (p)ower
  var spl = "";
  if (str.includes("e+")) {
    spl = "e+";
  } else if (str.includes("e-")) {
    spl = "e-";
  }
  str = str
    .replace(".", "")
    .split(spl)
    .reduce(function (p, b) {
      return p + Array(b - p.length + 2).join(0);
    });

  if (n > 0) str += "." + Array(n + 1).join(0);

  return str;
};

export function parseNumber(num) {
  if (num < 1) {
    return num;
  }
  return (num ?? "0").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function parseLikeOrDislikes(number) {
  var final = "";
  if (number < 1000) {
    final = number;
  } else {
    final = number / 1000 + "K";
  }
  return final;
}

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

export const usePosition = () => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };
  useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      setError("Geolocation is not supported");
      return;
    }
    const watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};

const persianNumbers = [
  /۰/g,
  /۱/g,
  /۲/g,
  /۳/g,
  /۴/g,
  /۵/g,
  /۶/g,
  /۷/g,
  /۸/g,
  /۹/g,
];
const arabicNumbers = [
  /٠/g,
  /١/g,
  /٢/g,
  /٣/g,
  /٤/g,
  /٥/g,
  /٦/g,
  /٧/g,
  /٨/g,
  /٩/g,
];
export const fixNumbers = (str) => {
  if (typeof str === "string") {
    for (var i = 0; i < 10; i++) {
      str = str.replace(persianNumbers[i], i).replace(arabicNumbers[i], i);
    }
  }
  return str;
};

export function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export function PriceFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: () => fixNumbers(values.value),
          },
        });
      }}
      thousandSeparator
      suffix=" تومان"
    />
  );
}

PriceFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

// export function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props

//     return (
//         <NumberFormat
//             {...other}
//             getInputRef={inputRef}
//             onValueChange={(values) => {
//                 onChange({
//                     target: {
//                         name: props.name,
//                         value: values.value,
//                     },
//                 })
//             }}
//             thousandSeparator
//             isNumericString
//         />
//     )
// }

export function DollarFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="＄"
    />
  );
}

export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

DollarFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export function getColsNumberByWidth(width) {
  return width > 1600
    ? 6
    : width > 1200
    ? 5
    : width > 900
    ? 4
    : width > 700
    ? 3
    : 2;
}

export function useWindowDimensions() {
  const hasWindow = typeof window !== "undefined";
  function getWindowDimensions() {
    const width = hasWindow ? window.innerWidth : null;
    const height = hasWindow ? window.innerHeight : null;
    return {
      width,
      height,
    };
  }
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    if (!isIOS && !isAndroid && !isSafari && hasWindow) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [hasWindow]);
  return windowDimensions;
}
