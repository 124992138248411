import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import Root from "./Root";

import * as serviceWorker from './serviceWorker';
import Wrapper from "./translation/Wrapper";
import './component/css/IranNastaliq.ttf';
import ThemeContext from "./component/ThemeContext";
import {ToastProvider} from "react-toast-notifications";
import "typeface-cormorant";


ReactDOM.render(
<ThemeContext>
    <Wrapper >
    <ToastProvider autoDismissTimeout={10000} placement={"bottom-right"} >
    <BrowserRouter>
      <Root />
    </BrowserRouter>
    </ToastProvider>
    </Wrapper>
</ThemeContext>
        ,
  document.getElementById("root")
);


serviceWorker.unregister();


