import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import English from "./en.json";
import farsi from "./fa.json";


export const Context = React.createContext();


 // const local = localStorage.getItem("language") || navigator.language.split("-")[0];
 const local = localStorage.getItem("language") || "fa";


let lang;
if (local === 'en') {
    lang = English;

}
if (local === 'fa') {
    lang = farsi;

}


const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);


    function selectLanguage(e) {
        var newLocale;

        if (e.target){
             newLocale = e.target.value;
        }
       else {
             newLocale = e;
        }
        setLocale(newLocale);
        if (newLocale === 'en') {
            setMessages(English);
            localStorage.setItem("language", "en");
        }

        else if (newLocale === 'fa') {
                setMessages(farsi);
                localStorage.setItem("language", "fa");
            }
    }


    return (
        <Context.Provider value={{locale, selectLanguage}}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
}

export default Wrapper;
