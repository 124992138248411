import React from 'react';

const ElevatorButton = () => {
    return (

            <svg
                version="1.1"
                id="Layer_1"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.ElevatorButton0{fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <path
                    className="ElevatorButton0"
                    d="M42.2,25.7h44.7c7.4,0,11.4,8.8,6.4,14.3c-0.7,0.8-1.3,1.4-1.8,1.8L62.3,69.5c-6.8,6.4-17.4,6.5-24.2,0
	C25.7,57.7,14.9,46.9,9,41.8c-0.7-0.6-1.6-1.5-2.6-2.6C1.9,33.9,6,25.7,13,25.7h0"
                />
            </svg>

    );
};

export default ElevatorButton;
