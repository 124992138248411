import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Line} from "react-chartjs-2";
import {Theme} from "../ThemeContext";
import axios from "axios";

function LineGraph(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const theme = useContext(Theme).theme;

    const gridColor = theme == 'dark' ? 'rgba(147,148,149,0.6)' : 'rgba(18,20,27,0.6)'

    const loadedList = [];

    for (var i in props.chartData) {
        loadedList.push(parseFloat(props.chartData[i]));
    }

    const color = props.percent < 0  ? "#f20519" : "#09aa6c";

    return (

        <Line
            height={50}
            width={150}
            border={'none'}
            data={{
                // x-axis label values
                labels: props.chartData,
                datasets: [
                    {
                        lineTension: 0.1,
                        // y-axis data plotting values
                        data: loadedList,
                        fill: false,
                        borderWidth: 2,
                        backgroundColor: color,
                        borderColor: color,
                        borderCapStyle: "butt",
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: "miter",
                        pointBorderColor: "transparent",
                        pointBackgroundColor: "transparent",

                        responsive: true
                    },
                ],
            }}


            options={
                {

                    scales: {
                        x: {
                            ticks: {
                                display: false //this will remove all the x-axis grid lines
                            },
                            grid: {
                                color: gridColor,
                                display: false
                            }
                        },
                        y: {
                            ticks: {
                                display: false //this will remove all the x-axis grid lines
                            },
                            grid: {
                                color: gridColor,
                                display: false
                            }

                        }
                    },
                    legend: {
                        display: false,
                    },
                    responsive: true,
                    plugins: {
                        tooltip: {
                            enabled: false
                        },
                        legend: {
                            display: false,
                            position: 'bottom'
                        },
                        title: {
                            display: false,
                            position: 'bottom',
                            text: ["assets", '( USDT )']
                        },
                        animation: {
                            duration: 1
                        }
                    }
                }}
        />
    );
}

export default LineGraph;