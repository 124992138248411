import React from 'react';

function LightModIco(props) {
    return (
        <div className="LightModIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.LightModIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="LightModIcoSt0"
                        d="M30,32.1c5.1-4.9,12-7.9,19.6-7.9c15.6,0,28.2,12.6,28.2,28.2S65.1,80.6,49.5,80.6S21.3,68,21.3,52.4H6"
                    />
                    <line className="LightModIcoSt0" x1="49.5" y1="17.4" x2="49.5" y2="4.6" />
                    <line className="LightModIcoSt0" x1="49.5" y1="97.6" x2="49.5" y2="85.9" />
                    <line className="LightModIcoSt0" x1={96} y1="51.1" x2="84.3" y2="51.1" />
                    <line className="LightModIcoSt0" x1="25.7" y1="74.9" x2="16.6" y2={84} />
                    <line className="LightModIcoSt0" x1="82.4" y1="18.2" x2="74.1" y2="26.5" />
                    <line className="LightModIcoSt0" x1="25.7" y1="27.3" x2="16.6" y2="18.2" />
                    <line className="LightModIcoSt0" x1="82.4" y1={84} x2="74.1" y2="75.7" />
                </g>
            </svg>

        </div>
    );
}

export default LightModIco;