import React from 'react';

const CoinMarketCap = () => {
    return (
        <a href="https://coinmarketcap.com/" className="text-capitalize CoinMarketCap" target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 25.4 26.3"
                style={{ enableBackground: "new 0 0 25.4 26.3" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: "\n\t.CoinMarketCapSt0{fill-rule:evenodd;clip-rule:evenodd;}\n"
                    }}
                />
                <path
                    className="CoinMarketCapSt0"
                    d="M19.5,14.8c-0.3,0.2-0.7,0.2-1,0.1c-0.4-0.3-0.6-0.8-0.6-1.4v-2.1c0-1-0.4-1.7-1-1.9c-1.1-0.3-1.9,1-2.2,1.5
	l-1.9,3.2v-3.9c0-0.9-0.3-1.4-0.8-1.5S11,8.8,10.5,9.5l-4.3,7c-0.6-1.1-0.9-2.4-0.9-3.6c0-4.2,3.3-7.6,7.5-7.6
	c4.2,0.1,7.5,3.5,7.5,7.7l0,0C20.3,13.7,20,14.4,19.5,14.8L19.5,14.8z M21.9,13L21.9,13c0-5.1-4.1-9.2-9.2-9.3c0,0,0,0,0,0
	c-5.1,0.2-9.1,4.5-9,9.6c0.2,4.9,4.1,8.8,9,9c2.3,0,4.6-0.9,6.3-2.6c0.3-0.3,0.3-0.9,0-1.2c-0.3-0.3-0.8-0.4-1.2-0.1c0,0,0,0,0,0
	l0,0c-1.4,1.3-3.2,2-5.1,2.1c-2.1,0-4.2-0.9-5.6-2.5l3.9-6.3v2.9c0,1.4,0.5,1.9,1,2s1.2,0.1,1.9-1.2l2.1-3.5
	c0.1-0.1,0.1-0.2,0.2-0.3v1.7c-0.1,1.1,0.5,2.2,1.4,2.8c0.9,0.5,1.9,0.4,2.7-0.1C21.5,15.5,22.1,14.2,21.9,13z"
                />
            </svg>
        </a>
    );
};

export default CoinMarketCap;
