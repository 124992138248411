import React from 'react';

const TradingView = () => {
    return (

            <a href="https://www.tradingview.com/" className="TradingView" target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 25.4 26.3"
                style={{ enableBackground: "new 0 0 25.4 26.3" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.TradingViewSt1{}\n"
                    }}
                />
                <path className="TradingViewSt1" d="M10.7,18.9H6.8v-6H3V9h7.7L10.7,18.9z" />
                <path className="TradingViewSt1" d="M18.3,18.9H14L18,9h4.4L18.3,18.9z" />
                <circle className="TradingViewSt1" cx="13.9" cy="11.2" r="2.2" />
            </svg>
            </a>
    );
};

export default TradingView;
