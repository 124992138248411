import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import NavBar from "../widgets/NavBar";
import {useHistory} from "react-router-dom";
import {Context} from "../../translation/Wrapper";
import {Theme} from "../ThemeContext";
import Footer from "../widgets/Footer";
import "../css/Feedback.scss";
import SubmitBtn from "../IconsClasses/SubmitBtn";
import Scrollbars from "react-custom-scrollbars-2";
import axios from "axios";
import {useToasts} from "react-toast-notifications";
import {CircularProgress} from "@material-ui/core";
import {IoIosArrowBack} from "@react-icons/all-files/io/IoIosArrowBack";
import {IoIosArrowForward} from "@react-icons/all-files/io/IoIosArrowForward";
import {FormattedMessage} from "react-intl";

function Feedback(props) {

    const history = useHistory();
    const context = useContext(Context);
    const theme = useContext(Theme);
    const {addToast} = useToasts();

    const [loading, setLoading] = useState(false);

    const [feedBacks, setFeedBacks] = useState([]);
    const [counts, setCounts] = useState([]);
    const [pageNum, setPageNum] = useState(1);
    const [firstStep, setFirstStep] = useState(0);
    const [lastStep, setLastStep] = useState(0);
    const [stepCount, setStepCount] = useState();

    const getFeedback = useCallback(async (pageNum, LStep, FStep) => {
        setLoading(true)

        try {

            const count = await axios.get(`support/feedback/count`);

            const countArray = [];

            const iCount = count.data.data.page
            setStepCount(count.data.data.page);

            for (var i = 1; i <= iCount; i++) {
                countArray.push(i)
            }

            setCounts(countArray);


            const response = await axios.get(`support/feedback?page=${pageNum}`)

            if (response.status === 200) {
                setFeedBacks(response.data.data);
                if (pageNum % 5 === 1) {
                    setLastStep(pageNum);
                    setFirstStep(pageNum - 1);
                } else if (pageNum < LStep) {
                    setLastStep(pageNum - 4);
                    setFirstStep(pageNum - 5);
                }

            }

        } catch (error) {
            if ((error?.response?.status ?? 0) === 403) {
                addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }

        setLoading(false);
    }, []);


    const emailRef = useRef("");
    const subjectRef = useRef("");
    const nickNameRef = useRef("");
    const textRef = useRef("");

    const [feedbackEmail, setFeedbackEmail] = useState("");
    const [feedbackSubject, setFeedbackSubject] = useState("");
    const [feedbackNickname, setFeedbackNickname] = useState("");
    const [feedbackText, setFeedbackText] = useState("");

    const sendFeedback = useCallback(async () => {
        setLoading(true)

        const data =
            localStorage.getItem("token") != null ? {
                    "subject": subjectRef.current.value,
                    "nickname": nickNameRef.current.value,
                    "text": textRef.current.value
                }
                :
                {
                    "email": emailRef.current.value,
                    "subject": subjectRef.current.value,
                    "nickname": nickNameRef.current.value,
                    "text": textRef.current.value
                }


        try {

            const response = localStorage.getItem("token") != null ? await axios.post(`support/feedback`, data, {headers: {'Authorization': "Bearer " + localStorage.getItem("token")}}) : await axios.post(`support/feedback`, data);

            if (response.status == 200) {
                addToast("Your feedback was created successfully", {appearance: "success", autoDismiss: true});
                setFeedbackEmail("");
                setFeedbackSubject("");
                setFeedbackNickname("");
                setFeedbackText("");
            }

        } catch (error) {
            if ((error?.response?.status ?? 0) === 429) {
                addToast("Rate limit exceeded", {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 403) {
                addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }

        setLoading(false);
    }, []);

    let content;
    if (loading) {
        content = <p><CircularProgress/></p>;
    }

    useEffect(() => {
        getFeedback(pageNum);
    }, []);

    return (
        <div className={`${theme.theme == 'dark' ? 'BGDark' : 'BGLight'}`}>
            <NavBar coloring={false}/>
            <br/>
            <div className={` container-fluid ${context.locale == "fa" && "font-face-farsi FFont"}`}>
                <div className="row justify-content-center">

                    <div className="col-xs-4  col-md-4 col-sm-12 col-xs-12" style={{margin: "0 20px"}}>
                        <div className="card-body feedBackFormBody shadow curvy" dir={context.locale == "fa" && "rtl"}
                             style={{margin: "60px 10px"}}>
                            <div className="feedBackInputsContainer ">
                                <div className="form__labelFeedBack"
                                     style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                    <FormattedMessage id="feedback.feedBackFormBody.Name" defaultMessage="Name"/>
                                </div>
                                <div
                                    className={` ${context.locale == "fa" ? 'inputGPBNN' : 'inputGP'} form__group field`}>
                                    <input placeholder="Name" value={feedbackNickname}
                                           onChange={(e) => setFeedbackNickname(e.target.value)} ref={nickNameRef}
                                           className="form__field" type="text"/>
                                </div>
                            </div>
                            {localStorage.getItem("token") == null &&  ( <div className="feedBackInputsContainer ">
                                <div className={`form__labelFeedBack `}
                                     style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                    <FormattedMessage id="feedback.feedBackFormBody.Email" defaultMessage="Email"/>
                                </div>
                                <div
                                    className={`${context.locale == "fa" ? 'inputGPBNN' : 'inputGP'} form__group field ${localStorage.getItem("token") != null && 'disabledInput'}`}>
                                    <input className="form__field" value={feedbackEmail}
                                           onChange={(e) => setFeedbackEmail(e.target.value)} ref={emailRef}
                                           type="text"/>
                                </div>
                            </div>)}


                            <div className="feedBackInputsContainer">
                                <div className="form__labelFeedBack"
                                     style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                    <FormattedMessage id="feedback.feedBackFormBody.Subject" defaultMessage="Subject"/>
                                </div>
                                <div
                                    className={`${context.locale == "fa" ? 'inputGPBNN' : 'inputGP'} form__group field`}>
                                    <input className="form__field" value={feedbackSubject}
                                           onChange={(e) => setFeedbackSubject(e.target.value)} ref={subjectRef}
                                           type="text"/>
                                </div>
                            </div>
                            <div className="feedBackTextAreaContainer  field">
                                <div className="form__labelFeedBack"
                                     style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                    <FormattedMessage id="feedback.feedBackFormBody.Text" defaultMessage="Text"/>
                                </div>
                                <div className={`${context.locale == "fa" ? 'textareaGPBNN' : 'textareaGP'}`}>
                                    <textarea rows="4" value={feedbackText}
                                              onChange={(e) => setFeedbackText(e.target.value)} ref={textRef}/>
                                </div>
                            </div>
                            <div className="FeedBackBtnContainer">
                                <button
                                    className="FeedBackBtn "
                                    onClick={() => sendFeedback()}
                                >
                                    <SubmitBtn/>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className="col-xs-6  col-md-6 col-sm-12 col-xs-12" style={{margin: "0 20px"}}>

                        <div className={`feedBackContainer `} style={{margin: "30px 10px 0 10px "}}>
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                style={{minHeight: (700)}}
                            >
                                {feedBacks.map((feedBack) => (
                                    <div className="card-body feedBackSubjectBody shadow curvy"
                                         dir={context.locale == "fa" && "rtl"}>
                                        <div className="feedBackSubjectContainer">
                                            <div><h4><FormattedMessage id="feedback.feedBackSubject.Name"
                                                                       defaultMessage="Name"/></h4>
                                                <span>{feedBack.nickname}</span></div>
                                            <div><h4><FormattedMessage id="feedback.feedBackSubject.Subject"
                                                                       defaultMessage="Subject"/></h4>
                                                <span>{feedBack.subject}</span></div>
                                        </div>
                                        <div className="feedBackTextContainer">
                                            <div><h4><FormattedMessage id="feedback.feedBackSubject.Text"
                                                                       defaultMessage="Text"/></h4></div>
                                            <p className="feedBackSubjectText">{feedBack.text}</p>
                                        </div>
                                    </div>

                                ))}
                            </Scrollbars>

                            <div id="app" className={`pagesContainer ${stepCount < 2 ? 'd-none' : ''}`}>
                                <ul className={`page card-body ${loading ? 'disabledInput' : ''} `}>
                                    <li className="page__numbers" onClick={() => {
                                        if (pageNum > 1) {
                                            setPageNum(pageNum - 1);
                                            getFeedback(pageNum - 1, lastStep, firstStep);
                                        } else {
                                            setPageNum(stepCount);
                                            setFirstStep(stepCount - 1);
                                            setLastStep(stepCount);
                                            getFeedback(stepCount, lastStep, firstStep);
                                        }

                                    }}>
                                        <span className="material-icons"> <IoIosArrowBack/></span></li>
                                    {counts.slice((0 + firstStep), (5 + lastStep)).map((count, index) => (
                                        <>
                                            <li className={`page__numbers ${pageNum == count ? 'active' : ''}`}
                                                onClick={() => {
                                                    setPageNum(count);
                                                    getFeedback(count, lastStep, firstStep);
                                                }}> {count}</li>
                                        </>
                                    ))}
                                    {counts.length > 6 && (5 + (pageNum)) < counts.length && (
                                        <>
                                            <li className="page__numbers "> ...</li>
                                            <li className="page__numbers "
                                                onClick={() => {
                                                    setPageNum(counts.length);
                                                    getFeedback(counts.length, lastStep, firstStep);
                                                    setFirstStep(stepCount - 1);
                                                    setLastStep(stepCount);
                                                }}> {(counts.length)}</li>
                                        </>
                                    )}
                                    <li className="page__numbers" onClick={() => {
                                        if (pageNum < counts.length) {
                                            setPageNum(pageNum + 1);
                                            getFeedback(pageNum + 1, lastStep, firstStep);
                                        } else {
                                            setPageNum(1);
                                            setFirstStep(0);
                                            setLastStep(0);
                                            getFeedback(1, lastStep, firstStep);
                                        }

                                    }}><span
                                        className="material-icons"><IoIosArrowForward/></span></li>
                                </ul>
                            </div>
                        </div>


                    </div>

                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default Feedback;