import React from 'react';

const SocialMediaTelegram = () => {
    return (
        <a className="list-group-item  border-0 SocialMediaTelegram" target="_blank"
           href={'https://t.me/sigloyex'}
           id="list-link">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 200 200"
                style={{ enableBackground: "new 0 0 200 200" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.st0{fill:none;stroke:#D42E35;stroke-width:7.3896;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#1D9BF0;stroke-width:7;stroke-linecap:round;stroke-linejoin:round;}\n\t.st2{fill:none;stroke:url(#SVGID_1_);stroke-width:7;stroke-linecap:round;stroke-linejoin:round;}\n\t.SocialMediaTelegramSt3{fill:none;stroke-width:11;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <path
                    className="SocialMediaTelegramSt3"
                    d="M116,112.2c-12.6,12.1-19,21-23.8,26.8c6.8,4.1,55.9,34.3,80.5,50c2.9-63.5,15.2-130.8,21-156.5
	C146.2,44.6,48.7,85.5,6,104.4l52.6,19.9c15.8-10.5,55.6-35.3,88.2-50.9"
                />
            </svg>
        </a>
    );
};

export default SocialMediaTelegram;
