import React from 'react';

function SettingPageIco(props) {
    return (
        <div className="SettingPageIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.SettingPageIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <line className="SettingPageIcoSt0" x1="3.5" y1="22.2" x2="60.1" y2="22.7" />
                    <line className="SettingPageIcoSt0" x1="88.4" y1="22.7" x2="96.5" y2="22.7" />
                    <line className="SettingPageIcoSt0" x1="3.5" y1={49} x2="11.6" y2={49} />
                    <line className="SettingPageIcoSt0" x1="41.9" y1={50} x2="92.5" y2={50} />
                    <line className="SettingPageIcoSt0" x1="3.5" y1="77.8" x2="43.9" y2="77.8" />
                    <line className="SettingPageIcoSt0" x1="72.2" y1="77.3" x2="92.5" y2="77.3" />
                    <circle className="SettingPageIcoSt0" cx="69.8" cy="22.2" r="9.3" />
                    <circle className="SettingPageIcoSt0" cx="31.8" cy={50} r="9.3" />
                    <circle className="SettingPageIcoSt0" cx="53.1" cy="77.8" r="9.3" />
                </g>
            </svg>
        </div>
    );
}

export default SettingPageIco;