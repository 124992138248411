import React, {useContext} from 'react';
import {Theme} from "../ThemeContext";
import {Context} from "../../translation/Wrapper";

function PlaceOrderBtn(props) {

    const context = useContext(Context);

    return (
        <button className="PlaceOrderBtn">
            {context.locale == "fa"  ?
                <svg viewBox="0 0 250 120">
                    <style>
                        {".PlaceOrderBtnSt0{fill:none;}"}
                        {".PlaceOrderBtnSt1{fill:none;stroke-width:3.8676;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                    </style>
                    <line className="PlaceOrderBtnSt1" x1="47.9" y1="15.6" x2="50.3" y2="15.6"/>
                    <g>
                        <rect className="PlaceOrderBtnSt0" x="53.3" y="42.9" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -15.7592 52.7539)"
                              width="4.9" height="4.9"/>
                        <path className="PlaceOrderBtnSt0" d="M69.2,65.3c0.1-0.8,0.2-1.6,0.2-2.5c0-2.5-0.5-5.2-1.4-7.9l-5,1.6c0.8,2.4,1.2,4.6,1.2,6.4c0,1.5-0.2,2.6-0.5,3.1
		s-0.9,0.8-1.8,0.8c-0.8,0-1.4-0.2-1.8-0.6c-0.4-0.4-0.6-1.2-0.6-2.3c0-0.5,0-0.9,0-1.1l0.6-4L55,58l-0.8,5.3
		c-0.3,1.3-0.6,2.2-1.1,2.7s-1,0.8-1.4,0.8c-1.4,0-2.5-1.2-3.5-3.8l-2-5.4L41,59.2l2,5.4c0.7,1.8,1,3.3,1,4.6c0,2.5-0.9,4.4-2.7,5.8
		c-1.8,1.4-4.2,2.1-7.1,2.1c-2.7,0-4.8-0.5-6.4-1.7c-1.5-1.2-2.3-2.8-2.3-4.8c0-1.2,0.4-2.8,1.3-4.6c0.9-1.8,2.2-3.7,3.9-5.8l-4-3.3
		c-4.3,5.1-6.5,9.7-6.5,13.8c0,1.6,0.3,3,0.8,4.3c0.6,1.3,1.3,2.4,2.2,3.3c0.9,0.9,2,1.7,3.2,2.3c1.2,0.6,2.5,1.1,3.8,1.4
		c1.3,0.3,2.6,0.4,4,0.4c1.7,0,3.3-0.3,4.9-0.7c1.6-0.4,3.1-1,4.5-1.9c1.4-0.8,2.6-1.9,3.6-3.4s1.6-3.1,1.9-4.9
		c0.8,0.3,1.6,0.4,2.4,0.4c1.9,0,3.5-0.7,4.9-2.1c1.5,1.4,3.3,2.1,5.4,2.1c1.1,0,2.1-0.2,3-0.6c0.9-0.4,1.6-0.9,2.2-1.5
		s1-1.3,1.4-2.1C68.8,66.9,69.1,66.1,69.2,65.3z"/>
                        <polygon
                            className="PlaceOrderBtnSt0"
                            points="55.8,51.3 59.2,54.8 62.7,51.3 59.2,47.8 55.8,51.2 52.3,47.8 48.8,51.3 52.3,54.8 	"/>
                        <path className="PlaceOrderBtnSt0" d="M74.4,59.5c0.7,1.5,1.1,3.1,1.5,4.6c0.4,1.5,0.6,2.9,0.6,4.1c0,2.4-0.6,4.3-1.9,5.9c-1.3,1.6-3.4,2.9-6.4,3.9l1.8,4.9
		c3.9-1.4,6.8-3.3,8.8-5.8s3-5.5,3-8.9c0-2.8-0.9-6.4-2.5-10.6L74.4,59.5z"/>
                        <rect className="PlaceOrderBtnSt0" x="102.3" y="41.7" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -0.5589 87.0507)"
                              width="4.9" height="4.9"/>
                        <path className="PlaceOrderBtnSt0" d="M143.9,55l-5.2,0.8l1,6.4c0.1,0.6,0.1,1,0.1,1.4c0,0.9-0.2,1.7-0.6,2.2c-0.2,0.3-0.4,0.5-0.6,0.6c-0.2,0.1-0.5,0.2-1,0.2
		c-0.4,0-0.7,0-0.9-0.1s-0.4-0.2-0.6-0.5c-0.2-0.3-0.5-0.8-0.7-1.6c-0.2-0.8-0.3-1.6-0.3-2.6V58h-5.2v3.8c0,1-0.1,1.9-0.3,2.6
		c-0.2,0.7-0.4,1.3-0.7,1.6c-0.2,0.2-0.4,0.4-0.6,0.5s-0.5,0.1-0.9,0.1c-0.4,0-0.7,0-0.9-0.1s-0.4-0.2-0.6-0.5
		c-0.3-0.3-0.5-0.8-0.7-1.6c-0.2-0.8-0.3-1.6-0.3-2.6V58h-5.2v2c0,2.8-0.3,4.6-1,5.4c-0.7,0.9-2.2,1.3-4.4,1.3c-0.3,0-0.5,0-0.7,0.1
		c-1,0-1.9,0-2.7-0.1c1.3-1.7,1.9-3.7,1.9-5.9c0-2.5-0.8-4.6-2.3-6.1s-3.4-2.3-5.7-2.3c-2.4,0-4.3,0.8-5.8,2.3
		c-1.5,1.6-2.3,3.6-2.3,6.1c0,2.3,0.6,4.2,1.9,5.9c-0.9,0.1-2,0.1-3.3,0.1c-2.1,0-3.5-0.3-4-0.9c-0.4-0.5-0.6-1.5-0.6-3.1V43.6h-5.2
		v19.2c0,2.7,0.6,4.9,2,6.4c1.7,1.9,4.2,2.8,7.7,2.8c0,0,0,0,0,0c0,0,0,0,0.1,0c0,0,0,0,0,0v0c3.7,0,6.8-0.4,9.4-1.3
		c2.5,0.8,5.5,1.2,8.9,1.3c0.2,0.1,0.5,0.1,0.7,0.1c3.3,0,5.8-0.8,7.6-2.6c0.6,0.8,1.4,1.4,2.4,1.9s2.1,0.7,3.2,0.7
		c2,0,3.8-0.7,5.2-2.2c1.4,1.5,3.2,2.2,5.2,2.2c2.2,0,4-0.8,5.4-2.4c1.4-1.6,2.1-3.6,2.1-5.9c0-0.7-0.1-1.4-0.5-2.5L143.9,55z
		 M106.9,63.3c-0.4,0.7-1.2,1.3-2.1,1.8c-0.9-0.5-1.6-1.1-2.1-1.8s-0.7-1.6-0.7-2.6c0-1.2,0.1-2,0.6-2.5s1.2-0.7,2.2-0.7
		c0.9,0,1.6,0.2,2.1,0.7s0.7,1.3,0.7,2.5C107.6,61.8,107.4,62.6,106.9,63.3z"/>
                        <polygon
                            className="PlaceOrderBtnSt0"
                            points="178.9,55.6 182.3,59.1 185.8,55.6 182.3,52.1 178.9,55.5 175.4,52.1 171.9,55.6 175.4,59.1 	"/>
                        <rect className="PlaceOrderBtnSt0" x="202.2" y="76.5" transform="matrix(0.7071 -0.7071 0.7071 0.7071 4.0938 167.8833)"
                              width="4.9" height="4.9"/>
                        <rect className="PlaceOrderBtnSt0" x="220.3" y="39.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 35.6996 169.7863)"
                              width="4.9" height="4.9"/>
                        <rect className="PlaceOrderBtnSt0" x="223.8" y="45.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.482 174.0186)"
                              width="4.9" height="4.9"/>
                        <path className="PlaceOrderBtnSt0" d="M221.7,54.8c0.4,1.1,0.7,2.4,1,3.7s0.4,2.4,0.4,3.2c0,1.9-0.3,3.3-0.9,4c-0.2,0.2-0.6,0.5-1.4,0.7
		c-0.8,0.3-1.7,0.4-2.8,0.4v-0.1c-2.1,0-3.5-0.4-4.2-1.2c-0.8-0.9-1.2-2.7-1.2-5.4V58h-5.2v2.2c0,2.8-0.4,4.6-1.3,5.5
		c-0.8,0.7-2.1,1.1-4.1,1.1c-1,0-1.7,0-2.3-0.1c-0.5,0-0.9-0.2-1.2-0.4s-0.4-0.5-0.5-0.7c-0.1-0.2-0.1-0.6-0.1-1.1
		c0-1.1,0.3-3,1.1-5.8l-5.2-1.2c-0.2,1.2-0.4,2-0.6,2.6c-0.6,2.3-2.3,4-5,5.1c-2.7,1.1-6.5,1.7-11.5,1.7c-1.8,0-3.4-0.1-4.7-0.3
		c-1.3-0.2-2.3-0.5-3-0.9c-0.7-0.4-1.2-0.9-1.5-1.4c-0.3-0.5-0.5-1.1-0.5-1.8c0-0.6,0.1-1.4,0.4-2.4s0.6-1.9,0.9-2.7l-4.9-2
		c-1.1,2.8-1.6,5.1-1.6,7c0,1,0.1,2,0.4,2.9s0.7,1.7,1.2,2.3c0.5,0.6,1.2,1.2,1.9,1.7c0.8,0.6,1.5,1,2.4,1.3
		c0.8,0.3,1.8,0.6,2.8,0.8c1,0.2,2,0.3,3,0.4c1,0.1,2.1,0.1,3.2,0.1c3.6,0,6.8-0.2,9.6-0.8c2.8-0.5,5.2-1.5,7.2-2.9
		c1.4,2.5,4.2,3.8,8.5,3.8c3.5,0,6.1-0.9,8-2.9c1.8,1.8,4.2,2.7,7.3,2.9c0.2,0.1,0.5,0.1,0.7,0.1c3.8,0,6.5-0.9,8.2-2.9
		c1.5-1.8,2.2-4.2,2.2-7.4c0-2.5-0.6-5.4-1.7-8.6L221.7,54.8z"/>
                        <rect className="PlaceOrderBtnSt0" x="216.8" y="45.3" transform="matrix(0.7071 -0.7071 0.7071 0.7071 30.4318 169.0688)"
                              width="4.9" height="4.9"/>
                    </g>
                    <g>
                        <g>
                            <path className="PlaceOrderBtnSt1"
                                  d="M67.1,16.2h172.2c3.3,0,6.2,2.2,6.2,5v78.7c0,2.7-2.7,5-6.2,5H10.7c-3.3,0-6.2-2.2-6.2-5V47.2"/>
                            <line className="PlaceOrderBtnSt1" x1="50.4" y1="16.2" x2="52.9" y2="16.2"/>
                        </g>
                    </g>
                </svg>
                :
                <svg viewBox="0 0 250 120">
                    <style>
                        {".PlaceOrderBtnSt0{fill:none;}"}
                        {".PlaceOrderBtnSt1{fill:none;stroke:#000000;stroke-width:3.7764;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}"}
                    </style>
                    <path className="PlaceOrderBtnSt0" d="M12.5,46h12.6c1.8,0,3.3,0.3,4.4,0.8c1.3,0.5,2.3,1.1,3,2c0.8,0.9,1.3,1.8,1.6,2.9c0.4,1.1,0.5,2.1,0.5,3.3
			s-0.1,2.3-0.5,3.3s-0.9,2-1.6,2.9c-0.8,0.9-1.8,1.5-3,2c-1.3,0.5-2.8,0.8-4.4,0.8h-6.4v10h-6.2C12.5,74,12.5,46,12.5,46z
			 M18.6,59.2h4.8c0.8,0,1.4,0,2-0.1s1.3-0.3,1.8-0.6s0.9-0.8,1.1-1.3c0.3-0.5,0.5-1.3,0.5-2.1c0-0.9-0.1-1.6-0.5-2.1
			c-0.3-0.5-0.8-1-1.1-1.3c-0.5-0.3-1-0.5-1.8-0.6C24.8,51,24,51,23.4,51h-4.8C18.6,51,18.6,59.2,18.6,59.2z"/>
                    <path className="PlaceOrderBtnSt0" d="M37.9,46h5.6v28h-5.6V46z"/>
                    <path className="PlaceOrderBtnSt0" d="M47.6,59.9c0.1-1.3,0.4-2.4,1-3.3c0.6-0.9,1.3-1.5,2.1-2c0.9-0.5,1.9-0.9,3-1.1s2.3-0.4,3.4-0.4c1,0,2,0.1,3.2,0.3
			s2,0.4,2.9,0.9c0.9,0.4,1.5,1,2.1,1.8c0.5,0.8,0.8,1.8,0.8,2.9v10.5c0,0.9,0,1.8,0.1,2.7c0.1,0.9,0.3,1.5,0.5,1.9H61
			c-0.1-0.3-0.1-0.6-0.3-1c-0.1-0.4-0.1-0.6-0.1-1c-0.9,0.9-1.9,1.5-3.2,1.9c-1.1,0.4-2.4,0.6-3.7,0.6c-1,0-1.9-0.1-2.7-0.4
			c-0.9-0.3-1.5-0.6-2.1-1.1s-1.1-1.1-1.5-1.9s-0.5-1.6-0.5-2.7s0.3-2.1,0.6-2.8s0.9-1.4,1.5-1.8c0.6-0.4,1.4-0.8,2.3-1
			c0.9-0.3,1.6-0.4,2.5-0.5s1.6-0.3,2.5-0.4s1.5-0.3,2.1-0.4c0.6-0.1,1.1-0.4,1.5-0.6c0.4-0.3,0.5-0.8,0.5-1.3
			c0-0.6-0.1-1.1-0.3-1.4c-0.3-0.4-0.5-0.6-0.8-0.8c-0.4-0.3-0.8-0.4-1.1-0.4c-0.4,0-0.9-0.1-1.4-0.1c-1,0-1.9,0.3-2.5,0.8
			s-1,1.3-1.1,2.4h-5.9v-0.3H47.6z M60.5,64c-0.3,0.3-0.5,0.4-0.9,0.5s-0.8,0.3-1.1,0.3c-0.4,0.1-0.9,0.1-1.3,0.3
			c-0.5,0-0.9,0.1-1.3,0.3c-0.5,0.1-0.9,0.1-1.3,0.3c-0.4,0.1-0.8,0.3-1,0.5c-0.3,0.3-0.5,0.5-0.8,0.9c-0.1,0.4-0.3,0.8-0.3,1.3
			s0.1,0.9,0.3,1.3c0.1,0.4,0.4,0.6,0.8,0.8c0.4,0.3,0.6,0.4,1.1,0.4C55.1,71,55.6,71,56,71c1.1,0,1.9-0.1,2.5-0.5s1-0.8,1.3-1.3
			s0.5-1,0.5-1.5c0.1-0.5,0.1-0.9,0.1-1.3V64H60.5z"/>
                    <path className="PlaceOrderBtnSt0" d="M84.1,60.8c-0.4-2.3-1.8-3.4-4-3.4c-0.9,0-1.6,0.3-2.3,0.6s-1.1,0.9-1.5,1.5s-0.6,1.4-0.8,2.1c-0.1,0.8-0.3,1.5-0.3,2.3
			c0,0.8,0.1,1.5,0.3,2.3s0.4,1.4,0.8,2s0.9,1.1,1.4,1.5c0.6,0.4,1.4,0.6,2.1,0.6c1.3,0,2.3-0.4,3-1.1c0.8-0.8,1.1-1.8,1.4-3h5.4
			c-0.4,2.7-1.4,4.7-3.2,6.2c-1.8,1.4-3.9,2.1-6.6,2.1c-1.5,0-2.9-0.3-4.2-0.8c-1.3-0.5-2.3-1.3-3.2-2.1c-0.9-0.9-1.5-2-2-3.3
			s-0.8-2.7-0.8-4.2s0.3-3,0.6-4.4c0.5-1.4,1.1-2.5,2-3.5s2-1.8,3.3-2.3s2.8-0.8,4.4-0.8c1.1,0,2.4,0.1,3.4,0.5s2.1,0.8,2.9,1.4
			c0.8,0.6,1.5,1.4,2.1,2.4c0.5,1,0.9,2,0.9,3.4h-5.4v0H84.1z"/>
                    <path className="PlaceOrderBtnSt0" d="M97.5,65.1c0.1,1.8,0.5,3,1.4,3.9c0.9,0.8,2,1.3,3.7,1.3c1.1,0,2.1-0.3,2.9-0.9s1.3-1.1,1.5-1.8h4.9
			c-0.8,2.4-2,4.2-3.5,5.2c-1.6,1-3.5,1.6-5.8,1.6c-1.6,0-3-0.3-4.3-0.8s-2.4-1.3-3.3-2.1c-0.9-0.9-1.5-2-2-3.4
			c-0.5-1.3-0.8-2.8-0.8-4.3c0-1.5,0.3-2.9,0.8-4.2s1.3-2.4,2.1-3.4c0.9-1,2-1.6,3.3-2.3c1.3-0.5,2.7-0.8,4.2-0.8
			c1.8,0,3.2,0.4,4.4,1s2.3,1.5,3.2,2.7c0.8,1.1,1.4,2.4,1.8,3.8c0.4,1.4,0.5,2.9,0.4,4.5L97.5,65.1L97.5,65.1z M106.5,61.6
			c-0.3-1.4-0.8-2.5-1.4-3.2c-0.6-0.8-1.6-1.1-3-1.1c-0.9,0-1.6,0.1-2.1,0.5c-0.6,0.3-1,0.6-1.4,1.1c-0.4,0.4-0.6,0.9-0.8,1.4
			c-0.1,0.5-0.3,0.9-0.3,1.3H106.5z"/>
                    <path className="PlaceOrderBtnSt0" d="M122.3,60.1c0-2,0.3-4,0.9-5.8s1.5-3.4,2.7-4.7c1.1-1.4,2.7-2.4,4.3-3.2c1.6-0.8,3.7-1.1,5.8-1.1s4,0.4,5.8,1.1
			c1.6,0.8,3.2,1.8,4.3,3.2s2,2.9,2.7,4.7c0.6,1.8,1,3.7,1,5.8c0,2-0.3,3.9-1,5.7c-0.6,1.8-1.5,3.3-2.7,4.7
			c-1.1,1.4-2.7,2.4-4.3,3.2c-1.6,0.8-3.7,1.1-5.8,1.1s-4-0.4-5.8-1.1c-1.8-0.8-3.2-1.8-4.3-3.2s-2-2.9-2.7-4.7
			C122.7,64,122.3,62.1,122.3,60.1z M128.5,60.1c0,1.1,0.1,2.3,0.4,3.4c0.3,1.1,0.8,2.1,1.3,3c0.6,0.9,1.4,1.6,2.4,2.1
			s2.1,0.8,3.5,0.8s2.5-0.3,3.5-0.8s1.8-1.3,2.4-2.1c0.6-0.9,1-1.9,1.3-3s0.4-2.3,0.4-3.4c0-1.3-0.1-2.4-0.4-3.5
			c-0.3-1.1-0.8-2.1-1.3-3c-0.6-0.9-1.4-1.6-2.4-2.1s-2.1-0.8-3.5-0.8s-2.5,0.3-3.5,0.8s-1.8,1.3-2.4,2.1s-1,1.9-1.3,3
			C128.6,57.7,128.5,58.8,128.5,60.1z"/>
                    <path className="PlaceOrderBtnSt0" d="M153.3,53.6h5.3v3.8h0.1c0.3-0.6,0.6-1.3,1-1.8c0.5-0.5,1-1,1.5-1.4c0.6-0.4,1.1-0.6,1.9-0.9c0.6-0.3,1.4-0.3,2-0.3
			c0.4,0,0.9,0,1.3,0.1v5.2c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0-0.8,0-1,0c-1,0-1.9,0.1-2.5,0.5c-0.8,0.4-1.3,0.8-1.8,1.4
			c-0.4,0.6-0.8,1.3-0.9,2c-0.1,0.8-0.3,1.6-0.3,2.5v9.1h-5.6V53.6H153.3z"/>
                    <path className="PlaceOrderBtnSt0" d="M182.7,71.3L182.7,71.3c-0.8,1.1-1.6,1.9-2.7,2.4s-2.3,0.8-3.5,0.8c-1.5,0-2.9-0.3-4-0.9s-2-1.4-2.8-2.4s-1.3-2.1-1.6-3.4
			s-0.5-2.7-0.5-4c0-1.4,0.1-2.7,0.5-3.9c0.4-1.3,0.9-2.4,1.6-3.3c0.8-1,1.6-1.8,2.8-2.3c1.1-0.5,2.4-0.9,3.9-0.9
			c1.1,0,2.3,0.3,3.4,0.8c1.1,0.5,1.9,1.3,2.5,2.3h0.1V46.3h5.6v27.9h-5.3V71.3z M182.7,63.7c0-0.8-0.1-1.6-0.3-2.4
			c-0.1-0.8-0.4-1.5-0.8-2c-0.4-0.6-0.9-1-1.5-1.4s-1.4-0.5-2.3-0.5s-1.6,0.1-2.3,0.5c-0.6,0.4-1.1,0.9-1.5,1.4
			c-0.4,0.6-0.6,1.3-0.8,2c-0.1,0.8-0.3,1.5-0.3,2.4c0,0.8,0.1,1.5,0.3,2.3c0.1,0.8,0.5,1.5,0.9,2.1s0.9,1.1,1.5,1.5
			s1.4,0.6,2.1,0.6c0.9,0,1.6-0.1,2.3-0.5c0.6-0.4,1.1-0.9,1.5-1.5s0.6-1.3,0.8-2.1C182.6,65.4,182.7,64.6,182.7,63.7z"/>
                    <path className="PlaceOrderBtnSt0" d="M197,65.1c0.1,1.8,0.5,3,1.4,3.9c0.9,0.8,2,1.3,3.7,1.3c1.1,0,2.1-0.3,2.9-0.9c0.8-0.6,1.3-1.1,1.5-1.8h4.9
			c-0.8,2.4-2,4.2-3.5,5.2c-1.6,1-3.5,1.6-5.8,1.6c-1.6,0-3-0.3-4.3-0.8s-2.4-1.3-3.3-2.1s-1.5-2-2-3.4c-0.5-1.3-0.8-2.8-0.8-4.3
			c0-1.5,0.3-2.9,0.8-4.2s1.3-2.4,2.1-3.4c0.9-1,2-1.6,3.3-2.3c1.3-0.5,2.7-0.8,4.2-0.8c1.8,0,3.2,0.4,4.4,1s2.3,1.5,3.2,2.7
			c0.8,1.1,1.4,2.4,1.8,3.8c0.4,1.4,0.5,2.9,0.4,4.5L197,65.1L197,65.1z M206,61.6c-0.3-1.4-0.8-2.5-1.4-3.2c-0.6-0.8-1.6-1.1-3-1.1
			c-0.9,0-1.6,0.1-2.1,0.5c-0.6,0.3-1,0.6-1.4,1.1c-0.4,0.4-0.6,0.9-0.8,1.4c-0.1,0.5-0.3,0.9-0.3,1.3H206z"/>
                    <path className="PlaceOrderBtnSt0" d="M214.7,53.6h5.3v3.8h0.1c0.3-0.6,0.6-1.3,1-1.8c0.5-0.5,1-1,1.5-1.4c0.6-0.4,1.1-0.6,1.9-0.9c0.6-0.3,1.4-0.3,2-0.3
			c0.4,0,0.9,0,1.3,0.1v5.2c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0-0.8,0-1,0c-1,0-1.9,0.1-2.5,0.5c-0.8,0.4-1.3,0.8-1.8,1.4
			c-0.4,0.6-0.8,1.3-0.9,2c-0.1,0.8-0.3,1.6-0.3,2.5v9.1h-5.6V53.6H214.7z"/>

                    <path className="PlaceOrderBtnSt1"
                          d="M63.9,15.6H228c3.2,0,5.9,2.2,5.9,5v78.7c0,2.7-2.6,5-5.9,5H10.1c-3.2,0-5.9-2.2-5.9-5V46.6"/>
                    <line className="PlaceOrderBtnSt1" x1="47.9" y1="15.6" x2="50.3" y2="15.6"/>

                </svg>

            }





        </button>
    );
}

export default PlaceOrderBtn;