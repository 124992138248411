import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import NavBar from "../widgets/NavBar";
import "../css/bootstrap.css";
import "../css/Trade.scss";
import PropTypes from "prop-types";
import {RiStarLine} from "@react-icons/all-files/ri/RiStarLine";
import {BsInfo} from "@react-icons/all-files/bs/BsInfo";
import TradingViewWidget, {Themes} from "react-tradingview-widget";
import {Popover, CircularProgress, Box, RadioGroup, FormControlLabel, Radio} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import axios from "axios";
import {parseFulDate, parseNumber, parseUSDPrice} from "../../Extentions";
import {useHistory} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import {Grid} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import $ from "jquery";
import {Scrollbars} from "react-custom-scrollbars-2";
import {LoginContext} from "../../Root";
import {Context} from "../../translation/Wrapper";
import {FormattedMessage} from "react-intl";
import {Theme} from "../ThemeContext";
import MarketBar from "../widgets/MarketBar";
import {BottomModal, ModalCloseTarget} from "react-spring-modal";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import {useToasts} from 'react-toast-notifications';
import ErrorPage from "../widgets/ErrorPage";
import NotAllowed from "../widgets/NotAllowed";
import LoadingSpinner from "../widgets/LoadingSpinner";
import {makeStyles} from "@material-ui/core/styles";
import PlaceOrderBtn from "../IconsClasses/PlaceOrderBtn";
import Footer from "../widgets/Footer";

export const PrettoSlider = withStyles({
    root: {
        color: "#BDE3F2",
        height: 9,
    },
    thumb: {
        height: 8,
        width: 8,
        backgroundColor: "#BDE3f2",
        marginTop: 1,
        marginLeft: 8,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 4,
        borderRadius: 4,
        background: "linear-gradient(90deg, rgba(158,149,183,1) 35%, rgba(189,227,242,1) 100%)",
        filter: "brightness(1.3)",
        marginLeft: 12,
        marginTop: 3
    },
    rail: {
        height: 10,
        width: "104%",
        borderRadius: 4,
        backgroundColor: "transparent",
        border: "1px solid #808080"
    },
    mark: {
        height: 4,
        borderRadius: 4,
        width: 3,
        marginTop: 3,
        marginLeft: 13,
        marginRight: 3,
    },
    markLabel: {
        color: '#969696',
        marginLeft: 14
    }
})(Slider);


export const marks = [
    {
        value: 0,
        label: "0%",
    },
    {
        value: 25,
        label: "25%",
    },
    {
        value: 50,
        label: "50%",
    },
    {
        value: 75,
        label: "75%",
    },
    {
        value: 100,
        label: "100%",
    },
];

function SliderValueLabelComponent(props) {
    const {children, open, value} = props;
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={value + "%"}
        >
            {children}
        </Tooltip>
    );
}

SliderValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Trade(props, {content}) {


    const TableCell = withStyles({
        root: {
            borderBottom: "none"
        }
    })(MuiTableCell);

    const {addToast} = useToasts();

    const controller = document.querySelector("input[type=range]");

    let els = document.getElementsByClassName("step");
    let steps = [];
    Array.prototype.forEach.call(els, (e) => {
        steps.push(e);
        e.addEventListener("click", (x) => {
            progress(x.target.id);
        });
    });

    function progress(stepNum) {
        let p = stepNum * 25;
        $("input[type=range]").val(p);

        steps.forEach((e) => {
            if (e.id === stepNum) {
                e.classList.remove("completed");
            }
            if (e.id < stepNum) {
                e.classList.add("completed");
            }
            if (e.id > stepNum) {
                e.classList.remove("completed");
            }
        });
    }

    const [isOpen, setOpen] = useState(false);

    const history = useHistory();
    const chartRef = useRef();
    const [error, setError] = useState(false);

    const {isLoggedIn, setIsLoggedIn} = useContext(LoginContext);

    const [width, setWidth] = useState(1600);
    const [chartTop, setChartTop] = useState(0);
    const [chartLeft, setChartLeft] = useState(0);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClose = () => {
        setAnchorEl(null);
        setIsOpenCoinSelectorPopover(false);
    };

    const [isOpenCoinSelectorPopover, setIsOpenCoinSelectorPopover] =
        useState(false);

    const [isOrderBookSelected, setIsOrderBookIsSelected] = useState(true);
    const [marketList, setMarketList] = useState([]);

    const [maxBuyOrderBookVolume, setMaxBuyOrderBookVolume] = useState(0);
    const [maxSellOrderBookVolume, setMaxSellOrderBookVolume] = useState(0);

    const [maxTradeHistoryVolume, setMaxTradeHistoryVolume] = useState(0);

    const [buyOrdersBook, setBuyOrdersBook] = useState([]);
    const [sellOrdersBook, setSellOrdersBook] = useState([]);

    const [tradeHistory, setTradeHistory] = useState([]);

    const [availableUSDT, setAvailableUSDT] = useState(0);
    const [availableCoin, setAvailableCoin] = useState(0);
    const [marketPrice, setMarketPrice] = useState(1);

    const [price, setPrice] = useState();
    const [stop, setStop] = useState();
    const [limit, setLimit] = useState();
    const [amount, setAmount] = useState();


    const [isCurrentOrdersSelected, setIsCurrentOrdersSelected] = useState(true);
    const [currentOrders, setCurrentOrders] = useState([]);

    const [currentStopOrders, setCurrentStopOrders] = useState([]);


    const Fake = []

    const [ordersHistory, setOrdersHistory] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [marketDetail, setMarketDetail] = useState();
    const marketDetailRef = useRef()

    const getMarketDetail = async () => {
        setLoading(true);
        axios.get("core/marketDetail?market=" + pair.current).then((res) => {
            setMarketDetail(res.data.data);
            marketDetailRef.current = res.data.data;
            setupWebSockets(res.data.data);
        });

        if (localStorage.getItem("token")) {
            getBalanceInfo();
            getPendingOrders();
            getOrderFinished();
        }

        setLoading(false);
    };

    const [isLoadingBalances, setIsLoadingBalances] = useState(false);
    const [balances, setBalances] = useState();

    const getBalanceInfo = useCallback(async () => {
        setIsLoadingBalances(true);

        try {
            const Response = await axios.get('core/balanceInfo', {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });


            if (Response.data.status == "ok") {
                setBalances(Response.data.data);
                setAvailableUSDT(Response.data.data.USDT?.available ?? 0);
                if (Response.data.data[marketDetailRef.current.name.split("USDT")[0]]) {
                    setAvailableCoin(Response.data.data[marketDetailRef.current.name.split("USDT")[0]].available);
                } else {
                    setAvailableCoin(0);
                }
            } else {
                setError(true);
            }
        } catch (error) {
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }


        setIsLoadingBalances(false);
    }, [])

    const [isBuyMode, setIsBuyMode] = useState(true);
    const [addingOrder, setAddingOrder] = useState(false);

    async function addLimitOrderHandler(event) {
        setAddingOrder(true);
        event.preventDefault();
        const tokken = `bearer ${localStorage.getItem("token")}`;
        const isBuy = isBuyMode ? "buy" : "sell";
        const limitOrder = {
            "market": marketDetail.name,
            "type": isBuy,
            "amount": amount,
            "price": price
        };

        try {
            const response = await axios.post(`${axios.defaults.baseURL}core/orderLimit`, limitOrder, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokken,
                }
            });

            const data = await response.data;

            if (data.status != "ok") {
                if (data.errors[0].msg != undefined)
                    addToast(data.errors[0].msg, {appearance: "error", autoDismiss: true});

            } else {
                addToast('Your Order Was successful', {appearance: "success", autoDismiss: true});
                setOpen(false);
                setPrice("");
                setAmount("");
                await getPendingOrders();
                await getBalanceInfo();
                await getOrderFinished();
            }
        } catch (error) {
            if ((error?.response?.status ?? 0) === 403) {
                addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }
        setAddingOrder(false);
    }

    async function addMarketOrderHandler(event) {
        setAddingOrder(true);
        event.preventDefault();
        const tokken = "bearer " + localStorage.getItem("token");
        const isBuy = isBuyMode ? "buy" : "sell";
        const marketOrder = {
            "type": isBuy,
            "amount": amount
        };

        try {
            const response = await axios.post(`${axios.defaults.baseURL}core/orderMarket?market=${pair.current}`, marketOrder, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokken,
                }
            });
            const data = await response.data;

            if (data.status != "ok") {
                addToast(data.errors[0].msg, {appearance: "error", autoDismiss: true});
            } else {
                addToast('Your Order Was successful', {appearance: "success", autoDismiss: true});
                setAmount("");
                await getBalanceInfo();
                await getOrderFinished();
                setOpen(false);
            }
        } catch (error) {
            if ((error?.response?.status ?? 0) === 403) {
                addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }
        setAddingOrder(false);
    }

    async function addStopLimitOrderHandler(event) {
        setAddingOrder(true);
        event.preventDefault();
        const tokken = "bearer " + localStorage.getItem("token");
        const isBuy = isBuyMode ? "buy" : "sell";
        const marketOrder = {
            "market": marketDetail.name,
            "type": isBuy,
            "amount": amount,
            "price": limit,
            "stop_price": stop
        };


        try {
            const response = await axios.post(`${axios.defaults.baseURL}core/orderStopLimit`, marketOrder, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': tokken,
                }
            });
            const data = await response.data;

            if (data.status != "ok") {
                addToast(data.errors[0].msg, {appearance: "error", autoDismiss: true});
            } else {
                addToast('Your Order Was successful', {appearance: "success", autoDismiss: true});
                setLimit("");
                setStop("");
                setAmount("");
                await getPendingOrders();
                await getBalanceInfo();
                await getOrderFinished();
                setOpen(false);
            }
        } catch (error) {
            if ((error?.response?.status ?? 0) === 403) {
                addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
            }
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }

        setAddingOrder(false);
    }

    const [orderModeIndex, setOrderModeIndex] = useState(0);


    // {"method": "state.update", "params": [{"ETHUSDT": {"last": "3946.14", "open": "3913.96", "close": "3946.14", "high": "3980.89", "low": "3898.03", "volume": "2347.14186289", "sell_total": "63.75037160", "buy_total": "96.70139570", "period": 86400, "deal": "9245048.7223123002"}}], "id": null}

    const [lastTsCurrentPrice, setLastTsCurrentPrice] = useState(0);
    const [floorAmount, setFloorAmount] = useState(100);
    const [lastTsIsBuy, setLastTsIsBuy] = useState(0);

    const [currentPrice, setCurrentPrice] = useState(0);
    const [currentPriceUSD, setCurrentPriceUSD] = useState(0);
    const [twentyFourOpen, setTwentyFourOpen] = useState(0);
    const [twentyFourHigh, setTwentyFourHigh] = useState(0);
    const [twentyFourLowest, setTwentyFourLowest] = useState(0);
    const [twentyFourVolume, setTwentyFourVolume] = useState(0);

    const pair = useRef("");
    const pairToShow = useRef("");
    const [sliderValue, setSliderValue] = useState(0);

    const defaultActivity = {
        group: "",
        a: 0,
        b: 0
    };

    const [activity, setActivity] = useState(defaultActivity);

    const onDataChanged = (name, value) => {
        setActivity({
            ...activity,
            [name]: value
        });
    };


    const handleSliderChange = (event, newValue) => {

        //sellMode
        if (!isBuyMode) {
            setSliderValue(newValue);
            setAmount(availableCoin * newValue / 100);
        }
        //Buymode
        else {

            if (orderModeIndex === 0) {
                setSliderValue(newValue);
                setAmount((availableUSDT / price) * newValue / 100);
            } else if (orderModeIndex === 1) {
                setMarketPrice(currentPrice);
                setSliderValue(newValue);
                setAmount(availableUSDT * newValue / 100);
            } else if (orderModeIndex === 2) {
                setSliderValue(newValue);
                setPrice(limit);
                setAmount((availableUSDT / price) * newValue / 100);
            }
        }

    };

    useEffect(() => {
        const search = props.location.search;
        let params = new URLSearchParams(search);
        if (params.get("pair") != null)
            localStorage.setItem("TradeLastCoin", params.get("pair").toUpperCase())

        if (localStorage.getItem("TradeLastCoin") == null) {
            if (params.get("pair") == null || params.get("pair").length == 0) {
                window.location = "/Trade?pair=btcusdt";
            }
            if (params.get("pair") != null && params.get("pair").length > 0) {
                pair.current = params.get("pair").toUpperCase();
                localStorage.setItem("TradeLastCoin", pair.current)
            }
        } else {
            pair.current = localStorage.getItem("TradeLastCoin");
        }


        setInterval(() => {
            setWidth(window.innerWidth)
        }, 100)
        getList(pair.current);

    }, []);

    const getList = useCallback(async (coinName) => {

        try {
            const listResponse = await axios.get('currencies/list');

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }

            const marketL = (listResponse.data);

            let theOne;

            for (var keys in marketL) {
                if (coinName === marketL[keys].short_name + "USDT") {
                    theOne = (marketL[keys].short_name + "USDT")
                    pairToShow.current = pair.current.split("USDT")[0] + "/USDT";
                    getMarketDetail();
                }
            }

            if (theOne != coinName) {
                setError(true)
            }

        } catch (e) {

            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }

    })


    const setupWebSockets = (marketData) => {
        const ws = new WebSocket("wss://socket.sigloyex.com");
        ws.onmessage = function (event) {
            const data = JSON.parse(event.data);

            if (data.method == "state.update") {
                setCurrentPrice(data.params[0][marketData.name].last);
                setTwentyFourHigh(data.params[0][marketData.name].high);
                setTwentyFourLowest(data.params[0][marketData.name].low);
                setTwentyFourOpen(data.params[0][marketData.name].open);
                setTwentyFourVolume(data.params[0][marketData.name].volume);
            } else if (data.method == "depth.update") {
                setLastTsCurrentPrice(data.params[1].last);

                if (0.01 < parseNumber(data.params[1].last)) {
                    setFloorAmount(1000);

                } else if (data.params[1].last > 10.0) {
                    setFloorAmount(1000000);
                } else if (0.0001 > data.params[1].last) {
                    setFloorAmount(1);
                }

                const buyLs = [];
                const sellLs = [];
                if (data.params[1].asks != undefined) {
                    data.params[1].asks.forEach((elem, index) => {
                        sellLs.push(elem);
                    });
                }
                if (data.params[1].bids != undefined) {
                    data.params[1].bids.forEach((elem, index) => {
                        buyLs.push(elem);
                    });
                }

                // data.params[1].forEach((elem, index) => {
                //     if (elem.asks ) {
                //         buyLs.push(elem);
                //     }
                //     if (elem.bids) {
                //         sellLs.push(elem);
                //     }
                // });

                setSellOrdersBook((prv) => {
                    const ls = prv;
                    ls.splice(
                        sellOrdersBook.length - 1,
                        sellLs.length - (6 - sellOrdersBook.length)
                    );

                    sellLs.forEach((elem) => {
                        if (elem[1] > 0) {
                            ls.push(elem);
                        }
                    });
                    ls.sort(function (a, b) {
                        return a.amount - b.amount;
                    });
                    return [...ls];
                });
                setBuyOrdersBook((prv) => {
                    const ls = prv;

                    ls.splice(
                        buyOrdersBook.length - 1,
                        buyLs.length - (6 - buyOrdersBook.length)
                    );
                    buyLs.forEach((elem) => {
                        if (elem[1] > 0) {
                            ls.push(elem);
                        }
                    });
                    ls.sort(function (a, b) {
                        return b.amount - a.amount;
                    });
                    return [...ls];
                });
            } else if (data.method == "deals.update") {
                // "type": "sell",             #order type
                // "time": 1496458040.059284,  #order time
                // "price": "17868.41",        #order price
                // "id": 29433,                #order no
                // "amount": "0.0281",         #order count
                // "client_id": "abc"          #client id

                setTradeHistory((prv) => {
                    const ls = prv;
                    if (ls.length > 20) {
                        ls.splice(ls.length - 1, ls.length - 200 + data.params[1].length);
                    }
                    const s = data.params[1];

                    if (s.length > 1) {
                        s.reverse()
                    }

                    s.forEach((elem) => {
                        ls.push(elem);

                        setLastTsIsBuy(elem.type == "buy");
                    });
                    return [...ls];
                });
            }
        };

        ws.onopen = function (event) {

            // const msg = JSON.stringify({
            //   method: "server.ping",
            //   params: [],
            //   id: 11,
            // });
            // ws.send(msg);

            // ws.send(
            //     JSON.stringify({
            //         method: "deals.query",
            //         params: [marketData.name, 15, 0],
            //         id: 16,
            //     })
            // );

            ws.send(
                JSON.stringify(
                    {
                        id: 4,
                        method: "state.subscribe",
                        params: [marketData.name]
                    }
                )
            );

            ws.send(
                JSON.stringify({
                    id: 5,
                    method: "deals.subscribe",
                    params: [marketData.name]
                })
            );

            ws.send(
                JSON.stringify({
                    id: 15,
                    "method": "depth.subscribe",
                    "params": [
                        marketData.name, 5, "0"]
                })
            );


        };
    };

    const [isLoadingPendingOrders, setLoadingPendingOrders] = useState(false);
    const pendingOrdersPage = useRef(1);

    const getPendingOrders = useCallback(async () => {
        setLoadingPendingOrders(true);
        const name = pair.current;
        try {
            const res = await axios.get(`core/orderPending?market=${name}&page=1&limit=100`, {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });

            if (res.data.status == "ok") {
                setCurrentOrders(res.data.data.data);
                setLoadingPendingOrders(false);
            } else {
                setError(true)
            }

            const resSec = await axios.get(`core/orderStopPending?market=${name}&page=1&limit=100`, {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });

            if (res.data.status == "ok") {
                setCurrentStopOrders(resSec.data.data.data);

                setLoadingPendingOrders(false);
            } else {
                setError(true)
            }


        } catch (e) {

            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }
        setLoadingPendingOrders(false);
    }, [])

    const getOrderFinished = useCallback(async () => {
        setLoadingPendingOrders(true);
        const name = pair.current;
        try {
            const res = await axios.get(`core/orderFinished?market=${name}&page=1&limit=100`, {
                headers: {
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            });


            setOrdersHistory(res.data.data.data);
        } catch (error) {
            if ((error?.response?.status ?? 0) === 401) {
                localStorage.removeItem("token");
                addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                // console.log(error);
                setTimeout(() => {
                    window.location = "/login";
                }, 2000);
            }
        }

        setLoadingPendingOrders(false);
    }, [])

    const cancelPendingOrders = useCallback(async (id) => {
        setLoadingPendingOrders(true);
        const name = pair.current;
        const res = await axios.delete(`core/orderPendingCancel?market=${name}&id=${id}`, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token")
            }
        });
        addToast('Your Order Was Canceled', {appearance: "info", autoDismiss: true});
        getBalanceInfo();
        getPendingOrders();
        setLoadingPendingOrders(false);
    }, [])

    const cancelPendingStopOrders = useCallback(async (id) => {
        setLoadingPendingOrders(true);
        const name = pair.current;
        const res = await axios.delete(`core/orderStopPendingCancel?market=${name}&id=${id}`, {
            headers: {
                'Authorization': "Bearer " + localStorage.getItem("token")
            }
        });
        addToast('Your Order Was Canceled', {appearance: "info", autoDismiss: true});
        getBalanceInfo();
        getPendingOrders();
        setLoadingPendingOrders(false);
    }, [])


    const context = useContext(Context);
    const theme = useContext(Theme);


    let totalAmountsAsks = 0;
    let totalAmountsBids = 0;


    return (
        error == true ? <NotAllowed history={history}/>
            :
            <>
                {isLoading || !marketDetail ? (
                    <LoadingSpinner/>
                ) : (
                    <div
                        className={`${context.locale == "fa" && "font-face-farsi"} ${theme.theme == 'dark' ? 'BGDark' : 'BGLight'}`}>

                        <Popover
                            id="coinsPopover"
                            open={isOpenCoinSelectorPopover}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "center",
                            }}
                        >
                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                style={{minHeight: 400, width: 200}}
                            >
                                <List component="nav" aria-label="coins-list-popover">
                                    {marketList.map((item, index) => {
                                        return (
                                            <ListItem
                                                key={index}
                                                button
                                                onClick={() => {
                                                    history.replace("/trade?pair=" + item);
                                                    history.go();
                                                }}
                                                className="pb-0 pt-2"
                                            >
                                                <div className="d-flex justify-content-around">
                                                    <p className="coin-list-name me-2">{item}</p>
                                                </div>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Scrollbars>
                        </Popover>
                        <NavBar isOpen={isOpen}/>


                        <div className="container-fluid  " id="TradeContainer">

                            <Grid

                                container
                                direction="row"
                                justifyContent="space-around"
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={12}
                                    lg={9}
                                    className={"sideBar"}
                                    style={{position: "relative"}}
                                >
                                    {width < 993 && (<div className={`card-body exchange-history curvy `}>
                                        <div className={`card-header  ps-0`}>
                                            <ul className="nav nav-tabs card-header-tabs  me-0"

                                                dir={context.locale == "fa" && "rtl"}>

                                                <a
                                                    className={`  ${
                                                        isOrderBookSelected
                                                            ? `active`
                                                            : "trade-history-btn from-top mt-1"
                                                    } ${context.locale == "fa" && 'BNN'}`}
                                                    aria-current="true"
                                                    onClick={() => setIsOrderBookIsSelected(true)}
                                                >
                                                    <div className>
                                                        <FormattedMessage
                                                            id="Trade.OrderBook"
                                                            defaultMessage="OrderBook"
                                                        />
                                                    </div>
                                                </a>

                                                <a
                                                    className={` ${
                                                        !isOrderBookSelected
                                                            ? `active `
                                                            : "trade-history-btn from-top mt-1"
                                                    } ${context.locale == "fa" && 'BNN'}`}
                                                    onClick={() => setIsOrderBookIsSelected(false)}
                                                >
                                                    <FormattedMessage
                                                        id="Trade.TradeHistory"
                                                        defaultMessage="TradeHistory"
                                                    />
                                                </a>
                                            </ul>
                                            <hr/>
                                        </div>
                                        <ul className="list-group list-group-flush border-0 mb-2 sellingMode">
                                            <li className="mt-2  nav-li d-flex text-capitalize">
                                                <div
                                                    className="d-flex flex-column justify-content-center"
                                                    style={{flex: 1}}
                                                >
                        <span className="">
                          {isOrderBookSelected ? <FormattedMessage
                              id="Trade.OrderBook.Price"
                              defaultMessage="Price"
                          /> : <FormattedMessage
                              id="Trade.OrderBook.Time"
                              defaultMessage="Time"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                            (USDT)
                          </span>
                                                    )}
                                                </div>

                                                <div
                                                    className="d-flex flex-column justify-content-center me-4"
                                                    style={{flex: 1}}
                                                >
                        <span className=" ">
                          {isOrderBookSelected ? <FormattedMessage
                              id="Trade.OrderBook.Amount"
                              defaultMessage="Amount"
                          /> : <FormattedMessage
                              id="Trade.OrderBook.Price"
                              defaultMessage="Price"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                            ({marketDetail.trading_name})
                          </span>
                                                    )}
                                                </div>

                                                <div
                                                    className="d-flex flex-column justify-content-center"
                                                    style={{flex: 1}}
                                                >
                        <span className="">
                          {isOrderBookSelected ? marketDetail.trading_name : <FormattedMessage
                              id="Trade.OrderBook.Volume"
                              defaultMessage="Volume"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                     <FormattedMessage
                         id="Trade.OrderBook.Total"
                         defaultMessage="Total"
                     />
                          </span>
                                                    )}
                                                </div>
                                            </li>
                                            <Scrollbars
                                                className="exchange-market-cryptos"
                                                autoHide
                                                autoHideTimeout={1000}
                                                style={{height: isOrderBookSelected ? "170px" : "400px"}}
                                            >
                                                {isOrderBookSelected
                                                    ? sellOrdersBook.map((item, index) => {
                                                        {
                                                            totalAmountsAsks += parseFloat(item[1])
                                                        }
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="position-relative list-group-item d-flex justify-content-around "
                                                            >
                                                                <div
                                                                    className="trade-books-li position-absolute d-flex"

                                                                />
                                                                <span style={{flex: 1, textAlign: "center"}}
                                                                      className="sellMode fw-bold me-2">
                                                    {parseFloat(item[0])}
                                </span>
                                                                <span
                                                                    className="me-4"
                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                          {Math.floor(parseFloat(item[1]) * floorAmount) / floorAmount}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                  {parseNumber(Math.floor(totalAmountsAsks * floorAmount) / floorAmount ?? 0)}
                                </span>
                                                            </li>
                                                        );
                                                    })
                                                    : tradeHistory.slice(0).reverse().map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`list-group-item d-flex ${item.type == "buy" ? "tradeHistoryListBuyMode" : "tradeHistoryListSellMode"} justify-content-around `}>
                                                                <section>
                                                                    {parseFulDate(item.time).split(" ")[1]}
                                                                </section>
                                                                <section className="fw-bold">
                                                                    {parseNumber(item.price)}
                                                                </section>
                                                                <section
                                                                >
                                                                    {Math.floor(parseFloat(item.amount) * floorAmount) / floorAmount}
                                                                </section>
                                                            </li>
                                                        );
                                                    })}
                                            </Scrollbars>
                                        </ul>

                                        {isOrderBookSelected && (
                                            <div className=" last-exchange-container text-center">
                      <span
                          className="last-exchange-amount"
                          style={{
                              color: lastTsIsBuy
                                  ? "var(--green-color)"
                                  : "var(--red-color)"
                          }}
                      >
                        $ {parseNumber(lastTsCurrentPrice)}
                      </span>
                                            </div>
                                        )}

                                        {isOrderBookSelected && (
                                            <Scrollbars
                                                className="exchange-market-cryptos "
                                                autoHide
                                                autoHideTimeout={1000}
                                                style={{height: isOrderBookSelected ? "160px" : "400px"}}
                                            >
                                                <ul className="list-group list-group-flush mt-2 buyingMode">
                                                    {buyOrdersBook.slice(0).reverse().map((item, index) => {
                                                        {
                                                            totalAmountsBids += parseFloat(item[1])
                                                        }
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="position-relative list-group-item d-flex justify-content-around "
                                                            >
                                                                <div
                                                                    className="trade-books-li position-absolute d-flex"

                                                                />
                                                                <span style={{flex: 1, textAlign: "center"}}
                                                                      className="buyMode fw-bold">
                                                {parseFloat(item[0])}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                                {Math.floor(parseFloat(item[1]) * floorAmount) / floorAmount}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                 {Math.floor(totalAmountsBids * floorAmount) / floorAmount ?? 0}
                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Scrollbars>
                                        )}

                                    </div>)}
                                    <div className=" p-exchange-nav">
                                        <ul className="nav exchange-nav ">
                                            <li
                                                className="nav-item crypto-name d-flex"
                                                aria-describedby="coinsPopover"
                                            >
                      <span className="stared btn ">
                        <RiStarLine className="far fa-bookmark" size={20}/>
                      </span>


                                                {/*<MarketSearchBar size={width / 3} Name={marketDetail.name}/>*/}
                                                <MarketBar Name={marketDetail.name} place={"trade"}/>

                                                <span className="info btn rounded-circle" onClick={() => history.push(`coin_info?pair=${pair.current}`)}>
                                                        <BsInfo className="fas fa-info " size={30}/>
                                                </span>
                                            </li>
                                            <li className="nav-item crypto-price" onClick={() => {
                                                setPrice(lastTsCurrentPrice);
                                                setLimit(lastTsCurrentPrice);
                                            }}>
                                                <p className>$ {parseNumber(lastTsCurrentPrice)}</p>
                                            </li>
                                            <li className="nav-item crypto-24H">
                                                <p className="">
                                                    <FormattedMessage
                                                        id="Trade.24hChange"
                                                        defaultMessage="24hChange"
                                                    />
                                                </p>
                                                <p className={`Percent ${currentPrice > twentyFourOpen ? "gain" : "lose"} `}>
                                                    {(((currentPrice - twentyFourOpen) / twentyFourOpen) * 100).toFixed(2)}%
                                                </p>
                                            </li>
                                            <li className="nav-item highest-24H">
                                                <p className="">
                                                    <FormattedMessage
                                                        id="Trade.24hHighest"
                                                        defaultMessage="24hHighest"
                                                    />
                                                </p>
                                                <p className>{parseNumber(twentyFourHigh)}</p>
                                            </li>
                                            <li className="nav-item lowest-24H">
                                                <p className="">
                                                    <FormattedMessage
                                                        id="Trade.24hLowest"
                                                        defaultMessage="24hLowest"
                                                    />
                                                </p>
                                                <p className>{parseNumber(twentyFourLowest)}</p>
                                            </li>
                                            <li className="nav-item  volume-24H">
                                                <p className="">
                                                    <FormattedMessage
                                                        id="Trade.24hVolume"
                                                        defaultMessage="24hVolume"
                                                    />
                                                </p>
                                                <p className>{parseNumber(twentyFourVolume)}</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="TradingViewWidget-container">
                                        <TradingViewWidget
                                            autosize
                                            isTransparent={true}
                                            theme={theme.themName}
                                            ref={chartRef}
                                            symbol={pair.current}

                                            hide_side_toolbar={width >= 960 ? false : true}
                                        />
                                    </div>

                                    <div className={" OrdersContainer card-body curvy"}>
                                        <div
                                            className={`row Orders  ${width < 1000 && "mb-5"} ${!isLoggedIn && "disabledButton blur-3px"}`}
                                        >

                                            <div className="card-header">
                                                <ul className="nav nav-tabs card-header-tabs"
                                                    dir={context.locale == "fa" && "rtl"}>

                                                    <a
                                                        onClick={() => setIsCurrentOrdersSelected(true)}
                                                        className={`from-top ${
                                                            isCurrentOrdersSelected ? "active" : "Orders-btn "
                                                        } ${context.locale == "fa" && 'BNN'}`}
                                                        aria-current={`${
                                                            isCurrentOrdersSelected ? "true" : "false"
                                                        }`}
                                                    >
                                                        <div className>
                                                            <FormattedMessage
                                                                id="Trade.CurrentOrders"
                                                                defaultMessage="CurrentOrders"
                                                            />
                                                        </div>
                                                    </a>


                                                    <a
                                                        onClick={() => setIsCurrentOrdersSelected(false)}
                                                        aria-current={`${
                                                            !isCurrentOrdersSelected ? "true" : "false"
                                                        }`}
                                                        className={` ${
                                                            !isCurrentOrdersSelected
                                                                ? "active"
                                                                : "Orders-btn "
                                                        }  from-top ${context.locale == "fa" && 'BNN'}`}
                                                    >
                                                        <FormattedMessage
                                                            id="Trade.OrdersHistory"
                                                            defaultMessage="OrdersHistory"
                                                        />
                                                    </a>
                                                </ul>
                                                <hr/>
                                            </div>

                                            <Scrollbars
                                                className="ordersCard  mt-1 mt-3"
                                                autoHide
                                                autoHideTimeout={1000}
                                                style={{height: "275px"}}
                                            >
                                                <div className="table-container  col-xs-12 col-md-12 col-sm-12">
                                                    <div style={{overflowX: "auto"}}>
                                                        <TableContainer>
                                                            <Table style={{overflowX: "auto"}} aria-label="table">
                                                                <TableHead>
                                                                    <TableRow dir={context.locale == "fa" && "rtl"}>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.OrderTime"
                                                                                    defaultMessage="OrderTime"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Type"
                                                                                    defaultMessage="Type"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Side"
                                                                                    defaultMessage="Side"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.SpotTrading.Limit"
                                                                                    defaultMessage="Limit"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Amount"
                                                                                    defaultMessage="Amount"
                                                                                />{" "}
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Unexecuted"
                                                                                    defaultMessage="Unexecuted"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className="table-passage">
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Avg-price"
                                                                                    defaultMessage="Avg-price"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                        <TableCell
                                                                            style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                                            <p className={`table-passage ${!isCurrentOrdersSelected && 'd-none'}`}>
                                                                                <FormattedMessage
                                                                                    id="Trade.Table.Operation"
                                                                                    defaultMessage="Operation"
                                                                                />
                                                                            </p>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody
                                                                    className={` ${isLoadingPendingOrders && "disabledButton"}`}
                                                                    sx={{border: "none"}}>
                                                                    {(isCurrentOrdersSelected
                                                                        ? currentOrders
                                                                        : ordersHistory).map((item, index) => (

                                                                        <TableRow key={item.id}>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {parseFulDate(new Date((item.create_time)))}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {item.order_type}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={`p-0 `}>
                                                                                <p className={`table-passage `}
                                                                                   style={item.type == 'buy' ? {
                                                                                       color: '#2ebd5e',
                                                                                       fontWeight: 700
                                                                                   } : {
                                                                                       color: '#c93532',
                                                                                       fontWeight: 700
                                                                                   }}>
                                                                                    {item.type}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {parseNumber(item.price)}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                <span>{Math.floor(parseFloat(item.amount) * 1000000) / 1000000}
                                                                                    <span
                                                                                        className="ps-1 currency">{item.type == 'sell' ? marketDetail.name.split("USDT")[0] : (marketDetail.name.split(marketDetail.name.split("USDT")[0]))}</span></span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage ">
                                                                                <span
                                                                                    className="">{Math.floor(parseFloat(item.left) * 1000000) / 1000000}
                                                                                    <span
                                                                                        className="ps-1 currency">{marketDetail.name.split("USDT")[0]}</span></span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {`${parseNumber(item.avg_price)}`}<span
                                                                                    className="currency">  USDT</span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"} style={{
                                                                                maxWidth: 200,
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                {isCurrentOrdersSelected && (
                                                                                    <button
                                                                                        style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                                                                        className="cancel-button "
                                                                                        onClick={() => cancelPendingOrders(item.id)}>
                                                                                        <FormattedMessage
                                                                                            id="Trade.Table.CancelOrder"
                                                                                            defaultMessage="CancelOrder"
                                                                                        />
                                                                                    </button>
                                                                                )}
                                                                            </TableCell>
                                                                            {isLoadingPendingOrders &&
                                                                                <div
                                                                                    className="btn-group OrdersTable-loading">
                                                                                    <CircularProgress/></div>}
                                                                        </TableRow>
                                                                    ))}

                                                                    {(isCurrentOrdersSelected
                                                                        ? currentStopOrders : Fake).map((item, index) => (

                                                                        <TableRow key={item.id}>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {parseFulDate(new Date((item.create_time)))}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    Stop-Limit
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={`p-0 `}>
                                                                                <p className={`table-passage `}
                                                                                   style={item.type == 'buy' ? {
                                                                                       color: '#2ebd5e',
                                                                                       fontWeight: 700
                                                                                   } : {
                                                                                       color: '#c93532',
                                                                                       fontWeight: 700
                                                                                   }}>
                                                                                    {item.type}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {parseNumber(item.price)}
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                <span>{Math.floor(parseFloat(item.amount) * 1000000) / 1000000}
                                                                                    <span
                                                                                        className="ps-1 currency">{item.type == 'sell' ? marketDetail.name.split("USDT")[0] : (marketDetail.name.split(marketDetail.name.split("USDT")[0]))}</span></span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage ">
                                                                                    <span>------</span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"}>
                                                                                <p className="table-passage">
                                                                                    {`${parseNumber(item.avg_price)}`}<span
                                                                                    className="currency">  USDT</span>
                                                                                </p>
                                                                            </TableCell>
                                                                            <TableCell className={"p-0"} style={{
                                                                                maxWidth: 200,
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                {isCurrentOrdersSelected && (
                                                                                    <button
                                                                                        style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                                                                        className="cancel-button "
                                                                                        onClick={() => cancelPendingStopOrders(item.order_id)}>
                                                                                        <FormattedMessage
                                                                                            id="Trade.Table.CancelOrder"
                                                                                            defaultMessage="CancelOrder"
                                                                                        />
                                                                                    </button>
                                                                                )}
                                                                            </TableCell>
                                                                            {isLoadingPendingOrders &&
                                                                                <div
                                                                                    className="btn-group OrdersTable-loading">
                                                                                    <CircularProgress/></div>}
                                                                        </TableRow>
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                </div>
                                            </Scrollbars>

                                        </div>

                                    </div>

                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    lg={3}
                                >
                                    <div className={`exchange-form card rounded ${!isLoggedIn && "blur-3px"}`}>

                                        <div className="card-body curvy">
                                            <div className=" form-card-container ">
                                                <div className={`card-container ${
                                                    !isLoggedIn && "disabledButton"
                                                }`}
                                                >
                                                    <div
                                                        className={`d-flex buy-sell-gp ${isBuyMode ? 'buySwitch' : 'sellSwitch'}`}
                                                        dir={context.locale == "fa" && "rtl"}>
                                                        <div
                                                            className={`${context.locale == "fa" ? "switch-button-sec" : "switch-button"}`}>
                                                            <input className="switch-button-checkbox" type="checkbox"
                                                                   onChange={() => setIsBuyMode(!isBuyMode)}></input>
                                                            <label className="switch-button-label" htmlFor=""><span
                                                                className="switch-button-label-span">
                                                              <FormattedMessage
                                                                  id="Trade.SpotTrading.Buy"
                                                                  defaultMessage="Buy"
                                                              />
                                                </span></label>
                                                        </div>

                                                    </div>
                                                    <div className="d-flex pt-2" dir={context.locale == "fa" && "rtl"}>
                                                        <button
                                                            onClick={() => {
                                                                setOrderModeIndex(0);
                                                                setPrice(0);
                                                                setAmount(0);
                                                                setSliderValue(0)
                                                            }}
                                                            className={`${
                                                                orderModeIndex == 0
                                                                    ? "text-button-selected"
                                                                    : "text-button"
                                                            }`}
                                                        >
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.Limit"
                                                                defaultMessage="Limit"
                                                            />
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setOrderModeIndex(1);
                                                                setPrice(marketPrice);
                                                                setAmount(0);
                                                                setSliderValue(0)
                                                            }}
                                                            className={`${
                                                                orderModeIndex == 1
                                                                    ? "text-button-selected"
                                                                    : "text-button"
                                                            }`}
                                                        >
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.Market"
                                                                defaultMessage="Market"
                                                            />
                                                        </button>
                                                        <button
                                                            onClick={() => {
                                                                setOrderModeIndex(2);
                                                                setPrice(0);
                                                                setAmount(0);
                                                                setSliderValue(0)
                                                            }}
                                                            className={`${
                                                                orderModeIndex == 2
                                                                    ? "text-button-selected"
                                                                    : "text-button"
                                                            }`}
                                                        >
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.Stop-limit"
                                                                defaultMessage="Stop-limit"
                                                            />
                                                        </button>
                                                    </div>
                                                    <div className="pt-3 d-flex justify-content-between pe-1 ps-1"
                                                         dir={context.locale == "fa" && "rtl"}>
                                                        <p style={{fontSize: 12, color: "#737373"}} dir={"ltr"}>
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.AVBL"
                                                                defaultMessage="avbl"
                                                            />
                                                        </p>
                                                        {isLoadingBalances ? (
                                                            <CircularProgress size={16}/>
                                                        ) : (
                                                            <p style={{fontSize: 15, color: "#737373"}} dir={"ltr"}>
                                                                {isBuyMode
                                                                    ? availableUSDT + " USDT"
                                                                    : availableCoin + " " + marketDetail.trading_name}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <form
                                                        onSubmit={orderModeIndex == 0 ? addLimitOrderHandler : orderModeIndex == 1 ? addMarketOrderHandler : orderModeIndex == 2 && addStopLimitOrderHandler}
                                                        style={{position: "relative"}}>

                                                        {orderModeIndex != 2 && (
                                                            <div
                                                                className={`form-floating exchange-form-input  pe-4 ps-4 ${orderModeIndex == 1 && 'disabledButton disabledColor'}`}
                                                                dir={context.locale == "fa" && "rtl"}>
                                                                <input
                                                                    className="w-100 form-control "
                                                                    id="price-field"
                                                                    dir={context.locale == "fa" && "rtl"}
                                                                    placeholder=""
                                                                    type={"text"}
                                                                    pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                                    value={orderModeIndex != 1 ? price : "Act at the market price"}
                                                                    disabled={orderModeIndex == 1}
                                                                    onChange={(e) => {
                                                                        if (orderModeIndex != 1) {
                                                                            setPrice(e.target.value);
                                                                        }
                                                                    }}
                                                                />
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="Trade.SpotTrading.price"
                                                                        defaultMessage="price"
                                                                    />
                                                                </label>
                                                            </div>
                                                        )}
                                                        {orderModeIndex == 2 && (
                                                            <div
                                                                className="form-floating exchange-form-input  pe-4 ps-4 "
                                                                dir={context.locale == "fa" && "rtl"}>
                                                                <input
                                                                    className="w-100  form-control"
                                                                    id="stop-field"
                                                                    dir={context.locale == "fa" && "rtl"}
                                                                    placeholder=""
                                                                    type="text"
                                                                    pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                                    value={stop}
                                                                    onChange={(e) => {
                                                                        setStop(e.target.value);
                                                                    }}
                                                                />
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="Trade.SpotTrading.Stop"
                                                                        defaultMessage="Stop"
                                                                    />
                                                                </label>
                                                            </div>
                                                        )}
                                                        {orderModeIndex == 2 && (
                                                            <div
                                                                className="form-floating mt-3 exchange-form-input  pe-4 ps-4 "
                                                                dir={context.locale == "fa" && "rtl"}>
                                                                <input
                                                                    className="w-100 form-control"
                                                                    id="limit-field"
                                                                    dir={context.locale == "fa" && "rtl"}
                                                                    placeholder=""
                                                                    type="text"
                                                                    pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                                    value={limit}
                                                                    onChange={(e) => {
                                                                        setLimit(e.target.value);
                                                                        setPrice(e.target.value);
                                                                    }}
                                                                />
                                                                <label htmlFor="">
                                                                    <FormattedMessage
                                                                        id="Trade.SpotTrading.price"
                                                                        defaultMessage="Price"
                                                                    />
                                                                </label>
                                                            </div>
                                                        )}
                                                        <div
                                                            className="form-floating mt-3 exchange-form-input  pe-4 ps-4 "
                                                            dir={context.locale == "fa" && "rtl"}>
                                                            <input
                                                                className=" w-100  form-control"
                                                                id="amount-field"
                                                                dir={context.locale == "fa" && "rtl"}
                                                                label={context.locale == "fa" ? "حساب" : "Account"}
                                                                type="text"
                                                                pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                                value={amount}
                                                                onChange={(e) => setAmount(e.target.value)}
                                                            />
                                                            <label htmlFor="">
                                                                <FormattedMessage
                                                                    id="Trade.SpotTrading.amount"
                                                                    defaultMessage="amount"
                                                                />
                                                            </label>
                                                        </div>
                                                        <PrettoSlider
                                                            className={`pt-4 ${!price > 0 && 'disabledButton'}`}
                                                            style={{
                                                                width: "85%",
                                                                paddingLeft: 8,
                                                                marginRight: 6,
                                                                color: '#a3a3a3',
                                                            }}
                                                            ValueLabelComponent={SliderValueLabelComponent}
                                                            marks={marks}
                                                            valueLabelDisplay="auto"
                                                            value={sliderValue}
                                                            aria-label="slider"
                                                            defaultValue={0}
                                                            onChange={handleSliderChange}

                                                        />

                                                        <div className="mt-3">
                                                            <PlaceOrderBtn/>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                            {!isLoggedIn ? (
                                                <div className="btn-group Register-Login" role="group">
                                                    <button
                                                        type="button"
                                                        onClick={() => history.push("login")}
                                                        className="btn  Register-Login-btn "
                                                    >
                                                        <FormattedMessage
                                                            id="Trade.SpotTrading.RegisterOrLogin"
                                                            defaultMessage="RegisterOrLogin"
                                                        />
                                                    </button>

                                                </div>
                                            ) : addingOrder && (
                                                <div className="btn-group exchange-form-loading">
                                                    <CircularProgress/>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    {width > 993 && (<div className={`card-body exchange-history curvy `}>
                                        <div className={`card-header  ps-0`}>
                                            <ul className="nav nav-tabs card-header-tabs  me-0"
                                                dir={context.locale == "fa" && "rtl"}>

                                                <a
                                                    className={`  ${
                                                        isOrderBookSelected
                                                            ? `active`
                                                            : "trade-history-btn from-top mt-1"
                                                    } ${context.locale == "fa" && 'BNN'}`}
                                                    aria-current="true"
                                                    onClick={() => setIsOrderBookIsSelected(true)}
                                                >
                                                    <div className>
                                                        <FormattedMessage
                                                            id="Trade.OrderBook"
                                                            defaultMessage="OrderBook"
                                                        />
                                                    </div>
                                                </a>

                                                <a
                                                    className={` ${
                                                        !isOrderBookSelected
                                                            ? `active `
                                                            : "trade-history-btn from-top mt-1"
                                                    } ${context.locale == "fa" && 'BNN'}`}
                                                    onClick={() => setIsOrderBookIsSelected(false)}
                                                >
                                                    <FormattedMessage
                                                        id="Trade.TradeHistory"
                                                        defaultMessage="TradeHistory"
                                                    />
                                                </a>
                                            </ul>
                                            <hr/>
                                        </div>
                                        <ul className="list-group list-group-flush border-0 mb-2 sellingMode">
                                            <li className="mt-2  nav-li d-flex text-capitalize">
                                                <div
                                                    className="d-flex flex-column justify-content-center"
                                                    style={{flex: 1}}
                                                >
                        <span className="">
                          {isOrderBookSelected ? <FormattedMessage
                              id="Trade.OrderBook.Price"
                              defaultMessage="Price"
                          /> : <FormattedMessage
                              id="Trade.OrderBook.Time"
                              defaultMessage="Time"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                            (USDT)
                          </span>
                                                    )}
                                                </div>

                                                <div
                                                    className="d-flex flex-column justify-content-center me-4"
                                                    style={{flex: 1}}
                                                >
                        <span className=" ">
                          {isOrderBookSelected ? <FormattedMessage
                              id="Trade.OrderBook.Amount"
                              defaultMessage="Amount"
                          /> : <FormattedMessage
                              id="Trade.OrderBook.Price"
                              defaultMessage="Price"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                            ({marketDetail.trading_name})
                          </span>
                                                    )}
                                                </div>

                                                <div
                                                    className="d-flex flex-column justify-content-center"
                                                    style={{flex: 1}}
                                                >
                        <span className="">
                          {isOrderBookSelected ? marketDetail.trading_name : <FormattedMessage
                              id="Trade.OrderBook.Volume"
                              defaultMessage="Volume"
                          />}
                        </span>
                                                    {isOrderBookSelected && (
                                                        <span
                                                            className=""
                                                            style={{fontSize: 10}}
                                                        >
                     <FormattedMessage
                         id="Trade.OrderBook.Total"
                         defaultMessage="Total"
                     />
                          </span>
                                                    )}
                                                </div>
                                            </li>
                                            <Scrollbars
                                                className="exchange-market-cryptos"
                                                autoHide
                                                autoHideTimeout={1000}
                                                style={{height: isOrderBookSelected ? "170px" : "400px"}}
                                            >
                                                {isOrderBookSelected
                                                    ? sellOrdersBook.map((item, index) => {
                                                        {
                                                            totalAmountsAsks += parseFloat(item[1])
                                                        }
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="position-relative list-group-item d-flex justify-content-around "
                                                            >
                                                                <div
                                                                    className="trade-books-li position-absolute d-flex"

                                                                />
                                                                <span style={{flex: 1, textAlign: "center"}}
                                                                      className="sellMode fw-bold me-2">
                                                    {parseFloat(item[0])}
                                </span>
                                                                <span
                                                                    className="me-4"
                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                          {Math.floor(parseFloat(item[1]) * floorAmount) / floorAmount}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                  {parseNumber(Math.floor(totalAmountsAsks * floorAmount) / floorAmount ?? 0)}
                                </span>
                                                            </li>
                                                        );
                                                    })
                                                    : tradeHistory.slice(0).reverse().map((item, index) => {
                                                        return (
                                                            <li
                                                                key={index}
                                                                className={`list-group-item d-flex ${item.type == "buy" ? "tradeHistoryListBuyMode" : "tradeHistoryListSellMode"} justify-content-around `}>
                                                                <section>
                                                                    {parseFulDate(item.time).split(" ")[1]}
                                                                </section>
                                                                <section className="fw-bold">
                                                                    {parseNumber(item.price)}
                                                                </section>
                                                                <section
                                                                >
                                                                    {Math.floor(parseFloat(item.amount) * floorAmount) / floorAmount}
                                                                </section>
                                                            </li>
                                                        );
                                                    })}
                                            </Scrollbars>
                                        </ul>

                                        {isOrderBookSelected && (
                                            <div className=" last-exchange-container text-center" onClick={() => {
                                                setPrice(lastTsCurrentPrice);
                                                setLimit(lastTsCurrentPrice);
                                            }}>
                      <span
                          className="last-exchange-amount"
                          style={{
                              color: lastTsIsBuy
                                  ? "var(--green-color)"
                                  : "var(--red-color)"
                          }}
                      >
                        $ {parseNumber(lastTsCurrentPrice)}
                      </span>
                                            </div>
                                        )}

                                        {isOrderBookSelected && (
                                            <Scrollbars
                                                className="exchange-market-cryptos "
                                                autoHide
                                                autoHideTimeout={1000}
                                                style={{height: isOrderBookSelected ? "170px" : "400px"}}
                                            >
                                                <ul className="list-group list-group-flush mt-2 buyingMode">
                                                    {buyOrdersBook.slice(0).reverse().map((item, index) => {
                                                        {
                                                            totalAmountsBids += parseFloat(item[1])
                                                        }
                                                        return (
                                                            <li
                                                                key={index}
                                                                className="position-relative list-group-item d-flex justify-content-around "
                                                            >
                                                                <div
                                                                    className="trade-books-li position-absolute d-flex"

                                                                />
                                                                <span style={{flex: 1, textAlign: "center"}}
                                                                      className="buyMode fw-bold">
                                                {parseFloat(item[0])}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                                {Math.floor(parseFloat(item[1]) * floorAmount) / floorAmount}
                                </span>
                                                                <span

                                                                    style={{flex: 1, textAlign: "center"}}
                                                                >
                                 {Math.floor(totalAmountsBids * floorAmount) / floorAmount ?? 0}
                                </span>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Scrollbars>
                                        )}

                                    </div>)}

                                </Grid>


                            </Grid>

                        </div>
                        <>
                            <div
                                className={`btn-group  Buy-sell-responsive-btn fixed-bottom ${isOpen ? 'd-none' : ''}  ${context.locale == "fa" && "font-face-farsi"}`}
                                role="group">
                                <button
                                    onClick={() => {
                                        setOpen(true)
                                        setIsBuyMode(false)
                                    }}
                                    type="button"
                                    className=" me-1  Sell-btn"
                                >
                                    <FormattedMessage
                                        id="Trade.SpotTrading.Sell"
                                        defaultMessage="Sell"
                                    />
                                </button>
                                <button
                                    onClick={() => {
                                        setOpen(true)
                                        setIsBuyMode(true)
                                    }}
                                    type="button"
                                    className=" ms-1  Buy-btn "
                                >
                                    <FormattedMessage
                                        id="Trade.SpotTrading.Buy"
                                        defaultMessage="Buy"
                                    />
                                </button>
                            </div>

                            <BottomModal isOpen={isOpen} onDismiss={() => setOpen(false)}>
                                <Scrollbars
                                    autoHide
                                    autoHideTimeout={1000}
                                    style={{minHeight: 350, maxHeight: "fit-content"}}
                                >
                                    <div className="card-body ">
                                        <div
                                            className={`card-container ${
                                                !isLoggedIn && "disabledButton"
                                            }`}
                                        >
                                            <div
                                                className={`d-flex buy-sell-gp ${isBuyMode ? 'buySwitch' : 'sellSwitch'}`}
                                                dir={context.locale == "fa" && "rtl"}>

                                                <div
                                                    className={`${context.locale == "fa" ? "switch-button-sec" : "switch-button"}`}>
                                                    <input className="switch-button-checkbox" type="checkbox"
                                                           onChange={() => setIsBuyMode(!isBuyMode)}
                                                           checked={!isBuyMode}></input>
                                                    <label className="switch-button-label" htmlFor=""><span
                                                        className="switch-button-label-span">
                                                              <FormattedMessage
                                                                  id="Trade.SpotTrading.Buy"
                                                                  defaultMessage="Buy"
                                                              />
                                                </span></label>
                                                </div>

                                            </div>
                                            <div className="d-flex pt-1" dir={context.locale == "fa" && "rtl"}>
                                                <button
                                                    onClick={() => {
                                                        setOrderModeIndex(0);
                                                    }}
                                                    className={`${
                                                        orderModeIndex == 0
                                                            ? "text-button-selected"
                                                            : "text-button"
                                                    }`}
                                                >
                                                    <FormattedMessage
                                                        id="Trade.SpotTrading.Limit"
                                                        defaultMessage="Limit"
                                                    />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setOrderModeIndex(1);
                                                        setPrice(marketPrice);
                                                    }}
                                                    className={`${
                                                        orderModeIndex == 1
                                                            ? "text-button-selected"
                                                            : "text-button"
                                                    }`}
                                                >
                                                    <FormattedMessage
                                                        id="Trade.SpotTrading.Market"
                                                        defaultMessage="Market"
                                                    />
                                                </button>
                                                <button
                                                    onClick={() => setOrderModeIndex(2)}
                                                    className={`${
                                                        orderModeIndex == 2
                                                            ? "text-button-selected"
                                                            : "text-button"
                                                    }`}
                                                >
                                                    <FormattedMessage
                                                        id="Trade.SpotTrading.Stop-limit"
                                                        defaultMessage="Stop-limit"
                                                    />
                                                </button>
                                            </div>
                                            <div className="pt-3 d-flex justify-content-between pe-1 ps-1"
                                                 dir={context.locale == "fa" && "rtl"}>
                                                <p style={{fontSize: 12, color: "#737373"}}>
                                                    <FormattedMessage
                                                        id="Trade.SpotTrading.AVBL"
                                                        defaultMessage="avbl"
                                                    />
                                                </p>
                                                {isLoadingBalances ? (
                                                    <CircularProgress size={16}/>
                                                ) : (
                                                    <p style={{fontSize: 15, color: "#737373"}}>
                                                        {isBuyMode ? availableUSDT + " USDT" : availableCoin + " " + marketDetail.trading_name}
                                                    </p>
                                                )}
                                            </div>
                                            <form
                                                onSubmit={orderModeIndex == 0 ? addLimitOrderHandler : orderModeIndex == 1 ? addMarketOrderHandler : orderModeIndex == 2 && addStopLimitOrderHandler}
                                                style={{height: "100%", position: "relative"}}>

                                                {orderModeIndex != 2 && (
                                                    <div
                                                        className={`form-floating exchange-form-input  pe-4 ps-4 ${orderModeIndex == 1 && 'disabledButton disabledColor'}`}
                                                        dir={context.locale == "fa" && "rtl"}>
                                                        <input
                                                            className="w-100 form-control "
                                                            id="price-field"
                                                            dir={context.locale == "fa" && "rtl"}
                                                            placeholder=""
                                                            type={"text"}
                                                            pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                            value={orderModeIndex != 1 ? price : "Act at the market price"}
                                                            disabled={orderModeIndex == 1}
                                                            onChange={(e) => {
                                                                if (orderModeIndex != 1) {
                                                                    setPrice(e.target.value);
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor="">
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.price"
                                                                defaultMessage="price"
                                                            />
                                                        </label>
                                                    </div>
                                                )}

                                                {orderModeIndex == 2 && (
                                                    <div
                                                        className={`form-floating exchange-form-input  pe-4 ps-4 `}
                                                        dir={context.locale == "fa" && "rtl"}>
                                                        <input
                                                            className="w-100  form-control"
                                                            id="stop-field"
                                                            dir={context.locale == "fa" && "rtl"}
                                                            placeholder=""
                                                            type="text"
                                                            pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                            value={stop}
                                                            onChange={(e) => {
                                                                setStop(e.target.value);
                                                                setPrice(e.target.value);
                                                            }}
                                                        />
                                                        <label htmlFor="">
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.Stop"
                                                                defaultMessage="Stop"
                                                            />
                                                        </label>
                                                    </div>)
                                                }


                                                {orderModeIndex == 2 && (
                                                    <div
                                                        className="form-floating mt-3 exchange-form-input  pe-4 ps-4 "
                                                        dir={context.locale == "fa" && "rtl"}>
                                                        <input
                                                            className="w-100   form-control"
                                                            id="limit-field"
                                                            dir={context.locale == "fa" && "rtl"}
                                                            placeholder=""
                                                            type="text"
                                                            pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                            value={limit}
                                                            onChange={(e) => {
                                                                setLimit(e.target.value);
                                                            }}
                                                        />
                                                        <label htmlFor="">
                                                            <FormattedMessage
                                                                id="Trade.SpotTrading.limit"
                                                                defaultMessage="limit"
                                                            />
                                                        </label>
                                                    </div>
                                                )}


                                                <div
                                                    className="form-floating mt-3 exchange-form-input  pe-4 ps-4 "
                                                    dir={context.locale == "fa" && "rtl"}>
                                                    <input
                                                        className=" w-100  form-control"
                                                        id="amount-field"
                                                        dir={context.locale == "fa" && "rtl"}
                                                        label={context.locale == "fa" ? "حساب" : "Account"}
                                                        type="text"
                                                        pattern="[\s0-9]+([\.,][0-9\s]+)?" step="0.0000001"
                                                        value={amount}
                                                        onChange={(e) => setAmount(e.target.value)}
                                                    />

                                                    <label htmlFor="">
                                                        <FormattedMessage
                                                            id="Trade.SpotTrading.amount"
                                                            defaultMessage="amount"
                                                        />
                                                    </label>
                                                </div>

                                                <div className='percent-select mt-3'>
                                                    <FormControl component="fieldset">
                                                        <label>Percent</label>
                                                        <RadioGroup row aria-label="percent"
                                                                    name="row-radio-buttons-group">
                                                            <FormControlLabel
                                                                value={'25'}
                                                                control={<Radio
                                                                    style={{color: '#6699ff'}}/>}
                                                                label="25%"
                                                                onClick={() => {
                                                                    setAmount(isBuyMode ? ((availableUSDT * 25) / 100) : ((availableCoin * 25) / 100))
                                                                }}
                                                            />
                                                            <FormControlLabel
                                                                value={'50'}
                                                                onClick={() => {
                                                                    setAmount(isBuyMode ? ((availableUSDT * 50) / 100) : ((availableCoin * 50) / 100))
                                                                }}
                                                                control={<Radio
                                                                    style={{color: '#6699ff'}}/>}
                                                                label="50%"/>
                                                            <FormControlLabel
                                                                value={'75'}
                                                                onClick={() => {
                                                                    setAmount(isBuyMode ? ((availableUSDT * 75) / 100) : ((availableCoin * 75) / 100))
                                                                }}
                                                                control={<Radio
                                                                    style={{color: '#6699ff'}}/>}
                                                                label="75%"/>
                                                            <FormControlLabel
                                                                value={'100'}
                                                                onClick={() => {
                                                                    setAmount(isBuyMode ? (availableUSDT) : (availableCoin))
                                                                }}
                                                                control={<Radio style={{color: '#6699ff'}}/>}
                                                                label="100%"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </div>

                                                <button
                                                    className={`button ${isBuyMode ? 'modalButtonBuyMode' : 'modalButtonSellMode'} `}>
                                                    <FormattedMessage
                                                        id="Trade.SpotTrading.PlaceOrder"
                                                        defaultMessage="PlaceOrder"
                                                    />
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </Scrollbars>

                                {!isLoggedIn ? (
                                    <div
                                        className="btn-group Register-Login"
                                        role="group"
                                    >
                                        <button
                                            type="button"
                                            onClick={() => history.push("login")}
                                            className="btn  Register-Login-btn "
                                        >
                                            <FormattedMessage
                                                id="Trade.SpotTrading.RegisterOrLogin"
                                                defaultMessage="RegisterOrLogin"
                                            />
                                        </button>
                                    </div>
                                ) : addingOrder && (
                                    <div className="btn-group exchange-form-loading">
                                        <CircularProgress/>

                                    </div>
                                )}

                            </BottomModal>
                        </>

                        <Footer/>

                    </div>
                )}
            </>
    );
}
