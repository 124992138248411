import React from 'react';

const SocialMediaEmail = () => {
    return (
            <a className="list-group-item  border-0 SocialMediaEmail" target="_blank" id="list-link"
               style={{width:40 , height:40}}
               href={'https://blog.sigloyex.com/'}>
            <svg viewBox="0 0 200 200">
                <defs>
                    <style>
                        {".SocialMediaEmailSt0{fill:none;stroke-linecap:round;stroke-miterlimit:10;stroke-width:10px;}"}
                    </style>
                </defs>
                <path className="SocialMediaEmailSt0"
                      d="M163.25,150.5H182.8a14.08,14.08,0,0,0,14.09-14.08V22.58A14.09,14.09,0,0,0,182.8,8.5H17.2A14.09,14.09,0,0,0,3.11,22.58V136.42A14.08,14.08,0,0,0,17.2,150.5h94.49l21.47,39.91a2.05,2.05,0,0,0,3.51.19L154,165.29"/>
                <line className="SocialMediaEmailSt0" x1="4.59" y1="44" x2="173.22" y2="44"/>
                <rect className="SocialMediaEmailSt0" x="23.55" y="66.19" width="32.82" height="60.65" rx="1.89"/>
                <line className="SocialMediaEmailSt0" x1="81.51" y1="122.4" x2="124.41" y2="122.4"/>
                <line className="SocialMediaEmailSt0" x1="81.51" y1="73.58" x2="171.74" y2="73.58"/>
                <line className="SocialMediaEmailSt0" x1="81.51" y1="97.25" x2="159.91" y2="97.25"/>
            </svg>


            </a>
    );
};

export default SocialMediaEmail;
