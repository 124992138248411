import React, {useContext, useState} from 'react';
import '../css/bootstrap.css';
import '../css/footer.css';
import Trail from "./Trail";
import {Context} from "../../translation/Wrapper";
import {Link, useHistory} from "react-router-dom";
import {parseFulDate} from "../../Extentions";
import {FormattedMessage} from "react-intl";
import Coin360 from "../IconsClasses/Coin360";
import CoinMarketCap from "../IconsClasses/CoinMarketCap";
import Icobench from "../IconsClasses/Icobench";
import CoinDesk from "../IconsClasses/CoinDesk";
import TradingView from "../IconsClasses/TradingView";
import CoinTelegraph from "../IconsClasses/CoinTelegraph";
import BlockChain from "../IconsClasses/BlockChain";
import Icodrops from "../IconsClasses/Icodrops";
import SocialMediaEmail from "../IconsClasses/SocialMediaEmail";
import SocialMediaTelegram from "../IconsClasses/SocialMediaTelegram";
import SocialMediaInstagram from "../IconsClasses/SocialMediaInstagram";
import SocialMediaTwitter from "../IconsClasses/SocialMediaTwitter";

const Footer = (props) => {
    const history = useHistory();
    const [windowSize, setWindowSize] = useState();

    setInterval(() => {
        setWindowSize(window.innerWidth);
    }, 1000);

    const staticModalStyles = {
        borderRadius: '1.25rem',
        padding: '50px 10px'
    };


    const [open, setOpen] = useState(window.innerWidth <= 800 ? true : true);
    const [open2, setOpen2] = useState(window.innerWidth <= 800 ? false : true);
    const [open3, setOpen3] = useState(window.innerWidth <= 800 ? false : true);
    const [open4, setOpen4] = useState(window.innerWidth <= 800 ? false : true);


    const context = useContext(Context);

    return (
        <div
            className={`col-xs-12 col-md-12 col-sm-12   container-fluid footer  ${context.locale == "fa" && "font-face-farsi"} ${props.coloring}`}>

            <div className="row ">
                <div className="col-xs-12 col-lg-3 col-sm-12 col-md-4 footer-divs text-center  text-capitalize">
                    <li className="list-group-item border-0"
                        onClick={() => windowSize <= 800 && setOpen3(state => !state)}>
                        <span className={` dd-button  ${context.locale == "fa" && "font-face-farsi"}`}>
                                        <FormattedMessage
                                            id="Footer.AboutUs.Reference"
                                            defaultMessage="Reference"
                                        />
                        </span>

                        <Trail open={open3} height={60}>

                            <ul className="list-group list-group-horizontal justify-content-center icons ">

                                    <Coin360/>

                                    <CoinMarketCap/>

                                    <Icobench/>

                                    <CoinDesk/>
                            </ul>


                            <ul className="list-group list-group-horizontal justify-content-center icons  ">

                                    <TradingView/>


                                    <CoinTelegraph/>

                                    <Icodrops/>

                                    <BlockChain/>

                            </ul>
                        </Trail>

                    </li>

                </div>
                <div className="col-xs-12 col-lg-2 col-sm-12 col-md-4  footer-divs text-center text-capitalize">
                    <li className="list-group-item border-0 ">
                        <span className={` dd-button ${context.locale == "fa" && "font-face-farsi"}`}
                              onClick={() => windowSize <= 800 && setOpen2(state => !state)}>
                                       <FormattedMessage
                                           id="Footer.AboutUs.Condition"
                                           defaultMessage="Condition"
                                       />
                        </span>
                        <ul className="list-group ">
                            <Trail open={open2}>

                                <a href="#"
                                   className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"}   `}
                                   id="list-link"
                                   onClick={() => history.push("AboutUs")}>
                                    <FormattedMessage
                                        id="Footer.Learn.AboutUs.AboutSigloy"
                                        defaultMessage="Footer.Learn.AboutUs.AboutSigloy"
                                    />
                                </a>

                                <a href="#"
                                   className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"}   `}
                                   id="list-link"
                                   onClick={() => history.push("feesStandard")}>
                                    <FormattedMessage
                                        id="Footer.AboutUs.Condition.FeesStandard"
                                        defaultMessage="FeesStandard"
                                    />
                                </a>
                                <a href="#"
                                   className={`${context.locale == "fa" && "BNN"}  ${context.locale == "fa" && "font-face-farsi"}  `}
                                   id="list-link"
                                   onClick={() => history.push("term_policies")}>
                                    <FormattedMessage
                                        id="Footer.AboutUs.Condition.PrivacyPolicy"
                                        defaultMessage="PrivacyPolicy"
                                    />
                                </a>
                                <a href="#"
                                   className={`${context.locale == "fa" && "BNN"}  ${context.locale == "fa" && "font-face-farsi"}  `}
                                   id="list-link"
                                   onClick={() => history.push("accountSecurity")}>
                                    <FormattedMessage
                                        id="Footer.AboutUs.Condition.AccountSecurity"
                                        defaultMessage="AccountSecurity"
                                    />
                                </a>
                                <a href="#"
                                   className={`${context.locale == "fa" && "BNN"}  ${context.locale == "fa" && "font-face-farsi"}  `}
                                   id="list-link"
                                   onClick={() => history.push("authLevel")}>
                                    <FormattedMessage
                                        id="Footer.AboutUs.Condition.AuthenticationLevel"
                                        defaultMessage="AuthenticationLevel"
                                    />
                                </a>
                            </Trail>
                        </ul>
                    </li>

                </div>

                <div className="col-xs-12 col-lg-2 col-sm-12 col-md-4  footer-divs text-center text-capitalize ">
                    <li className="list-group-item border-0 "
                    >
                        <span className={` dd-button ${context.locale == "fa" && "font-face-farsi"}`}
                              onClick={() => windowSize <= 800 && setOpen4(state => !state)}>
                                 <FormattedMessage
                                     id="Footer.Learn.Support"
                                     defaultMessage="Footer.Learn.Support"
                                 />
                        </span>
                        <ul className="list-group ">
                            <Trail open={open4} >
                                <a target="_blank"
                                   href={'https://doc.sigloyex.com/redoc'}
                                   className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"} `}
                                   id="list-link">
                                    <FormattedMessage
                                        id="Footer.Learn.Support.APIDocumentation"
                                        defaultMessage="APIDocumentation"
                                    />
                                </a>
                                <a  onClick={() => history.push("ContactUs")}
                                   className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"}  `}
                                   id="list-link">
                                    <FormattedMessage
                                        id="Footer.Learn.Support.AboutUs"
                                        defaultMessage="AboutUs"
                                    />
                                </a>
                                <a
                                    href="#"
                                    className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"} `}
                                    id="list-link"
                                    onClick={() => history.push("Feedback")}
                                >
                                    <FormattedMessage
                                        id="Footer.Learn.Support.Survey"
                                        defaultMessage="Footer.Learn.Support.Survey"
                                    />
                                </a>
                                <a href="#" onClick={() => history.push("Questions")}
                                   className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"}  `}
                                   id="list-link">
                                    <FormattedMessage
                                        id="Footer.Learn.Support.CommonQuestions"
                                        defaultMessage="CommonQuestions"
                                    />
                                </a>

                            </Trail>
                        </ul>
                    </li>

                </div>
                <div className="col-xs-12 col-lg-3 col-sm-12 col-md-12  footer-divs footer-divs-Community">
                    <ul className="list-group text-center text-capitalize">
                        <li className={`${context.locale == "fa" && "BNN"} ${context.locale == "fa" && "font-face-farsi"} list-group-item  border-0  dd-button `}>
                            <FormattedMessage
                                id="Footer.Learn.Community"
                                defaultMessage="Community"
                            />
                        </li>
                        <ul className="list-group list-group-horizontal justify-content-center icons p-3">

                                <SocialMediaTelegram/>


                                <SocialMediaInstagram/>


                                <SocialMediaTwitter/>


                                <SocialMediaEmail/>
                        </ul>
                        <p className="" dir={context.locale == "fa" && "rtl"}>© {new Date().getFullYear()}
                            <FormattedMessage
                                id="Footer.Learn.Community.Rights"
                                defaultMessage="Footer.Learn.Community.Rights"
                            />
                            <FormattedMessage
                                id="Footer.Learn.Community.RightsSec"
                                defaultMessage="Footer.Learn.Community.RightsSec"
                            />
                        </p>
                    </ul>
                    <br/>
                    {/*                <p class="email-for-info"> Stay up to date </p>*/}
                    {/*                <label class="text-start"> Enter your email address below</label>*/}
                    {/*                <div class="input-group mb-3 email-for-info-input mt-4">*/}
                    {/*                    <input type="text" class="form-control " placeholder="*****@gmail.com"*/}
                    {/*                           aria-label="Recipient's username" aria-describedby="button-addon2">*/}
                    {/*                    <button class="btn " type="button" id="button-addon2">submit</button>*/}
                    {/*                </div>*/}
                </div>
            </div>
        </div>
    );
};

export default Footer;
