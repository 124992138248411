import React from 'react';

const Coin360 = () => {

    return (
        <a href="https://coin360.com/" className="coin360Ico " target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 25.4 26.3"
                style={{enableBackground: "new 0 0 25.4 26.3"}}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.Coin360St1{}\n\t.Coin360St2{}\n\t.Coin360St3{}\n"
                    }}
                />
                <path className="Coin360St1" d="M14.8,4.9H4.4v16.5h10.3L14.8,4.9L14.8,4.9z"/>
                <path className="Coin360St2" d="M21,4.9h-4.2v10.3H21V4.9z"/>
                <path className="Coin360St3" d="M21,17.3h-4.2v4.1H21V17.3z"/>
            </svg>
        </a>
    );
};

export default Coin360;
