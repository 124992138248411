import React from 'react';

function Mcico(props) {
    return (
        <div className="Mcico">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.McicoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.McicoSt1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <path
                        className="McicoSt0"
                        d="M67,44.9h11.5c5.5,0,7.9,7.1,3.5,10.4L53.1,76.9c-2.1,1.5-4.9,1.5-6.9,0L17.3,55.3c-4.4-3.3-2.1-10.4,3.5-10.4
		h23.1c3.2,0,5.8-2.6,5.8-5.8V2.5"
                    />
                    <path
                        className="McicoSt0"
                        d="M2.8,28.1v56.3c0,7.2,5.9,13.1,13.1,13.1h68.2c7.2,0,13.1-5.9,13.1-13.1V28.1H80.3"
                    />
                </g>
            </svg>
        </div>
    );
}

export default Mcico;