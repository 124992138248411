import React from 'react';

function OrdersIco(props) {
    return (
        <div className="OrdersIco">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.OrdersIcoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.st1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <g>
                    <g>
                        <polyline
                            className="OrdersIcoSt0"
                            points="86.4,87.9 86.4,96.8 13.6,96.8 13.6,19.1 29.5,3.2 86.4,3.2 86.4,54.4 		"
                        />
                        <polyline className="OrdersIcoSt0" points="29.5,3.8 29.5,19.6 13.6,19.7 		" />
                    </g>
                    <polyline
                        className="OrdersIcoSt0"
                        points="41.9,31.9 76.7,31.9 76.7,44 25.6,44 25.6,31.9 	"
                    />
                    <polyline
                        className="OrdersIcoSt0"
                        points="44.5,63.5 25.6,63.5 25.6,51.4 76.7,51.4 76.7,63.5 68.5,63.5 	"
                    />
                    <polyline
                        className="OrdersIcoSt0"
                        points="76.7,80.6 76.7,83 25.6,83 25.6,70.9 76.7,70.9 76.7,72.1 	"
                    />
                </g>
            </svg>
        </div>
    );
}

export default OrdersIco;