import React from 'react';

function ArrowIco(props) {
    return (
        <svg viewBox="0 0 200 200">
            <style type="text/css">
                {".ArrowIcoSt0{fill:none;stroke-width:9.6;stroke-linecap:round;stroke-linejoin:round;}"}
            </style>
            <path className="ArrowIcoSt0" d="M35.4,61.8L16,22.5c-5.5-11.2,6.6-23,17.4-16.8l145.5,83.2c8.5,4.9,8.5,17.3,0,22.2L33.4,194.3
	c-10.8,6.2-22.9-5.6-17.4-16.8l19.3-39.3"/>
        </svg>
    );
}

export default ArrowIco;