import React from 'react';

const Icobench = () => {

    return (
            <a href="https://icobench.com/" className=" IcobenchIco" target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 25.4 26.3"
                style={{ enableBackground: "new 0 0 25.4 26.3" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html: "\n\t.IcobenchIcoSt0{}\n\t.IcobenchIcoSt1{}\n"
                    }}
                />
                <rect x="1.3" y="2.7" className="IcobenchIcoSt0" width="22.7" height="22.7" />
                <path
                    className="IcobenchIcoSt1"
                    d="M2.4,24.2H23V3.7H2.4V24.2z M3.1,4.3h19.1v19.1H3.1V4.3z"
                />
                <rect x="5.9" y="10.8" className="IcobenchIcoSt1" width="0.7" height="6.2" />
                <path
                    className="IcobenchIcoSt1"
                    d="M10.8,16.4c-1.4,0-2.5-1.1-2.5-2.4c0-1.4,1.1-2.5,2.4-2.5c0.7,0,1.3,0.3,1.8,0.8l0.4-0.5
	c-0.6-0.5-1.4-0.9-2.2-0.9c-1.7-0.1-3.2,1.2-3.3,2.9c-0.1,1.7,1.2,3.2,2.9,3.3c0.1,0,0.2,0,0.4,0c0.9,0,1.7-0.3,2.3-0.9l-0.4-0.5
	C12.1,16.1,11.4,16.4,10.8,16.4z"
                />
                <path
                    className="IcobenchIcoSt1"
                    d="M16.3,10.8c-1.7-0.1-3.2,1.3-3.2,3c0,0,0,0,0,0V14c0,1.8,1.4,3.2,3.2,3.3c1.7,0,3.1-1.3,3.3-3
	c0-0.1,0-0.2,0-0.3c0-1.7-1.4-3.1-3.1-3.1L16.3,10.8z M16.3,16.4c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5v0.1
	C18.8,15.3,17.7,16.4,16.3,16.4L16.3,16.4z"
                />
                <path
                    className="IcobenchIcoSt1"
                    d="M16.3,10.5c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4C19.7,12,18.2,10.5,16.3,10.5z
	 M16.3,16.4c-1.4,0-2.5-1.1-2.5-2.5c0-1.4,1.1-2.5,2.5-2.5c1.4,0,2.5,1.1,2.5,2.5C18.8,15.3,17.7,16.4,16.3,16.4z"
                />
            </svg>

            </a>
    );
};

export default Icobench;
