import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import NavBar from "../widgets/NavBar";
import "../css/Convert.css";
import "../css/bootstrap.css";
import {FormattedMessage} from "react-intl";
import {Context} from "../../translation/Wrapper";
import MarketSearchSec from "../widgets/MarketSearchSec";
import {LoginContext} from "../../Root";
import {useHistory} from "react-router-dom";
import axios from "axios";
import {CenterModal, ModalTitle} from "react-spring-modal";
import {useToasts} from "react-toast-notifications";
import {CircularProgress} from "@material-ui/core";
import {AiOutlineClose} from "@react-icons/all-files/ai/AiOutlineClose";
import {Theme} from "../ThemeContext";
import {PrettoSlider, marks} from "./Trade";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import PlaceBtn from "../IconsClasses/PlaceBtn";
import Footer from "../widgets/Footer";
import ConvertBtn from "../IconsClasses/ConvertBtn";
import JsxParser from "react-jsx-parser";
import ArrowIco from "../IconsClasses/ArrowICO";


function SliderValueLabelComponent(props) {
    const {children, open, value} = props;
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="top"
            title={value + "%"}
        >
            {children}
        </Tooltip>
    );
}

SliderValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
};

const Convert = () => {

        const [sliderValue, setSliderValue] = useState(0);


        const history = useHistory();
        const {isLoggedIn, setIsLoggedIn} = useContext(LoginContext);
        const [isLoading, setIsLoading] = useState(false);
        const [currentPriceUSD, setCurrentPriceUSD] = useState();
        const [swap, setSwap] = useState(true);

        const [first, setFirst] = useState();
        const [second, setSecond] = useState();
        const [firstImage, setFirstImage] = useState("");
        const [secondImage, setSecondImage] = useState("");
        const [price, setPrice] = useState();
        const priceRef = useRef("");

        const [isBuyMode, setIsBuyMode] = useState(true);
        const [availableCoin, setAvailableCoin] = useState(0);
        const [availableCoinName, setAvailableCoinName] = useState(" ");
        const [convertAmount, setConvertAmount] = useState();
        const [loading, setLoading] = useState(false);
        const [calculated, setCalculated] = useState(false);

        const handleSliderChange = (event, newValue) => {
            setSliderValue(newValue);
            setPrice(availableCoin * newValue / 100);
        };

        useEffect(() => {
            getInfo();
        }, []);

        const getMarketDetail = useCallback(async (val) => {

            if (val != undefined) {
                setAvailableCoinName(val);
                setLoading(true)

                try {
                    await axios.get(`core/balanceInfo`, {
                        headers: {
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }).then((res) => {
                        let loadedData;
                        if (res.data.data[val]) {
                            loadedData = res.data.data[val].available;
                        } else {
                            loadedData = 0;
                        }
                        setAvailableCoin(loadedData);
                    });
                } catch (error) {
                    if ((error?.response?.status ?? 0) === 401) {
                        localStorage.removeItem("token");
                        addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                        // console.log(error);

                    }
                }

                setLoading(false);
            }
        }, []);

        const {addToast} = useToasts();

        const context = useContext(Context);

        const notMatch = <p className="text-danger ms-4 text-capitalize fw-bold">{context.locale == 'fa' ? "ارز های انتخاب شده باید متفاوت باشند" : "The selected coins must be different"}</p>;


        const convert = useCallback(async (fi, sec) => {
                setIsLoading(true);
                const Amount = priceRef.current.value;
                const tokken = "bearer " + localStorage.getItem("token");
                const ConvertOrder = {
                    "fromcurrency": fi,
                    "tocurrency": sec,
                    "amount": Amount
                }

                try {
                    const response = await axios.post(`convert/check_convert_amount`, ConvertOrder,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': tokken,
                            }
                        });
                    const data = await response.data;
                    if (data.status != "ok") {
                        addToast(data.errors[0].msg, {appearance: "error", autoDismiss: true});
                    } else {

                        setConvertAmount(data.data.convert_value);
                        setOpen(true);
                    }
                } catch (error) {

                    if ((error?.response?.status ?? 0) === 403) {
                        addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
                    }
                    if ((error?.response?.status ?? 0) === 401) {
                        localStorage.removeItem("token");
                        addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                        // (error);
                        setTimeout(() => {
                            window.location = "/login";
                        }, 2000);
                    }
                }
                setIsLoading(false);
            }, []
        )

        async function addConvert() {
            setIsLoading(true);

            const Amount = priceRef.current.value;
            const tokken = "bearer " + localStorage.getItem("token");
            const ConvertOrder = {
                "fromcurrency": first,
                "tocurrency": second,
                "amount": Amount
            }

            try {

                const response = await axios.post(`convert/convert`,
                    ConvertOrder, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': tokken,
                        }
                    });

                const data = await response.data;

                if (data.status != "ok") {
                    addToast(data.errors[0].msg, {appearance: "error", autoDismiss: true});
                    setOpen(false);
                } else {
                    addToast('Your Order Was successful', {appearance: "success", autoDismiss: true});
                    setPrice("");
                    setOpen(false);
                    getMarketDetail();
                }
            } catch (error) {
                if ((error?.response?.status ?? 0) === 403) {
                    addToast(error.response.data.errors[0].msg, {appearance: "error", autoDismiss: true});
                    setPrice("");
                    setOpen(false);
                }
                if ((error?.response?.status ?? 0) === 401) {
                    localStorage.removeItem("token");
                    addToast("Invalid credentials or token expired", {appearance: "warning", autoDismiss: true});
                    // (error);
                    setTimeout(() => {
                        window.location = "/login";
                    }, 2000);
                }
            }

            setIsLoading(false);
        }

        const [isVerified, setIsVerified] = useState(null);
        const [error, setError] = useState(null);

        const getInfo = useCallback(async () => {
            try {
                const response = await axios.get('profile/info', {
                    headers: {
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                });
                if (response.status == 200) {
                    setIsVerified(response.data.data.auth_level);

                }

            } catch (error) {
                setError(error.message);
            }
        }, [])

        let content;
        if (isLoading) {
            content = <CircularProgress size={16}/>;
        }

        const [isOpen, setOpen] = useState(false);


        let placeholder = context.locale == 'fa' ? 'لطفا مقدار را 50-0.0003 وارد کنید' : 'Please enter 0.0003-50 '

        const theme = useContext(Theme);

        return (
            <div className={`${theme.theme == 'dark' ? 'BGDark' : 'BGLight'}`}>
                <NavBar/>

                <div className="container-fluid ">
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-8 col-xl-8  col-sm-12 col-xs-12">
                            <br/>
                            <br/>
                            <br/>

                            <div className={`  CovertCard border-0 `}>

                                <div className={`card-body curvy  shadow ${theme.theme == 'dark' ? 'convertBGDark' : 'convertBGLight'}`}>

                                    <br/>
                                    <br/>
                                    <br/>

                                    {/*<button className=" col-2 d-flex justify-content-center convertIcon"*/}
                                    {/*        onClick={() => {*/}
                                    {/*            setSwap(!swap);*/}
                                    {/*            setIsBuyMode(!isBuyMode);*/}
                                    {/*            setAvailableCoinName("");*/}
                                    {/*            setAvailableCoin(0);*/}
                                    {/*        }}>*/}
                                    {/*    <MdCompareArrows/>*/}
                                    {/*</button>*/}

                                    <form className="convertForm" onSubmit={addConvert}>
                                        {isVerified == 0 && <h4 className="authErr" style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}} dir={context.locale == "fa" && "rtl"}><FormattedMessage id="Convert.AuthLevel"
                                                                                                                                                                                                           defaultMessage="AuthLevel"/>
                                        </h4>}
                                        <div className="row justify-content-around convertInputGp">
                                            <div
                                                className={`  marketBarContainer  ${!isLoggedIn || isVerified == 0 && 'disable-content'}`}
                                                dir={context.locale == "fa" && "rtl"}>
                                                <label className={`d-flex justify-content-between  `}
                                                       dir={context.locale == "fa" && "rtl"}
                                                       style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}>
                                                    <FormattedMessage
                                                        id="Convert.from"
                                                        defaultMessage="from"
                                                    /></label>
                                                <MarketSearchSec
                                                    onGetMarketDetail={getMarketDetail}
                                                    setFirst={setFirst}
                                                    setFirstImage={setFirstImage}
                                                    lable={"From"}
                                                />

                                            </div>
                                        </div>

                                        <div className="row justify-content-around convertInputGp">
                                            <div
                                                className={`  marketBarContainer  ${!isLoggedIn || isVerified == 0 && 'disable-content'}`}
                                                style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                                dir={context.locale == "fa" && "rtl"}>
                                                <label>
                                                    <FormattedMessage
                                                        id="Convert.to"
                                                        defaultMessage="To"
                                                    />
                                                </label>
                                                <MarketSearchSec market={true} setSecond={setSecond}
                                                                 setSecondImage={setSecondImage} lable={"To"}/>
                                            </div>
                                        </div>

                                        <div className="row justify-content-around convertInputGp">
                                            <div className=" toConvert">
                                                <input type="text" className="form-control"
                                                       style={context.locale == "fa" ? {fontFamily: "IranNastaliq"} : {fontFamily: "HelveticaNeueCyrBold"}}
                                                       placeholder={placeholder}
                                                       disabled={first == second}
                                                       value={price}
                                                       ref={priceRef}
                                                       onChange={(e) => {
                                                           setPrice(e.target.value);
                                                       }}
                                                />
                                                <p className={`balanceInfo m-0 text-secondary d-inline`}
                                                   onClick={() => {
                                                       setPrice(availableCoin);
                                                       setSliderValue(100);
                                                   }}>
                                                    {first == second && first != undefined && notMatch}
                                                    {availableCoin + " " + availableCoinName}
                                                </p>
                                            </div>

                                        </div>

                                        <div className="row justify-content-around convertInputGp">
                                            <div className=" toConvertPercent">
                                                <PrettoSlider
                                                    className={`pt-4 ${!availableCoin > 0 && 'disabledButton'}`}
                                                    style={{
                                                        width: "90%",
                                                        paddingLeft: 8,
                                                        marginLeft: 6,
                                                        color: '#a3a3a3',
                                                    }}
                                                    ValueLabelComponent={SliderValueLabelComponent}
                                                    marks={marks}
                                                    valueLabelDisplay="auto"
                                                    value={sliderValue}
                                                    aria-label="slider"
                                                    defaultValue={0}
                                                    onChange={handleSliderChange}
                                                />

                                            </div>
                                        </div>

                                        <CenterModal isOpen={isOpen} >
                                            <button className="bnClose " onClick={() => setOpen(false)}><AiOutlineClose/>
                                            </button>
                                            <div className={`modalContent ${isLoading && 'disabledButton'}`}>
                                                <div className="row justify-content-around convertInputGp">
                                                    <p className="convertText">
                                                        <div className="convertData"
                                                             style={{margin: "20px auto"}}>
                                                            <div
                                                                className={`${theme.theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                                <JsxParser className="marketListLogo" jsx={firstImage}/>
                                                            </div>
                                                            <p>{first}</p>
                                                        </div>
                                                        <p className="convertAmount HandN"  >{priceRef.current.value}</p>
                                                        <div className="convertIco">
                                                            <ArrowIco/>
                                                        </div>
                                                        <div className="convertData"
                                                             style={{margin: "20px  auto"}}>
                                                            <div
                                                                className={`${theme.theme == 'dark' ? 'LogoContainerLight' : 'LogoContainerDark'}`}>
                                                                <JsxParser className="marketListLogo" jsx={secondImage}/>
                                                            </div>
                                                            <p>{second}</p>
                                                        </div>
                                                    </p>
                                                    <p className="convertAmount HandN"  >{convertAmount}</p>

                                                    <p className="convertText">
                                                        <p className="convertAmount" style={{margin: "20px auto 20px 10px"}}>{priceRef.current.value}</p>
                                                        <p className="convertAmount"  style={{margin: "20px  10px 20px auto"}} >{convertAmount}</p>
                                                    </p>
                                                </div>

                                                <div className="row justify-content-center">
                                                    <button type="submit" onClick={addConvert} className="convertBtn ">
                                                        <ConvertBtn/>
                                                    </button>
                                                </div>

                                            </div>
                                        </CenterModal>

                                    </form>

                                    <br/>
                                    <a className="d-flex justify-content-center">
                                        <div
                                            role="button"
                                            className={`PlaceBtn ${price > 0 && 'bn25'} col-xl-2 col-lg-2 col-sm-4  col-xs-4 ${!price > 0 && 'disabledInput'} ${!isLoggedIn && 'disabledInput'}`}
                                            onClick={() => convert(first, second)}>
                                            <PlaceBtn/>
                                        </div>
                                    </a>
                                    <br/>
                                    <br/>
                                    <br/>
                                </div>
                            </div>

                            <br/>
                            <br/>
                            <br/>

                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
;

export default Convert;
