import React, {useContext, useEffect, useState} from 'react';

import "../css/AccountsNav.css"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {FormattedMessage} from "react-intl";
import {CgListTree} from "@react-icons/all-files/cg/CgListTree";
import {MdStreetview} from "@react-icons/all-files/md/MdStreetview";
import {HiInboxIn} from "@react-icons/all-files/hi/HiInboxIn";
import {VscReferences} from "@react-icons/all-files/vsc/VscReferences";
import {useHistory} from "react-router-dom";
import {Theme} from "../ThemeContext";
import {Context} from "../../translation/Wrapper";
import AccSettingIco from "../IconsClasses/ACCSettingICO";
import Idvico from "../IconsClasses/IDVICO";
import Mcico from "../IconsClasses/MCICO";
import SettingPageIco from "../IconsClasses/SettingPageICO";

const AccountsNavigator = () => {

    const history = useHistory();
    const theme = useContext(Theme);

    const styling = theme.theme == 'dark' ? {color: "#fff"} : {color: "#333"};
    const context = useContext(Context);


    return (


        <div className="ACCnavbar mb-3 row">
            <a onClick={() => history.push("account")} className="ACC-nav-btns " style={context.locale == "fa"  ? {fontFamily:"IranNastaliq"} : {fontFamily:"HelveticaNeueCyrBold"}}>
                <FormattedMessage id="Account.Info.AccountSettings"
                                  defaultMessage="AccountSettings"/>
                <AccSettingIco/>
            </a>
            <a onClick={() => history.push("id_verification")} className="ACC-nav-btns" style={context.locale == "fa"  ? {fontFamily:"IranNastaliq"} : {fontFamily:"HelveticaNeueCyrBold"}}>
                <FormattedMessage id="SettingsDropDown.IdVerification"
                                  defaultMessage="IdVerification"/>
                <Idvico/>
            </a>
            <a className="ACC-nav-btns disabledButton" style={context.locale == "fa"  ? {fontFamily:"IranNastaliq"} : {fontFamily:"HelveticaNeueCyrBold"}}>
                <FormattedMessage id="Account.Info.MessageCenter"
                                  defaultMessage="MessageCenter"/>
                <Mcico/>
            </a>
            <a onClick={() => history.push("referral")} className="ACC-nav-btns" style={context.locale == "fa"  ? {fontFamily:"IranNastaliq"} : {fontFamily:"HelveticaNeueCyrBold"}}>
                <FormattedMessage id="Account.Info.Settings" defaultMessage="Setting"/>
                <SettingPageIco/>
            </a>

        </div>

    );
};

export default AccountsNavigator;
