import '../css/LoadingSpinner.css';
import React, {useContext} from "react";
import {Theme} from "../ThemeContext";

const LoadingSpinner = () => {

    const theme = useContext(Theme);

    return (
        <div className={`Body ${theme.theme == 'dark' ? 'BGDark' : 'BGLight'}`}>
            <div className="loader">

            </div>
        </div>
    );
}

export default LoadingSpinner;