import AccountsNavigator from "./widgets/AccountsNavigator";

export const lightTheme = {
    body: '#f1f2f4',
    text: '#363537',
    textSec: '#111111',
    toggleBorder: '#82969f',
    background: '#363537',
    border: 'rgb(0,0,0)',
    footer: `#fff`,
    EstTotal: `#535353`,
    bodySec: '#f2f2f2',
    hoverBtn: '#c4c4c4',
    footerHover: '#343434;',
    footerBrightnessIco: 'brightness(0.3)',
    cardHeader:`#fff`,
    shadowColor:`rgba(0, 0, 0, 0.175)`,
    dropDown:`#e6e6e6`,
    cardBorder:`#535353 1px solid`,
    tableTr: `#dee2e6`,
    autoComplete: '0 0 0 30px',
    colorInfo: `#051e4c`,
    marquee:'#ededef',
    sideNav:'rgba(221,222,225,0.8)',
    disableColor: '#757575',
   idVerificationCard: '#f2f2f2',
    verification:"#434343",
    navColor: '#c5c5c5',
    navFColor: '#0d0d0d',
    checkBox: '#374449',
    btnM:'#e8f0fe',
    tableGlow:'#233f7b',
    calColor:'#405fc9',
    tileRapColor:'rgba(232,234,237,0.8)',
    tileRapColorInset:'rgba(232,234,237)',
    idVerificationStepCard: 'rgba(155, 155, 155)',
    GPrice: '#d38435',
    growColor: '#64a07f',
    AccountsNavigator: '#265d73',
    ShadowCard:'linear-gradient(125deg, transparent 0%, rgba(221,222,225,0.8) 100%);',
    GlassCard:'rgba(221,222,225,0.8)',
    GlassCardNoOp:'#aeafb1',
    tileRapGlassCard:'rgba(16,18,24,0.6)',
    MarketBefore:'rgba(166,167,169,0.7)',
    notLogin:"#504d4d",
    GlassList:'linear-gradient(90deg, transparent 0%, rgba(240,241,245,0.4) 35%, transparent 100%)',
    GlassButton:'linear-gradient( to right,transparent, rgba(24, 58, 132, 0.8),rgba(24, 58, 132, 0.8),transparent);',
    GlassListSec:'linear-gradient(    to right, transparent,rgba(155, 155, 155, 0.5), rgba(155, 155, 155, 0.5),transparent)',
    glowShadow:'3px 3px 3px 3px rgba(0, 0, 0, 0.2), 1px 1px 1px 1px rgba(0, 0, 0, 0.3)'
}


export const darkTheme = {
    sideNav:'rgba(7, 8, 13, 0.8)',
    autoComplete: '0 30px 20px 5px',
    body: '#12141b',
    textSec: '#fff',
    navColor: '#14141f',
    navFColor: '#f2f2f2',
    shadowColor:`rgba(0, 0, 0, 0.3)`,
    bodySec: '#14141f',
    hoverBtn: '#13182c',
    footerHover: '#cccccc;',
    footerBrightnessIco: 'brightness(1.9)',
    text: '#FAFAFA',
    toggleBorder: '#637e86',
    background: '#999',
    border: '#727272',
    footer: `#181c27`,
    cardHeader:`#181c27`,
    EstTotal: `#cbcbcb`,
    tableTr: `#909295`,
    dropDown:`#181c27`,
    cardBorder:`#d8d8d8 1px solid`,
    cardBodyBorder:`#d8d8d8 1px solid`,
    colorInfo: `#637e86`,
    marquee:'#262626',
    disableColor: '#44516f',
    checkBox: '#728d96',
    btnM:'#464d60',
    tableGlow:'#4871cc',
    verification:"#cccccc",
    calColor:'#4b5a8e',
    idVerificationCard: '#1d222f',
    idVerificationStepCard: '#0c0f27',
    GPrice: '#d88c41',
    growColor: '#2fcb88',
    AccountsNavigator: '#BDE3F2',
    tileRapColor:'rgba(18,20,27,0.8)',
    tileRapColorInset:'rgba(18,20,27)',
    MarketBefore:'rgba(11,12,14,0.7)',
    GlassCard:'rgba(16,18,24,0.8)',
    notLogin:"#878787",
    GlassCardNoOp:'rgba(24,27,38,0.8)',
    tileRapGlassCard:'rgba(140, 140, 140, 0.6)',
    GlassList:'linear-gradient(90deg, rgba(18,20,27,0.5) 0%, rgb(27, 32, 50 ,0.4) 35%, rgba(18,20,27,0.5) 100%)',
    GlassButton:'linear-gradient(    to right,transparent, rgba(9, 23, 52, 0.8), rgba(9, 23, 52, 0.8),transparent)',
    GlassListSec:'linear-gradient(to right, transparent, #14171f, #14171f, transparent)',
    glowShadow:'3px 3px 3px 3px rgba(168, 166, 166, 0.2), 1px 1px 1px 1px rgba(114, 113, 113, 0.3)'
}
