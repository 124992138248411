import React from 'react';

const CoinDesk = () => {
    return (
        <a href="https://www.coindesk.com/" className="CoinDesk" target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 25.4 26.3"
                style={{ enableBackground: "new 0 0 25.4 26.3" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{ __html: "\n\t.CoinDeskSt0{}\n" }}
                />
                <path
                    className="CoinDeskSt0"
                    d="M11.9,13c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9C12.3,12.1,11.9,12.5,11.9,13z
	 M19.5,12.1c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9l0,0C20.4,12.5,20,12.1,19.5,12.1L19.5,12.1z M19.5,19
	c-0.5,0-0.9,0.4-0.9,0.9c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9c0,0,0,0,0,0C20.4,19.4,20,19,19.5,19L19.5,19z"
                />
                <path
                    className="CoinDeskSt0"
                    d="M21.2,3.8c0,0-1.6-0.4-2.4,0.5s-2.4,0.6-2.4,0.6s-0.7-0.3-1.2-0.8c-0.6-0.5-1.8-0.8-2.8-0.2s-1.2,1-1.9,0.9
	c-0.7-0.1-1-0.2-1.6-0.7C8.4,3.9,6.8,2.8,5.3,4.3S5.7,8,5.7,8s1.1,1.4-0.2,3.1c-0.5,0.6-0.7,1.5-0.4,2.3c0.1,0.4,0.4,0.9,0.8,1.3
	c0,0,1.2,1.1-0.2,3.1s-1.3,3.1,0,4.2C7.1,23,8.7,21,8.7,21s0.9-1,2.4,0c1,0.6,2.4,2.1,4.2,0.3c0,0,1.5-2.1-0.7-3.5
	c0,0-1.4-0.9-2.8,0.3c-0.6,0.5-1.3,0.7-2,0.7c-0.6,0-1.2-0.3-1.6-1C7.7,17,7.7,16.1,8,15.4c0.1-0.3,0.4-0.7,0.7-1
	c0,0,1.1-1.2-0.5-3.3C7.3,10,7.6,9.1,8,8.4C8.6,7.5,9.7,7,10.8,7.4c0.3,0.1,0.6,0.2,0.8,0.5c1.1,0.9,2.7,0.6,3.4-0.1
	c0.8-0.8,2.3-0.9,3.2-0.1s2.6,1.3,3.7,0.5c0.8-0.5,1.5-1.6,1.2-2.5S22.4,4.2,21.2,3.8z"
                />
                <circle className="CoinDeskSt0" cx="12.8" cy={13} r="0.9" />
                <circle className="CoinDeskSt0" cx="19.5" cy={13} r="0.9" />
                <circle className="CoinDeskSt0" cx="19.5" cy="19.9" r="0.9" />
            </svg>
        </a>
    );
};

export default CoinDesk;
