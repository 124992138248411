import React from 'react';

function Idvico(props) {



    return (
        <div className="Idvico">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                style={{ enableBackground: "new 0 0 100 100" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.IdvicoSt0{fill:none;stroke:#000000;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;}\n\t.IdvicoSt1{fill:none;stroke:#000000;stroke-width:4.2773;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <path
                    className="IdvicoSt0"
                    d="M4.8,55v15.1c0,5.6,4.5,10.1,10.1,10.1h70.4c5.6,0,10.1-4.5,10.1-10.1V29.9c0-5.6-4.5-10.1-10.1-10.1H14.8
	c-5.6,0-10.1,4.5-10.1,10.1v10.1h75.4"
                />
            </svg>

        </div>
    );
}

export default Idvico;