import React from 'react';

const SocialMediaTwitter = () => {
    return (
            <a className="list-group-item  border-0 SocialMediaTwitter" target="_blank" id="list-link"
               href={'https://Twitter.com/sigloyexx'}>
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 200 200"
                style={{ enableBackground: "new 0 0 200 200" }}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.st0{fill:none;stroke:#D42E35;stroke-width:7.3896;stroke-linecap:round;stroke-linejoin:round;}\n\t.SocialMediaTwitterSt1{fill:none;stroke-width:11;stroke-linecap:round;stroke-linejoin:round;}\n\t.st2{fill:none;stroke:url(#SVGID_1_);stroke-width:7;stroke-linecap:round;stroke-linejoin:round;}\n\t.st3{fill:none;stroke:#1C9DD9;stroke-width:7;stroke-linecap:round;stroke-linejoin:round;}\n"
                    }}
                />
                <path
                    className="SocialMediaTwitterSt1"
                    d="M6,175.9c48.2,22.8,149.5,35.1,169.4-98.5c7-3.1,18.4-11.6,20.5-34.6c-6.6,4.8-19.5,6.8-32.3,5.6
	c-21-11-63.5-18.6-65.5,39c-22.4-3-71.2-18.3-86.7-55.5c-7,27-8.2,88.9,42.9,120.5c-2.2,3.8-10.6,12.1-25.9,14.3"
                />
            </svg>
            </a>
    );
};

export default SocialMediaTwitter;
