import React, {useContext} from 'react';
import NavBar from "../widgets/NavBar";
import {Theme} from "../ThemeContext";
import {Context} from "../../translation/Wrapper";
import '../css/ContactUs.css';
import {FormattedMessage} from "react-intl";

function ContactUs(props) {

    const theme = useContext(Theme);
    const context =useContext(Context);

    return (
        <div className={`${context.locale == "fa" && "font-face-farsi"} ${theme.theme == 'dark' ? 'BGDark' : 'BGLight'}`}>
            <NavBar />
            <div className="container-fluid " style={{position:"absolute",transform:"translate(-50%,-50%)",top:"50%",left:"50%"}}>
                <div className="row justify-content-center">
                    <div className="col-lg-4  col-md-6 col-sm-12 col-xs-12">
                        <div className="card " dir={context.locale == "fa" && "rtl"}>
                            <div className="row justify-content-between ">
                                <div className="card-body curvy ContactUsContainer shadow">
                                        <div dir={context.locale == "fa" && "rtl"} className={`${context.locale == "fa" && "font-face-farsi"}`}>
                                            <div className="AboutUsAddress">
                                                <p><FormattedMessage id="Footer.Modal.AddressTitle" defaultMessage="Footer.Modal.AddressTitle"/>
                                                </p>
                                                <p><FormattedMessage id="Footer.Modal.Address" defaultMessage="Footer.Modal.Address"/></p></div>
                                            <div className="AboutUsPhoneNumber">
                                                <p><FormattedMessage id="Footer.Modal.PhoneNumberTitle" defaultMessage="PhoneNumberTitle"/></p>
                                                <p>: 05137641672</p></div>
                                            <div className="AboutUsWorkingHours">
                                                <p><FormattedMessage id="Footer.Modal.WorkingHoursTitle" defaultMessage="WorkingHoursTitle"/>
                                                </p>
                                                <p><FormattedMessage id="Footer.Modal.WorkingHours" defaultMessage="WorkingHours"/></p></div>
                                            <div className="AboutUsPhoneNumber">
                                                <p><FormattedMessage id="Footer.Modal.EmailTitle" defaultMessage="EmailTitle"/></p>
                                                <p> support@sigloyex.com</p></div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ContactUs;