import React from 'react';
import {useHistory} from "react-router-dom";

function LanguageIco(props) {

    const history = useHistory();

    return (
        <div className={`LanguageIco`} >
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 200 200"
                style={{enableBackground: "new 0 0 200 200"}}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                        __html:
                            "\n\t.LanguageIcoSt0{fill:none;stroke-width:9.5992;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n"
                    }}
                />
                <g>
                    <path className="LanguageIcoSt0" d="M106.9,114.9c27-25.5,66.9-66.2,78.1-93.9H70"/>
                    <path className="LanguageIcoSt0" d="M86.2,52.5c0,0,47.2,66.6,95.2,91.6"/>
                    <g>
                        <polyline className="LanguageIcoSt0" points="26.6,162.4 65.7,53.8 115.6,192.8 		"/>
                        <line className="LanguageIcoSt0" x1="15.7" y1="192.8" x2="21.2" y2="180.9"/>
                        <line className="LanguageIcoSt0" x1="54.4" y1="138.5" x2="95.5" y2="138.5"/>
                    </g>
                    <path className="LanguageIcoSt0" d="M111.4,21c0,0,13.5-29,33.7,0"/>
                </g>
            </svg>
        </div>
    );
}

export default LanguageIco;