import "./notAllowed.css";
import disableScroll from 'disable-scroll';


const NotAllowed = (props) => {
    disableScroll.on(); // prevent scrolling
        return (
            <div className="container-fluid body">
                <a href="#">
                    <header className="top-header"></header>
                    {/*dust particel*/}
                    <div>
                        <div className="starsec"/>
                        <div className="starthird"/>
                        <div className="starfourth"/>
                        <div className="starfifth"/>
                    </div>
                    {/*Dust particle end-*/}
                    <div className="lamp__wrap">
                        <div className="lamp">
                            <div className="cable"/>
                            <div className="cover"/>
                            <div className="in-cover">
                                <div className="bulb"/>
                            </div>
                            <div className="light"/>
                        </div>
                    </div>
                    {/* END Lamp */}
                </a>
                <section className="error">
                    <a href="#">
                        {/* Content */}
                    </a>
                    <div className="error__content">
                        <a href="#">
                            <div className="error__message message">
                                <h1 className="message__title">404</h1>
                                <h3 className="message__Subtitle">Page Not Found</h3>
                                <p className="message__text">
                                    We're sorry, the page you were looking for isn't found here.
                                </p>
                            </div>
                        </a>
                        <div className="error__nav e-nav">
                            <a href="#"></a>
                            <a href="#" className="e-nav__link" onClick={() => {window.location = "/"}}>HomePage</a>
                        </div>
                    </div>
                    {/* END Content */}
                </section>
            </div>
        )
    }
;

export default NotAllowed;
