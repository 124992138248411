import React from 'react';

const Icodrops = () => {
    return (
        <a href="https://icodrops.com/" className="Icodrops " target="_blank">
            <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                viewBox="0 0 20.4 18.7"
                style={{enableBackground: "new 0 0 20.4 18.7"}}
                xmlSpace="preserve"
            >
                <style
                    type="text/css"
                    dangerouslySetInnerHTML={{__html: "\n\t.IcodropsSt0{}\n"}}
                />
                <path
                    className="IcodropsSt0"
                    d="M8.4,2.6c0,0-7.5,8.6-3.3,13.4c2.2,2.6,5.9,3.3,8.9,1.5c0.2,0,5-3,1.3-9.9S10,0.1,10,0.1L9.1,1.9
	c0,0,6.6,8.4,5.6,11.5s-3.5,4.3-6.3,3.2c-0.6-0.2-1.1-0.6-1.5-1c-1.3-1.4-1.7-3.5-0.8-5.3c0.6-1.4,1.5-2.8,2.5-3.9
	c0,0,3.4,4.4,3,9.2c1.2-0.6,2-1.9,1.8-3.2c-0.1-1.2-0.3-2.4-0.8-3.6C11.6,6.8,9.4,3.2,8.4,2.6z"
                />
            </svg>
        </a>
    );
};

export default Icodrops;
