import * as React from 'react';
import PropTypes from 'prop-types';
import {useTheme, styled} from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DoneIcon from '@mui/icons-material/Done';
import Autocomplete, {autocompleteClasses, createFilterOptions} from '@mui/material/Autocomplete';
import ButtonBase from '@mui/material/ButtonBase';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import {useHistory} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import axios from "axios";
import JsxParser from "react-jsx-parser";

const StyledAutocompletePopper = styled('div')(({theme}) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: '#838383' ,
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            alignItems: 'flex-start',
            padding: 8,
            borderBottom: `1px solid  ${
                theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
            }`,
            '&[aria-selected="true"]': {
                backgroundColor: 'transparent',
            },
            '&[data-focus="true"], &[data-focus="true"][aria-selected="true"]': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
    },
}));

function PopperComponent(props) {
    const {disablePortal, anchorEl, open, ...other} = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({theme}) => ({
    border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
    boxShadow: `0 8px 24px ${
        theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
    }`,
    borderRadius: 6,
    width: 300,
    zIndex: theme.zIndex.modal,
    fontSize: 13,
    color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({theme}) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${
        theme.palette.mode === 'light' ? '#eaecef' : '#30363d'
    }`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        fontSize: 14,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light'
                    ? 'rgba(6,27,69,0.3)'
                    : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#30363d' : '#388bfd',
        },
    },
}));

const Button = styled(ButtonBase)(({theme}) => ({
    fontSize: 13,
    width: '100%',
    textAlign: 'left',
    paddingBottom: 8,
    fontWeight: 600,
    '&:hover,&:focus': {
        filter: "opacity(0.6)"
    },
    '& span': {
        width: '100%',
    },
    '& svg': {
        width: 16,
        height: 16,
    },
}));

export default function GitHubLabel(props) {

    const history = useHistory();
    const [marketList, setMarketList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);


    const fetchCoinHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {

            const listResponse = await axios.get('currencies/list');

            if (listResponse.status !== 200) {
                throw new Error('Something went wrong with CoinList!');
            }


            const marketL = (listResponse.data);


            const loadedList = [];


            for (var keys in marketL) {
                if (keys != 'insert' && marketL[keys].markets != undefined && marketL[keys].markets.length > 0 && !marketL[keys].is_stable) {
                    loadedList.push({
                            name: marketL[keys].short_name,
                            market: marketL[keys].markets,
                            logo: marketL[keys].logo
                        }
                    )
                }
            }

            setMarketList(loadedList);

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchCoinHandler();
    }, [fetchCoinHandler]);


    const filterOptions = createFilterOptions({
        matchFrom: 'start'
    });

    //***************************************************************************************************************

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [value, setValue] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState();
    const theme = localStorage.getItem('themeMode');

    const handleClick = (event) => {
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const choseCoin = (Value) => {

        history.replace("/Trade?pair=" + Value);
        history.go();

    }


    const open = Boolean(anchorEl);
    const id = open ? 'github-label' : undefined;

    return (
        <React.Fragment>
            <Box sx={{width: 150, fontSize: 36}}>
                <Button sx={{fontSize: 18, letterSpacing: 2}} disableRipple aria-describedby={id} onClick={handleClick}>
                    {props.Name}
                </Button>
            </Box>
            <StyledPopper id={id} open={open} anchorEl={anchorEl} placement="bottom-start">
                <ClickAwayListener onClickAway={handleClose}>
                    <div className={`${theme == 'light' ? 'searchBarMarket-light' : 'searchBarMarket-dark'}`}>
                        <Autocomplete
                            open
                            multiple
                            value={pendingValue}
                            onChange={(event, newValue, reason) => {
                                choseCoin(newValue[0].market[0]);
                                setPendingValue(newValue);
                            }}
                            disableCloseOnSelect
                            PopperComponent={PopperComponent}
                            renderTags={() => null}
                            noOptionsText="No labels"
                            renderOption={(props, option, {selected}) => (
                                <li {...props}>
                                    <Box
                                        sx={{width: 17, height: 17, mr: '5px', ml: '-2px'}}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 16,
                                            height: 16,
                                            flexShrink: 0,
                                            borderRadius: '3px',
                                            mr: 1,
                                            mb: '2px',
                                        }}
                                    >
                                        <JsxParser className="TPTableLogo" jsx={option.logo}/>
                                    </Box>
                                    <Box
                                        sx={{
                                            fontSize: 16,
                                            flexGrow: 1,
                                            fontWeight: 550,
                                            color:
                                                theme == 'light' ? '#000' : '#fff',
                                        }}
                                    >
                                        {option.name} <span
                                        className={`${theme == 'light' ? 'MarketSearch-coin-light ' : 'MarketSearch-coin-dark '}  pt-2`}
                                        style={theme == 'light' ? {color: "#181818"} : {color: "#bababa"}}>  ({option.market[0]})</span>
                                        <br/>
                                    </Box>
                                    <Box
                                        sx={{opacity: 0.6, width: 18, height: 18}}
                                        style={{
                                            visibility: selected ? 'visible' : 'hidden',
                                        }}
                                    />
                                </li>
                            )}
                            options={marketList}
                            getOptionLabel={(option) => option.market[0]}
                            renderInput={(params) => (
                                <StyledInput
                                    ref={params.InputProps.ref}
                                    inputProps={params.inputProps}
                                    autoFocus
                                    placeholder="Filter labels"
                                />
                            )}
                        />
                    </div>
                </ClickAwayListener>
            </StyledPopper>
        </React.Fragment>
    );
}

// From https://github.com/abdonrd/github-labels
const labels = [
    {
        name: 'good first issue',
        color: '#7057ff',
        description: 'Good for newcomers',
    },
    {
        name: 'help wanted',
        color: '#008672',
        description: 'Extra attention is needed',
    },
    {
        name: 'priority: critical',
        color: '#b60205',
        description: '',
    },
    {
        name: 'priority: high',
        color: '#d93f0b',
        description: '',
    },
    {
        name: 'priority: low',
        color: '#0e8a16',
        description: '',
    },
    {
        name: 'priority: medium',
        color: '#fbca04',
        description: '',
    },
    {
        name: "status: can't reproduce",
        color: '#fec1c1',
        description: '',
    },
    {
        name: 'status: confirmed',
        color: '#215cea',
        description: '',
    },
    {
        name: 'status: duplicate',
        color: '#cfd3d7',
        description: 'This issue or pull request already exists',
    },
    {
        name: 'status: needs information',
        color: '#fef2c0',
        description: '',
    },
    {
        name: 'status: wont do/fix',
        color: '#eeeeee',
        description: 'This will not be worked on',
    },
    {
        name: 'type: bug',
        color: '#d73a4a',
        description: "Something isn't working",
    },
    {
        name: 'type: discussion',
        color: '#d4c5f9',
        description: '',
    },
    {
        name: 'type: documentation',
        color: '#006b75',
        description: '',
    },
    {
        name: 'type: enhancement',
        color: '#84b6eb',
        description: '',
    },
    {
        name: 'type: epic',
        color: '#3e4b9e',
        description: 'A theme of work that contain sub-tasks',
    },
    {
        name: 'type: feature request',
        color: '#fbca04',
        description: 'New feature or request',
    },
    {
        name: 'type: question',
        color: '#d876e3',
        description: 'Further information is requested',
    },
];
